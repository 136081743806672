import axios from 'axios'
import { SERVER_URI } from 'config'
import jwt from 'jwt-decode'
import { getPendingTasks } from './searchActions'
import {
  SET_ERROR,
  RESET_ERROR,
  SET_TOKEN,
  SET_USER,
  RESET_PASSWORD_MESSAGE,
  RESET_PASSWORD_ERROR
} from './types'

console.log(SERVER_URI) // remove this later

// axios.defaults.withCredentials = true

export const registerRequest = (input, history) => (dispatch) => {
  if (input.password !== input.confirmPassword) {
    dispatch({ type: SET_ERROR, payload: 'Passwords do not match' })
  } else {
    axios.post(`${SERVER_URI}/api/auth/register`, {
      fullName: input.fullName,
      username: input.username,
      email: input.email,
      password: input.password,
      type: input.type,
    })
    .then((response) => {
      console.log(SERVER_URI)
      const user = response.data.user
      const test = jwt(response.data.token)
      dispatch({ type: SET_TOKEN, payload: response.data.token })
      dispatch({ type: SET_USER, payload: test })
      localStorage.setItem('token', response.data.token)
      localStorage.setItem('id', response.data.user.id)
      localStorage.setItem('full_name', user.fullName)
      history.push('/login')
    })
    .catch((error) => {
      console.log('Error on registerRequest actions: ', error)
      dispatch({ type: SET_ERROR, payload: 'Email already in use' })
    })
  }
}

export const loginRequest = (input, history) => (dispatch) => {
  return new Promise((resolve) => {
  axios.post(`${SERVER_URI}/api/auth/login`, input)
    .then((response) => {
      console.log(response.data)
      const user = response.data.user
      console.log(SERVER_URI)
      console.log(user)
      const test = jwt(response.data.token)
      console.log(test, "THIS IS THE SET USER TEST")
      dispatch({ type: SET_TOKEN, payload: response.data.token })
      dispatch({ type: SET_USER, payload: test })
      localStorage.setItem('token', response.data.token)
      localStorage.setItem('full_name', user.fullName)
      localStorage.setItem('id', response.data.user.id)
      localStorage.setItem('full_name', response.data.user.fullName)
      localStorage.setItem('stripeAccount', response.data.user.stripeAccountId)
      localStorage.setItem('stripeID', response.data.user.stripeID)
      localStorage.setItem('pendingEarnings', response.data.user.pendingEarnings)
      resolve(response.data.message);
    })
    .catch((error) => {;
    dispatch({ type: SET_ERROR, payload: error.response });
    resolve(error.response.data.message);
});
});
};

export const forgotPassword = (email) => (dispatch) => {
  console.log('Invoking forgotPassword with email:', email);
  axios.post(`${SERVER_URI}/api/reset/forgotpassword`, email)
    .then((response) => {
      dispatch({ type: RESET_PASSWORD_MESSAGE, payload: response.data })
    })
    .catch((error) => {
      console.error('Request error:', error);
      if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
      } else if (error.request) {
        // The request was made but no response was received
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Axios Configuration Error:', error.message);
      }
      
      dispatch({ type: RESET_PASSWORD_ERROR, payload: error })
    });
}

export const changePassword = (token, password) => (dispatch) => {
  axios.post(`${SERVER_URI}/api/reset/updatePasswordViaEmail`, { token: token, password: password })
    .then((response) => {
      console.log(response, 'this is the response!!')
      dispatch({ type: RESET_PASSWORD_MESSAGE, payload: response.data })
    })
    .catch((error) => {
      console.log(error, 'this is the fake error!!')
      dispatch({ type: RESET_PASSWORD_ERROR, payload: true }, error)
    })
}

export const preserveState = (token) => (dispatch) => {
  const user = jwt(token)
  dispatch({ type: SET_TOKEN, payload: token })
  dispatch({ type: SET_USER, payload: user })
}

export const resetError = () => (dispatch) => dispatch({ type: RESET_ERROR })

export const successRedirect = (history, token) => (dispatch) => {
  localStorage.setItem('token', token)
  if (token) {
    dispatch(preserveState(token))
  }
  history.push('/dashboard')
}

export const signOut = (history) => (dispatch) => {
  localStorage.removeItem('token')
  axios.get(`${SERVER_URI}/api/auth/signout`)
    .then((response) => {
      console.log(response)
      localStorage.setItem('token', '')
      localStorage.removeItem('token')
      localStorage.removeItem('id')
      localStorage.removeItem('full_name')
      history.push('/login')
    })
    .catch((error) => console.log(error))
}
