import styled from 'styled-components'

export const Wrapper = styled.div`
  h1{
    margin-top: 4px;
    font-size: 20px;
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 1s; /* Firefox < 16 */
        -ms-animation: fadein 1s; /* Internet Explorer */
         -o-animation: fadein 1s; /* Opera < 12.1 */
            animation: fadein 1s;
  }

  @keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }

  /* Internet Explorer */
  @-ms-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }

  /* Opera < 12.1 */
  @-o-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }
  h2{
    margin-top: 15px;
    margin-bottom: 0px;
  }
  h3{
    font-size: 23px;
    color: white;
    text-shadow: 1px 1px 2px #959597;
  }
  .logout{
   display: block;
   right: 10px;
   margin-top: -60px;
   position: absolute;
  @media (max-width: 992.5px) {
    display: none;
  }
}
  .logout:hover {
    transform: translateY(-5px);
    box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.15);
  }
  .logoutCard {
    padding: 1rem;
    padding-right: .4rem;
    height: 3.3rem;
    background: linear-gradient(90deg, #Ede0f7 0%, #B993ee 100%);
    border: none;
    border-radius: .7rem;
    box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
    transition: box-shadow .1s ease,transform .1s ease;
    cursor: pointer;
  }
  .logoutButton{
    background: none;
    border: none;
    color: white;
    text-shadow: 1px 1px 2px #959597;
    cursor: pointer;
    font-size: 17px;
  }
  .section {
    padding-top: calc(5% + 25px);
    padding-bottom: calc(5% + 20px);
    display: block;
    overflow: auto;
    -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 2s; /* Firefox < 16 */
        -ms-animation: fadein 2s; /* Internet Explorer */
         -o-animation: fadein 2s; /* Opera < 12.1 */
            animation: fadein 2s;
  }

  @keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }

  /* Firefox < 16 */
  @-moz-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }

  /* Safari, Chrome and Opera > 12.1 */
  @-webkit-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }

  /* Internet Explorer */
  @-ms-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }

  /* Opera < 12.1 */
  @-o-keyframes fadein {
      from { opacity: 0; }
      to   { opacity: 1; }
  }

    .cards {
      display: grid;
      width: auto;
      margin-top: calc(5% - 10px);
      padding: .3rem;
    }

    .dashboardCards{
      display: grid;
      grid-gap: 1rem;
      margin-top: calc(5% - 17px);
    }
    .dashboardCard {
      display: flex;
      justify-content: center;
      padding: 1rem;
      height: 5rem;
      margin: 0.3rem;
      background: linear-gradient(90deg, #Ede0f7 0%, #B993ee 100%);
      border: none;
      border-radius: .7rem;
      box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
      transition: box-shadow .1s ease,transform .1s ease;
      cursor: pointer;
    }
    .profileCard{
      width: auto;
      align-items: center;
      padding: .5rem;
    }
    .profileButton:hover{
      transform: translateY(-5px);
      box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.15);
    }
    .profileButton{
      max-width: 1200px;
      grid-gap: 1rem;
      padding: 1rem;
      margin: 0.5rem;
      background: linear-gradient(90deg, #Ede0f7 0%, #B993ee 100%);
      border: none;
      color: #194d8c;
      border: none;
      border-radius: .7rem;
      box-shadow: 0 1px 3px 0 #d4d4d5, 0 0 0 1px #d4d4d5;
      transition: box-shadow .1s ease,transform .1s ease;
      cursor: pointer;
      font-size: 17px;
    }
    .ui.card {
      background: none;
      border: none;
      margin: 0 auto;
      box-shadow: none;
    }
    .ui.card>.content {
      border: none;
    }
    .profileTitle{
      padding-bottom: 10px;
    }
    .cardbutton {
      background: none;
      border: none;
      color: #194d8c;
      cursor: pointer;
      font-size: 17px;
      vertical-align: middle;
    }
    @media (min-width: 600px) {
      .cards { grid-template-columns: repeat(2, 1fr); }
    }
    @media (min-width: 900px) {
      .cards { grid-template-columns: repeat(2, 2fr); }
    }
    .dashboardCard:hover {
      transform: translateY(-5px);
      box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.15);
    }
    .dashboardCard a{
      color: #194d8c;
    }
  }
`;
