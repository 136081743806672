// import { options } from 'marked';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom';
// import { Menu, Dropdown, Button } from 'antd'
// import { createSelector } from 'reselect';
// import { Card, Button } from 'semantic-ui-react'
import { getMyProfile } from 'redux/actions/profileActions';
import { Wrapper } from 'components/Dashboard/DashboardStyles'
import { Card, Icon } from 'semantic-ui-react'

const Dashboard = (props) => {
  const dispatch = useDispatch()
  const state = useSelector(state => state)
  const id = state.auth.user.id
  const myProfile = state.profile.myProfile
  const image = state.profile.myProfile.image
  const name = state.auth.user.fullName
  console.log(state.auth.user, 'this is from dashboard!')
  // const token = localStorage.getItem('token')
  console.log(state.auth.user.type, 'stttaaatte on dashboard')


  const userType = state.auth.user.type
  console.log(userType)

  console.log(props, 'this are porpspsps');

  const handleLogout = () => {
    localStorage.clear('token')
    // localStorage.clear('id')
    // localStorage.clear('state')
    // localStorage.clear('full_name')
    // sessionStorage.clear()
    // window.location.reload()
    // props.history.push('/login')

  }

  useEffect(() => {
    dispatch(getMyProfile(id))
  }, [dispatch, id])

  // const menu = (
  //   <Menu>
  //   <Menu.Item>
  //     <p>{name}</p>
  //   </Menu.Item>
  //   <Menu.Item>
  //     <Link to='/my-profile'>My Profile</Link>
  //   </Menu.Item>
  //   <Menu.Item>
  //     <a target="_blank" rel="noopener noreferrer" href="http://www.tmall.com/">
  //       Logout
  //     </a>
  //   </Menu.Item>
  // </Menu>
  // )

  return (
    <Wrapper>
      <h1>Welcome, {name}</h1>
      <div className="section">

        <div className="logout">
            <a href='/login' className='logoutCard' onClick={handleLogout}>
            <button  className='logoutButton'>
              Logout
              <Icon name='chevron right'></Icon>
            </button>
            </a>
        </div>
        {/* <NavLink to="/dashboard" className="link">Dashboard</NavLink> */}

        {/* <Dropdown overlay={menu} placement="bottomCenter" arrow>
        <Button>{name}</Button>
        </Dropdown> */}
        <div>
        <h2>Dashboard</h2>
        </div>




        <div className='cards'>

        <a href='/my-profile' className='profileButton'>
        <div className='profileCard'>
            <Card>
            <Card.Content>
            <img
              src={image}
              alt={'profile pic'}
              style={{
                height: '200px',
                width: '200px',
                borderRadius: '50%',
              }}
              className='image'
            />
            </Card.Content>
            </Card>
            <div className="profileTitle">
            <h3>My Profile</h3>
            </div>
          </div>
          </a>

          <div className='dashboardCards'>
          { state.auth.user.type === 'influencer'
            ? <a href='/brandsearch' className='dashboardCard'>
          <button className='cardbutton'>
            <h3>Search For Brands</h3>
          </button>
            </a>
            : <a href='/influencersearch' className='dashboardCard'>
            <button className='cardbutton'>
              <h3>Search For Creators</h3>
            </button>
            </a>
            }



            { state.auth.user.type === 'influencer'
            ? <a href='/dashboard/influencer/inbox' className='dashboardCard'>
          <button className='cardbutton'>
            <h3>Messages</h3>
          </button>
            </a>
            : <a href='/dashboard/brand/inbox' className='dashboardCard'>
            <button className='cardbutton'>
              <h3>Messages</h3>
            </button>
            </a>
              }

            { state.auth.user.type === 'influencer'
          ? <a href='/Payment-Portal-Two' className='dashboardCard'>
          <button className='cardbutton'>
            <h3>Payment Portal</h3>
          </button>
          </a>
          : <a href='/Payment-Portal' className='dashboardCard'>
          <button className='cardbutton'>
            <h3>Payment Portal</h3>
          </button>
          </a>
          }

          </div>
        </div>

      </div>
    </Wrapper>

  )
}

export default Dashboard;
