
import styled from 'styled-components'


export const Wrapper = styled.div`



.section {
    height: 100%;
    width: 100%;
  }
`
