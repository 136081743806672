import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getUserById } from 'redux/actions/userActions';
import { useDispatch } from 'react-redux'

import { Button } from 'semantic-ui-react'
import { Wrapper } from './ProductStyles'

const Product = ({ product, selected, handleClick }) => {

  const state = useSelector((state) => state)
  const dispatch = useDispatch()
  const userID = state.auth.user.id
  const stripeCustomer = state.user.person.stripeID
  const stripeSubscription = state.user.person.subscriptionType

  useEffect(() => {
    dispatch(getUserById(userID));
  }, [dispatch, userID]);

  
  return (
    <Wrapper>
    <div className='prod-section'>
      <div className='top'>
        <div className='product'>
          <h4>{product.name}</h4>
          <p>{product.description}</p>
        </div>
          <div className='price-info'>
            <p className='price'>{product.price}</p>
            <p>Billed {product.billed}</p>
          </div>
        </div>

      <div className='bottom'>


      {stripeSubscription === product.name ? (
            <h4>Current Plan</h4>
          ) : (
            <>
              {selected ? (
                <Button type='submit'>Selected</Button>
              ) : (
                <Button onClick={() => handleClick(product.key)} type='submit'>
                  Select
                </Button>
              )}
            </>
          )}
      </div>
    </div>
    </Wrapper>
  )
}

export default Product