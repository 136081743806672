import styled from 'styled-components'

export const Wrapper = styled.div`
  .search-section {
    /* height: 100vh; */
    height: 100%;
    margin-top: 6.4rem;
    margin-bottom: 4.4rem;
    /* border: 3px dotted darkolivegreen; */
}

.toplink {
  /* border: 1px dotted black; */
  display: block;
  margin-top: 7rem;
  margin-left: 2rem;
  width: 160px;

  .ui.button {
    margin-top: 2rem;
    background: linear-gradient(90deg, #Ede0f7 0%, #B993ee 100%);
    color: white;
    text-shadow: 1px 1px 2px #959597;
    font-size: 17px;
    border: none;
    border-radius: .7rem;

    .link {
      color: white;
    }
  }
}
    h1 {
      padding-top: 20px;
    }

    .brands-list {
      /* border: 2px dashed purple; */
      display: flex;
      flex-wrap: wrap;
      justify-content: space-evenly;

      .brand {
        display: flex;
        justify-content: center;
        /* border: 2px double purple; */
      }
      .ui.card {
        align-items: center;
        width: 450px;
        margin-top: 2rem;
        padding-bottom: 1rem;
      }
    }
  }
  }



`;
