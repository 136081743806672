import React from 'react'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import logoLight from 'assets/images/endorse-logo-white.png'
import { NavLink } from 'react-router-dom'
import './Footer.css'

const onClick = () => {
  if (window.location.pathname === '/stripe-prices') {
    console.log(true);
    localStorage.removeItem('stripeID')
  } else {
    console.log(false);
  }
}

const Footer = () => {
  return (
    
    <footer>
      <div className='footerHeader'></div>
      
       <div className="site-footer text-light">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <a class="footer-logo d-block">
              <img src={logoLight} alt=""/>
            </a>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4 col-lg">
            <h4 className="footer-title">
              Connect With Us
            </h4>
            <ul className="social-links">
            <li>
              <a
                className='link'
                href='https://www.instagram.com/getendorse/'
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none", color: "#6CB7D9" }}
              >
                <FontAwesomeIcon icon={['fab', 'instagram']} size='1x' />
              </a>
              
              </li>

              <li>
              <a
                className='link'
                href='https://twitter.com/get_endorse'
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none", color: "#6CB7D9" }}
              >
                <FontAwesomeIcon icon={['fab', 'twitter']} size='1x' />
              </a>
              </li>

            </ul>
          </div>

          <div className="col-md-4 col-lg">
            <h4 className="footer-title">
              Contact Details
            </h4>
            <ul className="contact-details">
              <li><a href="mailto:admin@getendorse.com"style={{ textDecoration: "none", color: "#6CB7D9" }}><i className="fa fa-envelope"></i>Send Us An Email</a></li>             
            </ul>
          </div>

          <div className="col-md-12 col-lg-4">
            <ul className="footer-menu">
              <li><Link to='/terms' className='terms'style={{ textDecoration: "none", color: "#6CB7D9" }}>Terms</Link></li>
              <li><Link to='/privacy' className='privacy'style={{ textDecoration: "none", color: "#6CB7D9" }}>Privacy</Link></li>
            </ul>
          </div>

          
        </div>

        <div className="row copyrights-row">
          <div className="col-md-12">
            <p className="text-center">© 2020-2024 - Endorse - All Rights Reserved.</p>
          </div>
        </div>



      </div>
      </div>
    </footer>
    
      
  )
}

export default Footer
