import styled from 'styled-components'

export const Wrapper = styled.div`
  .top-btn {
    background: radial-gradient(ellipse at top, #23d8f8, transparent), radial-gradient(ellipse at bottom, #8a58ee, #9ed2ff);
    color: white;
    cursor: pointer;
  }

  .top-btn:hover {
    background: radial-gradient(ellipse at top, #23d8f8, transparent), radial-gradient(ellipse at bottom, #8a58ee, #9ed2ff);
    color: white;
    cursor: pointer;
  }

   .top-btn-two {
        background: linear-gradient(90deg, rgb(237, 224, 247) 0%, rgb(185, 147, 238) 100%);
    color: white;
    cursor: pointer;
  }

  .top-btn-two:hover {
        background: linear-gradient(90deg, rgb(237, 224, 247) 0%, rgb(185, 147, 238) 100%);
    color: white;
    cursor: pointer;
  }


  .back-btn {
  margin: 5px;
  width: 30%;
  }

  .top-btn {
  border-radius: 10px;
  }
`;