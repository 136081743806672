import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Talk from 'talkjs'
import { NavLink } from 'react-router-dom'
import { Wrapper } from './MessagingStyles'
import { Button, Icon } from 'semantic-ui-react'

const BrandMessaging = (props) => {

  const state = useSelector(state => state)
  const id = state.auth.user.id
  const name = state.auth.user.fullName
  const email = state.auth.user.email
  const role = state.auth.user.type
  const photoUrl = state.profile.myProfile.image
  const talkjsContainer = React.createRef()
  let inbox = undefined;

  const [conv, setConv] = useState()
  const [filter, setFilter] = useState('all');

  // Check message url for influencer id and username
  // const url = window.location.href;
  const search = window.location.search.substring(1);
  let i;
  if (search) {
     i = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) { return key === "" ? value : decodeURIComponent(value) });
  }
  const influencerId = i?.chatWith;
  const influencerName = i?.username;

  const TalkFunction = (_currentConv, _isActive) => {
    let currentConv = _currentConv;
    let isActive = _isActive;

    Talk.ready.then(() => {

      const me = new Talk.User({
        id: id,
        name: name,
        email: email,
        role: role,
        photoUrl: photoUrl
      })

      const session = new Talk.Session({
        appId: 'tHRSmz20',
         me: me
      })

      const other = new Talk.User({
        id: influencerId || id,
        name: influencerName || name
      })


      const conversationId = currentConv ? currentConv : Talk.oneOnOneId(me, other);

      const conversation = session.getOrCreateConversation(conversationId);

      conversation.setParticipant(me)
      conversation.setParticipant(other)

      if (isActive === 'active') {
        console.log(`*** MARKING ACTIVE // ${conversation.id} ***`)
        conversation.setAttributes({ custom: { state: "active" } })
      } else if (isActive === 'complete') {
        console.log(`*** MARKING COMPLETE // ${conversation.id} ***`)
        conversation.setAttributes({ custom: { state: "completed" } })
      } else if (isActive === 'unmark') {
        console.log(`*** UNMARKING // ${conversation.id} ***`)
        conversation.setAttributes({ custom: { state: 'unmarked' } })
      }

      inbox = session.createInbox({
        selected: conversation
      });

      if (filter === 'active') {
        inbox.setFeedFilter({ custom: { state: ["==", "active"] } })
      } else if(filter === 'completed') {
        inbox.setFeedFilter({ custom: { state: ["==", "completed"] } })
      }

      inbox.mount(talkjsContainer.current)

      inbox.on('conversationSelected', (evt) => {
        setConv(evt?.conversation?.id);
      })

    })
    .catch(error => {
      console.log(error, 'SOME ERROR OCCURED');
    })
  }


  useEffect(() => {
    TalkFunction(conv);

    // unmounts the inbox when page is left, etc.
    return (() => {
      if (inbox) {
      inbox.destroy();
      }
    })

    // dependency for when filter is changed TalkFunction is called again
  }, [filter])

  // useEffect(() => {
  //   TalkFunction()
  // }, [TalkFunction])


  const markActive = () => {
    TalkFunction(conv, 'active')
  }

  const markCompleted = () => {
    TalkFunction(conv, 'complete')
  }

  const unmark = () => {
    TalkFunction(conv, 'unmark')
  }

  const showActive = () => {
    setFilter('active');
  }

  const showCompleted = () => {
    setFilter('completed');
  }

  const showAll = () => {
    setFilter('all');
  }

  return (
    <Wrapper>
    <div className='container'>

    <div className='toplink'>
    <a href='/dashboard'>
     <Button className='normal-btn'>
         <div className="link">
         <Icon name='chevron left'></Icon>
         Dashboard</div>
       </Button>
       </a>
       </div>
      <h1>Inbox</h1>

      <div className='top-btns' style={{marginTop: '10px'}}>

          <Button className='blue-btn' onClick={showAll}>All</Button>
          <Button className='blue-btn' onClick={showActive}>Active</Button>
          <Button className='blue-btn' onClick={showCompleted}>Completed</Button>
      </div>
      <div className='showing'>
        <span>{`${filter}`}</span>
      </div>
      <div ref={talkjsContainer} className='chatbox-container' style={{
        height: '500px',
        width: '90%',
        margin: '30px'
      }}>
      </div>
      <div className='bottom-btns'>
        <Button className='blue-btn' onClick={markActive}>Mark Active</Button>
        <Button className='blue-btn' onClick={markCompleted}>Mark Completed</Button>
        <Button className='blue-btn' onClick={unmark}>Unmark</Button>
      </div>
    </div>
    </Wrapper>
  )
}

export default BrandMessaging
