import styled from 'styled-components'


export const Wrapper = styled.div`
.section {
  height: 100%;
  margin-top: 4.4rem;
  margin-bottom: 80px;

  .toplink {
    display: block;
    margin-top: 7rem;
    margin-left: 2rem;
    width: 160px;

    .ui.button {
      margin-top: 2rem;
      background: linear-gradient(90deg, #Ede0f7 0%, #B993ee 100%);
      color: white;
      text-shadow: 1px 1px 2px #959597;
      font-size: 17px;
      border: none;
      border-radius: .7rem;

      .link {
        color: white;
      }
    }
  }
  .ui.button:hover {
    transform: translateY(-3px);
            box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.15);
    }

    .taskBtns {
      display: grid;
      grid-gap: 10px; /* Space between buttons */
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      justify-items: center;
      align-items: center;
    }

    @media (max-width: 700px) {
      .taskBtns {
        /* Change to a single column */
        grid-template-columns: repeat(1, 1fr);
      }
    }
    .taskFilter {
      margin: 2px 1px;
      max-width: 300px;
      min-width: 100px;
      background: linear-gradient(90deg, #Ede0f7 0%, #B993ee 100%);
      color: white;
      text-shadow: 1px 1px 2px #959597;
      font-size: 17px;
      border: none;
      border-radius: .7rem;
    }
    .taskFilter:hover {
      transform: translateY(-3px);
            box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.15);
    }
  }



    .tasksTitle {
        display: grid;
        max-width: 1200px;
        margin: 0 auto;
        padding-top: calc(5% - 10px);
        grid-gap: 1px;
        grid-template-columns: repeat(3, 1fr);
      }
      .brandTask{
        display: flex;
        border-bottom: .25rem solid black;
        align-items: center;
        justify-content: center;
      }
      .tasks {
        display: grid;
        max-width: 1200px;
        margin: 0 auto;
        padding-top: calc(5% - 10px);
        grid-gap: 1px;
        grid-template-columns: repeat(3, 2fr);
      }
    `;