import React from 'react';
import { NavLink } from 'react-router-dom';

const Deals = () => {
  return (
    <div className="section">
      <NavLink to="/dashboard" className="link">Dashboard</NavLink>
      <p>This is the deals page</p>
    </div>
  )
};

export default Deals;