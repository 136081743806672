import React,  {useContext, useState, useEffect}  from 'react';
import { auth } from "auth/firebase/firebase.js";
import {createUserWithEmailAndPassword, signInWithEmailAndPassword} from "firebase/auth";
import { registerRequest } from 'redux/actions/newAuthActions'
import { connect } from 'react-redux'


 const AuthContext = React.createContext()

 export function useAuth() {
   return useContext(AuthContext)
}

 export function AuthProvider({ children }) {
  const[currentUser, setCurrentUser] = useState()
  const[loading, setLoading] = useState(true)

  function signup(email, password) {
    return createUserWithEmailAndPassword(auth, email, password)
  }

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password)
  }

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user)
      setLoading(false)
    })

    return unsubscribe
  }, [])

  const value = {
    currentUser,
    login,
    signup
  }


  const [ input, setInput ] = useState({
    fullName: '',
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    type: '',
  })


  return(
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
const mapStateToProps = state => {
  return {
    error: state.error,
  }
}
