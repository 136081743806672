import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import StripeSubscription from './StripeSubscription.js'
import Product from './Product'
import { Button, Card, Icon } from 'semantic-ui-react'
import { Wrapper } from './PricesStyles'
import { getUserById } from 'redux/actions/userActions';
import { useDispatch } from 'react-redux'
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./stripeCheckout.js";
import PaymentForm from './PaymentForm.js'

import { SERVER_URI, PRODUCT_FREE, PRODUCT_PREMIUM, PRODUCT_PREMIUM_ID} from 'config.js'

const stripePromise = loadStripe("pk_live_ONeyIlHWwMZQTowOUsIufjFM00DViPqsjR");
//const Stripe = Stripe('pk_test_J2WkKzP49szqXu60AFjvDMgL00L1DEw0OO');

const options = {
  clientSecret: '{{CLIENT_SECRET}}',
  // Fully customizable with appearance API.
  appearance: {/*...*/},
};


const products = [
  
  {
    key: 0,
    price: 'Free',
    name: 'Basic',
    interval: 'month',
    billed: 'monthly',
    priceId: PRODUCT_FREE,
    description: 'To activate Promotion Payments Sign Up.'
  },
  {
    key: 1,
    price: '$10.00',
    name: 'Premium',
    interval: 'month',
    billed: 'monthly',
    priceId: PRODUCT_PREMIUM,
    subscriptionId: PRODUCT_PREMIUM_ID,
    description: 'To activate Promotion Payments Sign Up Here. Premium Accounts recieve minimal fees for all payments.'
  }
]



const Prices = ({ location }) => {
  const state = useSelector((state) => state)
  const dispatch = useDispatch()
  const userID = state.auth.user.id
  const [ productSelected, setProductSelected ] = useState(null)
  const customer = state.user.person.stripeID
  const [clientSecret, setClientSecret] = useState("");
  const id = state.auth.user.id
  //const priceId = productSelected.priceId;
  //const customerId = state.user.person.stripeID;
  //const [ customer ] = localStorage.getItem('stripeID')

  //localStorage.setItem('stripeID', customer.id) // setting the stripe cus ID here

  const stripeCustomer = state.user.person.stripeID
  const stripeSubscription = state.user.person.subscriptionType

  const stripeID = customer.id;
  console.log('this is current subscription:', {stripeSubscription});
  console.log('this is stripeID:', {stripeCustomer});

  const url = window.location.href
  console.log('## URL: ', url);

  useEffect(() => {
    dispatch(getUserById(userID));
  }, [dispatch, userID]);

  useEffect(() => {
    console.log('## customer: ', {customer});
    window.onpopstate = e => {
      e.preventDefault()
      console.log('it worked')
      //localStorage.removeItem('stripeID')
    }
  })

  const handleClick = (key) => {
    setProductSelected(products[key])

  }

  console.log(productSelected, 'this is current product selected')




  
  /*
  const handleCancel = e => {
    e.preventDefault()
    localStorage.removeItem('stripeID')
    fetch(`${SERVER_URI}/api/stripe/delete-customer`, {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: userID,
        stripeID: stripeID
      })
    })
    .then((response) => {
      console.log(response, 'this was the res on Prices');

    })
    .catch((error) => {
      console.log(error, 'this was an error on Prices');
    })
  }
*/

  return (
    <Wrapper>
    <div className='section'>


    
      <Link to="/my-profile">
        <button  className="cancelBtn">
        <Icon name='chevron left'></Icon>
          Dashboard
        </button>
        </Link>

    

      <h2>Choose Your Plan</h2>
      

      <div className='products'>
        {products.map((product, index) => {
          return (
            <Card className='card' raised key={index}>
            <Product
              key={index}
              product={product}
              handleClick={() => handleClick(index)}
              selected={productSelected === product}
            />
            </Card>
          )
        })}
      </div>
      <div>
        {productSelected ? (
          <PaymentForm
          productSelected={productSelected}
          customer={stripeCustomer}
          userID={userID}
        />
        ) : null}
      </div>
    </div>
    </Wrapper>
  )
}

export default withRouter(Prices)
