import {db} from 'auth/firebase/firebase.js'
import { useAuth } from 'auth/firebase/AuthContext.js'


import axios from 'axios'
import { SERVER_URI } from 'config'
import jwt from 'jwt-decode'
import {
  SET_TOKEN,
  SET_USER,
} from './types'

console.log(SERVER_URI)

export const registerRequest = (input, type, history) => (dispatch) => {
  axios.post(`${SERVER_URI}/api/auth/Register`, {
    fullName: input.fullName,
    username: input.fullName,
    email: input.email,
    password: input.password,
    type: type,
  })
  .then((response) => {
  console.log(SERVER_URI)
  const user = response.data.user
  const test = jwt(response.data.token)
  dispatch({ type: SET_TOKEN, payload: response.data.token })
  dispatch({ type: SET_USER, payload: test })
  localStorage.setItem('token', response.data.token)
  localStorage.setItem('id', response.data.user.id)
  localStorage.setItem('full_name', user.fullName)
  history.push('/login')
})
}

export const loginRequest = (email, password, history) => (dispatch) => {
  axios.post(`${SERVER_URI}/api/auth/NewLogin`, email, password)
    .then((response) => {
      const user = response.data.user
      console.log(user)
      const test = jwt(response.data.token)
      console.log(response.data)
      console.log(test)
      dispatch({ type: SET_TOKEN, payload: response.data.token })
      dispatch({ type: SET_USER, payload: test })
      localStorage.setItem('token', response.data.token)
      localStorage.setItem('full_name', user.fullName)
      localStorage.setItem('id', response.data.user.id)
      localStorage.setItem('full_name', response.data.user.fullName)
      history.push('/dashboard')
    })
  }
  export const preserveState = (token) => (dispatch) => {
    const user = jwt(token)
    dispatch({ type: SET_TOKEN, payload: token })
    dispatch({ type: SET_USER, payload: user })
  }

  export const successRedirect = (history, token) => (dispatch) => {
    localStorage.setItem('token', token)
    if (token) {
      dispatch(preserveState(token))
    }
    history.push('/dashboard')
  }
