import styled from 'styled-components'

export const Wrapper = styled.div`

.site-header {
  /* background: #45B5E3; */
  background: transparent;
  position: absolute;
  left: 5px;
  top: 0;
  right: 0;
  /* these below are for mobile css */
  z-index: 1111;
  padding: 0px 10px;

  .container {

    .row {
      align-items: center;
      padding-top: 2rem;
      padding-bottom: 0.75rem;

      .navigation-col {
        padding-left: 20rem;
      }

        .menu-wrap{
        margin-right: 7.5%;
        margin-left: 15%;
        font-size: 1.2em;
      }

        .menu {
          padding-inline-start: 0px;
          display: inline-flex;
          justify-content: space-evenly;
          list-style: none;
        }
        .main-menu{
          display: inline-flex;
        }

        .menu li {
          margin: 0px 20px;


          .link {
            color: #fff; /* this is the white about blog dashboard button */
            margin: 0px 20px;

            &:hover {
              cursor: pointer;
              text-shadow: 0 0 3px #be68cd, 0 0 3px #be68cd, 0 0 3px #be68cd, 0 0 3px #be68cd;
              font-size: 20px;
            }
          }

          .active {
            font-weight: 100;
            color: #151B55;
          }
        }
      }
      .account-btns{
        display: flex;
      }
      .header-btns {
       font-size: 1em;
       padding-inline-start: 0px;
       display: flex;
       float: right;
        .signup { /******* .header-btns li a  ********/
          &:hover {
            opacity: 0.8;
          }

          @media (max-width: 1200px) {

            &:hover {
              transform: translateY(-5px);
              box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.15);
              opacity: 0.8;
            }
          }

        }

        .login { /******* .header-btns li a ********/
          &:hover {
            opacity: 0.8;
          }

          @media (max-width: 1200px) {

            &:hover {
              transform: translateY(-5px);
              box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.15);
              opacity: 1;
            }
          }
        }

        .reg-icon { /******* .header-btns li a i ********/
          margin-right: 10px;

          @media (max-width: 1200px) {
            padding: 0px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            background: rgba(255, 255, 255, 0.2);
            border-radius: 50px;

            font-size: 16px;
            margin-right: 0px;


          }
        }

        .login-icon { /******* .header-btns li a.login AND .header-btns li a i ********/
          margin-right: 10px;

          @media (max-width: 1200px) {
              padding: 0;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              width: 40px;
              height: 40px;
              background: rgba(255, 255, 255, 0.2);
              border-radius: 50px;

              font-size: 16px;
              margin-right: 0px;

            }
          }

        li {          
          &:first-of-type {
            margin-right: 25px;
          }

          a { /******* .header-btns li a ********/
            color: #fff;
            width: max-content;

            @media (max-width: 12px) {
              font-size: 0px;
              padding: 0;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              width: 40px;
              height: 40px;
              background: rgba(255, 255, 255, 0.2);
              border-radius: 50px;

              &:hover {
                transform: translateY(-5px);
                box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.15);
                opacity: 1;
              }
            }
          }

          @media (max-width: 1200px) {
            &:first-of-type {
              margin-right: 10px;
            }
          }
        }

       .mobile-menu {
        display: block;
        align-items: center;
        padding-top: 15px;

        @media only screen and (min-width: 992px) {
          display: none;
         }
        }


        .navbar-toggler {
          display: block;
          align-items: center;
          color: purple;
          outline: none;
          transition: 0.1s ease-in-out;
          transform-origin: center;

          &:active {
            transform: scale(0.9);
          }

          .icon {
            margin-right: 10px;
          }

          @media (min-width: 992px) {  /* this is d-lg none*/
            display: none;
          }
        }

        @media (max-width: 1200px) {
          .signup { /* THIS IS .header-btns li */
            display: none;
            margin: 15px 5px;
          }

          .login { /* THIS IS .header-btns li */
            display: none;
            margin: 15px 5px;
          }
        }
        @media (max-width: 1200px) and (min-width: 993px){
          .signup { /* THIS IS .header-btns li */
            display: inline-flex;
            margin: 15px 5px;
          }

          .login { /* THIS IS .header-btns li */
            display: inline-flex;
            margin: 15px 5px;
          }
        }

        @media (min-width: 768px) and (max-width: 992px) {
          .container,
          .container-fluid {
            padding-bottom: 50px;
          }
        }
      }
    }


  }




}








/*
  .header-btns li::first-of-type {
    margin-right: 25px;
  }

  .header-btns li a {
    color: #fff;
    width: max-content;
  }

  .header-btns li a i {
    margin-right: 10px;
  }

  .header-btns li a .login {
    padding: 12px 35px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50px;
    display: inline-block;
  }

  .header-btns li a::hover {
    opacity: 0.8;
  }

  .header-btns li a.login::hover {
    transform: translateY(-5px);
    box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.15);
    opacity: 1;
  }

  .navbar-toggler {
    @media (min-width: 1024px) {
      display: none;
    }
  }

  .navbar-toggler::active {
    transform: scale(0.9);
  }

  .navbar-toggler i {
  margin-right: 10px;
}

  .navbar-collapse {
    background: #fff;
    margin-right: -15px;
    margin-left: -15px;
    padding: 0 30px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
  }

  .navbar-collapse li:not(:last-child) {
    border-bottom: 1px solid #e9e9e9;
    margin: 5px 0;
  }

  .navbar-collapse .navbar-nav {
    padding: 20px 0;
  }

  .navbar-nav .nav-link {
    color: #151B55;
    font-weight: 500;
    text-transform: uppercase;
  } */
`;
