import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Wrapper } from './stripeSubscriptionStyles.js'
import { Button } from 'semantic-ui-react'
import { loadStripe } from '@stripe/stripe-js'
import { useDispatch } from 'react-redux'
import {
  CardElement,
  Elements,
  useStripe,
} from '@stripe/react-stripe-js'
import { Redirect } from 'react-router-dom'
import {SERVER_URI} from 'config'
import { getUserById, editUserSubscription } from 'redux/actions/userActions';
import axios from 'axios'
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
const stripe = require('stripe')(process.env.REACT_APP_STRIPE_SECRET_KEY);

const StripeSubscription = ({ productSelected, customer }) => {
    const state = useSelector((state) => state)
    const dispatch = useDispatch()
    const stripe = useStripe()
    const userID = state.auth.user.id
    const id = state.auth.user.id
   // const elements = useElements()
    const [ subscribing, setSubscribing ] = useState(false)
    const [ accountInformation, setAccountInformation ] = useState(null)
    const [ errorToDisplay, setErrorToDisplay ] = useState('')

    const stripeSubscription = state.user.person.subscriptionType

    console.log(productSelected.name, 'this is current product selected')

    const [ isLoading, setIsLoading ] = useState(false)


    const handleSubmit = async () => {
      setIsLoading(true);
      const update = await axios.post(`${SERVER_URI}/api/stripe/update-subscription`, {
        subscriptionType: productSelected.name,
        priceId: productSelected.priceId,
        userID: userID
    });
    console.log('this is update:', update);

  setIsLoading(false);
};


    return (
        <Wrapper>
            <div className='section'>
                <div className='Subscription'>
                    <h2>Subscription Effective Immediately</h2>
                    <h3>New Subscription: {productSelected.name}</h3>
                    <p>Price: {productSelected.price}</p>
                    <p>Effective Date: {new Date().toLocaleDateString()}</p>
                    <Button type='submit' onClick={handleSubmit}> Confirm </Button>
                </div>
            </div>        
        </Wrapper>
    )
}
    



export default StripeSubscription
                
