import { FETCH_ASSET } from 'redux/actions/types'

export const reducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_ASSET:
      return [ action.payload.data, ...state ]
    default:
      return state
  }
}

