import React from 'react'
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import DocumentMeta from 'react-document-meta';


import { usePosts } from '../customHooks'
import {readableDate } from '../helpers'
// import "./posts.css";
import { Wrapper } from './postsStyles'
import './posts.css'

export default function Posts() {
  const [ posts, isLoading ] = usePosts()

  const meta = {
    title: 'Endorse Blog',
    description: 'Endorse blogs covering all things surrounding social media, content creation, and content marketing.',
    canonical: 'https://getendorse.com/blog',
    meta: {
        charset: 'utf-8',
        name: {
            keywords: 'endorse blog, content marketing blog, content creator blog'
        }
    }
  }


  const renderPosts = () => {
    if (isLoading) return <p>Loading...</p>

    return posts.map(post => (


      <Link
        className="posts__post"
        key={post.fields.slug}
        to={'blog/' + post.fields.slug}
      >
        {/* {console.log(post.fields.slug, 'THIS IS POST')} */}
        <div className="posts__post__img__container">
          <img
            className="posts__post__img__container__img"
            src={post.fields.featuredImage.fields.file.url}
            alt={post.fields.title}
          />
        </div>
        <small>{readableDate(post.fields.date)}</small>
        {/* {console.log(post.fields, 'THIS IS POST')} */}
        <h3>{post.fields.title}</h3>

        <p>{post.fields.metaDescription}</p>
      </Link>
    ))

  }

  return (
    <DocumentMeta {...meta} >
    <Wrapper>
      
    <div className='postsBackground'></div>
    <div className="posts__container" style={{marginTop: '5rem'}}>
      <div className="posts-title">
      <h2>Blog</h2>
      </div>
      
      <div className="posts">{renderPosts()}</div>
    </div>
    </Wrapper>
    </DocumentMeta>
  )
}
