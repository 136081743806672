import { axiosWithAuth } from 'utils/axiosWithAuth'
import axios from 'axios';
import { SERVER_URI } from 'config'
import {
  GET_ALL_USERS_START,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAIL,
  GET_ALL_BRANDS_START,
  GET_ALL_BRANDS_SUCCESS,
  GET_ALL_BRANDS_FAIL,
  GET_BRAND_START,
  GET_BRAND_SUCCESS,
  GET_BRAND_FAIL,
  GET_ALL_INFLUENCERS_START,
  GET_ALL_INFLUENCERS_SUCCESS,
  GET_ALL_INFLUENCERS_FAIL,
  GET_INFLUENCER_START,
  GET_INFLUENCER_SUCCESS,
  GET_INFLUENCER_FAIL,
  GET_PENDING_TASKS_START,
  GET_PENDING_TASKS_SUCCESS,
  GET_PENDING_TASKS_FAIL,
  GET_PENDING_PAYOUTS_START,
  GET_PENDING_PAYOUTS_SUCCESS,
  GET_PENDING_PAYOUTS_FAIL,
  GET_COMPLETE_PAYOUTS_START,
  GET_COMPLETE_PAYOUTS_SUCCESS,
  GET_COMPLETE_PAYOUTS_FAIL,
} from './types'

console.log(SERVER_URI, 'this is from search actions')

// const baseUrl = `${SERVER_URI}/api/users/profile`

// Users (for payments)
export const getAllUsers = () => dispatch => {
  dispatch({ type: GET_ALL_USERS_START })
  axiosWithAuth()
    .get(`${SERVER_URI}/api/users/users/type/influencer`)
    .then(res => {
      // console.log(res.data, 'THIS IS RES DATA ON SEARCH ACTIOSN')
      dispatch({ type: GET_ALL_USERS_SUCCESS, payload: res.data })
    })
    .catch(error => {
      dispatch({ type: GET_ALL_USERS_FAIL, payload: error.message })
    })
}


// BRANDS
export const getAllBrands = () => dispatch => {
  dispatch({ type: GET_ALL_BRANDS_START })
  axiosWithAuth()
    .get(`${SERVER_URI}/api/users/profile/type/brand`)
    .then(res => {
      // console.log(res.data, 'THIS IS RES DATA ON SEARCH ACTIOSN')
      dispatch({ type: GET_ALL_BRANDS_SUCCESS, payload: res.data })
    })
    .catch(error => {
      dispatch({ type: GET_ALL_BRANDS_FAIL, payload: error.message })
    })
}

export const getAllBrandsById = id => dispatch => {
  dispatch({ type: GET_BRAND_START, payload: id })
  axiosWithAuth()
    .get(`${SERVER_URI}/api/users/profile/type/brand/${id}`)
    .then(res => {
      // console.log(res.data, 'THIS IS RES DATA ALL PROF BY ID THIS BUG IS A REAL BITCH')
      dispatch({ type: GET_BRAND_SUCCESS, payload: res.data })
    })
    .catch(error => {
      dispatch({ type: GET_BRAND_FAIL, payload: error })
    })
}

// INFLUENCERS
export const getAllInfluencers = () => dispatch => {
  dispatch({ type: GET_ALL_INFLUENCERS_START })
  axiosWithAuth()
    .get(`${SERVER_URI}/api/users/profile/type/influencer`)
    .then(res => {
      dispatch({ type: GET_ALL_INFLUENCERS_SUCCESS, payload: res.data })
    })
    .catch(error => {
      dispatch({ type: GET_ALL_INFLUENCERS_FAIL, payload: error.message })
    })
}

export const getAllInfluencersById = id => dispatch => {
  dispatch({ type: GET_INFLUENCER_START, payload: id })
  axios.get(`${SERVER_URI}/api/users/profile/type/influencer/${id}`)
  .then(res => {
     dispatch({ type: GET_INFLUENCER_SUCCESS, payload: res.data })
  })
  .catch(error => {
     dispatch({ type: GET_INFLUENCER_FAIL, payload: error })
  })
}

// STRIPE //
export const getPendingTasks = (id) => dispatch => {
  dispatch({ type: GET_PENDING_TASKS_START })
  axiosWithAuth()
    .get(`${SERVER_URI}/api/users/influencer/${id}/pending/tasks`)
    .then(res => {
      console.log(res.data.tasks, 'THIS IS RES DATA ON SEARCH ACTIONS')
      dispatch({ type: GET_PENDING_TASKS_SUCCESS, payload: res.data.tasks })
    })
    .catch(error => {
      dispatch({ type: GET_PENDING_TASKS_FAIL, payload: error.message })
    })
}

export const getPendingPayouts = (id) => dispatch => {
  dispatch({ type: GET_PENDING_PAYOUTS_START })
  axiosWithAuth()
    .get(`${SERVER_URI}/api/users/brand/${id}/pending/tasks`)
    .then(res => {
      console.log(res.data.tasks, 'THIS IS RES DATA ON SEARCH ACTIONS')
      dispatch({ type: GET_PENDING_PAYOUTS_SUCCESS, payload: res.data.tasks })
    })
    .catch(error => {
      dispatch({ type: GET_PENDING_PAYOUTS_FAIL, payload: error.message })
    })
}

export const getCompletePayouts = (id) => dispatch => {
  dispatch({ type: GET_COMPLETE_PAYOUTS_START })
  axiosWithAuth()
    .get(`${SERVER_URI}/api/users/brand/${id}/complete/tasks`)
    .then(res => {
      console.log(res.data.tasks, 'THIS IS RES DATA ON SEARCH ACTIONS')
      dispatch({ type: GET_COMPLETE_PAYOUTS_SUCCESS, payload: res.data.tasks })
    })
    .catch(error => {
      dispatch({ type: GET_COMPLETE_PAYOUTS_FAIL, payload: error.message })
    })
}
