import React, { useState } from 'react'
import { SERVER_URI } from 'config'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { Wrapper } from './stripeRegisterStyles'

const StripeRegister = ({ location }) => {
  console.log(location, 'location on stripe register')
  const [ email, setEmail ] = useState('')
  const [ customer, setCustomer ] = useState(null)

  
  const state = useSelector(state => state)
  const currentUserId = state.auth.user.id
  console.log(currentUserId, 'this is id state')
  console.log('### STATE: ', state);
  const userEmail = state.auth.user.email
  

  const handleSubmit = e => {
    e.preventDefault()
    fetch(`${SERVER_URI}/api/stripe/create-customer/`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
        id: currentUserId
      })
    })
      .then((response) => {
        console.log(response, 'this is response handlesubmit')
        return response.json()
      })
      .then((result) => {
        setCustomer(result.customer)
      })
  }

  

  

  

  if (customer) {
    console.log(customer.id, 'this is customer on stripeRegister ONLY ID')
    return (
      <Redirect
        to={{
          pathname: '/stripe-prices',
          state: { customer: customer }
        }}
      />
    )
  } else {
    return (
      <Wrapper>
      <div className='section'>
        <h2>Verify Your Email</h2>
        <form onSubmit={handleSubmit}>
          <input
            className='stripe-login'
            id='email'
            type='text'
            value={userEmail}
            size='30'
            onChange={(e) => setEmail(e.target.value)}
            placeholder='Email address'
            required
          />
          <button type='submit'>Confirm</button>
        </form>
      </div>
      </Wrapper>
    )
  }
}

export default StripeRegister