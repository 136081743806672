import React, { useState } from 'react';
import { Input } from 'antd'
import { Wrapper } from 'auth/forgotstyles'
import { Button } from 'react-bootstrap'
import { UserOutlined } from '@ant-design/icons'
import { forgotPassword } from 'redux/actions/authActions'
import { useDispatch } from 'react-redux';
import { Card, Icon } from 'semantic-ui-react'

const ForgotPassword = (props) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const handleInputChange = (e) => {
    setEmail(e.target.value);
  }



  const handleClick = () => {
    dispatch(forgotPassword({ email: email }));
  }

  return (

    <Wrapper>
            
            <div className='section'>
              
                <div className='Forgot'>
                      <h2>Enter Email</h2>
                      <Input 
                        placeholder='admin@getendorse.com'
                        value={email} 
                        prefix={<UserOutlined />}
                        onChange={handleInputChange}  // Update state on input change
                      />
                       <Button className='confirm-btn' type='submit'onClick={handleClick}> Confirm </Button>
                       <div className="back">
                          <Button className='back-btn' onClick={props.goBack}><Icon name='chevron left'></Icon>Return to Login</Button>
                       </div>
                </div>
            </div>        
        </Wrapper>
  )
}

export default ForgotPassword;
