import styled from 'styled-components'

export const Wrapper = styled.div`
.section {
  height: 100%;
  margin-top: 4.4rem;
  margin-bottom: 80px;

  .toplink {
    display: block;
    margin-top: 7rem;
    margin-left: 2rem;
    width: 160px;

    .ui.button {
      margin-top: 2rem;
      background: linear-gradient(90deg, #Ede0f7 0%, #B993ee 100%);
      color: white;
      text-shadow: 1px 1px 2px #959597;
      font-size: 17px;
      border: none;
      border-radius: .7rem;

      .link {
        color: white;
      }
    }
  }
  .ui.button:hover {
    transform: translateY(-3px);
    box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.15);
  }

  .taskSubmit{
    display: block;
    margin-top: 20px;
    align-items: center;
  }

    .taskSubmit button{
      background: linear-gradient(90deg, #Ede0f7 0%, #B993ee 100%);
      color: white;
      text-shadow: 1px 1px 2px #959597;
      font-size: 17px;
      border: none;
      border-radius: .7rem;
      cursor: pointer;
      width: 100px;
      height: 3rem;
    }
  }
  .taskSubmit button:hover {
    transform: translateY(-3px);
    box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.15);
  }

  .normal-btn {
    color: white;
    background: linear-gradient(90deg, #Ede0f7 0%, #B993ee 100%);

    a {
    }
  }
  .tasksTitle {
    display: grid;
    max-width: 1200px;
    margin: 0 auto;
    padding-top: calc(5% - 10px);
    grid-gap: 1px;
    grid-template-columns: repeat(4, 1fr);
  }
  .brandTask{
    display: flex;
    border-bottom: .25rem solid black;
    align-items: center;
    justify-content: center;
  }
  .tasks {
    display: grid;
    max-width: 1200px;
    margin: 0 auto;
    padding-top: calc(5% - 10px);
    grid-gap: 1px;
    grid-template-columns: repeat(4, 2fr);
  }

`;
