import { createStore, applyMiddleware, compose } from 'redux'
import reducer from 'redux/reducers/index'
// import {reducer} from 'redux/reducers/auth'
import thunk from 'redux-thunk'
import logger from 'redux-logger'
import { loadState } from './localStorage'

const persistedState = loadState()

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(reducer, persistedState, composeEnhancers(applyMiddleware(thunk, logger)))

export default store