import styled from 'styled-components'

export const Wrapper = styled.div`
  .section {
    /* border: 2px dashed green; */
    height: 100%;
    margin-top: 4.4rem;

    .single-brand {
    /* border: 2px dotted blue; */
    display: flex;
    justify-content: center;
    margin-bottom: 20px;


    .ui.card {
      width: 450px;
      padding-bottom: 1rem;
      margin-top: 2rem;

      img {
        margin: 0.5rem;
      }

      .ui.button {
        background-color: #6CB7D9;

        a {
         color: white;

        }
      }
    }
  }
  .icon-links {
    /* border: 2px dotted blue; */
    margin-top: 10px;
    display: flex;
    justify-content: center;
  .link{
    margin-right: 1rem;
  }
}
.toplink {
  /* border: 1px dotted black; */
  display: block;
  margin-top: 7rem;
  margin-left: 2rem;
  width: 160px;

  .ui.button {
    margin-top: 2rem;
    background: linear-gradient(90deg, #Ede0f7 0%, #B993ee 100%);
    color: white;
    text-shadow: 1px 1px 2px #959597;
    font-size: 17px;
    border: none;
    border-radius: .7rem;

    .link {
      color: white;
    }
  }
}
`;
