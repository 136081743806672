import {initializeApp} from 'firebase/app';
import firebase from 'firebase/app';
import {
    getFirestore,
    collection,
    addDoc,
    doc,
    getDocs,
    getDoc,
    setDoc,
} from 'firebase/firestore'
import {
    getAuth,
    signOut,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    onAuthStateChanged
} from 'firebase/auth'
import 'firebase/storage'
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_KEY,
    authDomain: "promoquo-435d3.firebaseapp.com",
    projectId: "promoquo-435d3",
    storageBucket: "promoquo-435d3.appspot.com",
    messagingSenderId: "859319987838",
    appId: "1:859319987838:web:d1c20bb1bceddf677d322f"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore();

export {
    db,
    doc, setDoc, getDoc,
    collection,
    getDocs,
    getFirestore,
    addDoc,
    getAuth,
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    onAuthStateChanged,
    signOut
};
export const auth= getAuth(app)
export default app
