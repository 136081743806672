import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { Icon, Button } from 'semantic-ui-react'
import { useSinglePost } from '../customHooks'
import { readableDate } from '../helpers'
import './singlePost.css'
import { Wrapper } from './buttonStyle'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Helmet from 'react-helmet';



export default function SinglePost() {
  const { id } = useParams()
  const [ post, isLoading ] = useSinglePost(id)

  const renderPost = () => {
    if (isLoading) return <h3>Loading...</h3>


    return (
      <React.Fragment>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{post.title}</title>
                <meta name='description' content={post.metaDescription}/>
                <link rel="canonical" to={'blog/' + post.slug} />
            </Helmet>
        <div className="post__intro">
        <img
            className="post__intro__img"
            src={post.featuredImage.fields.file.url}
            alt={post.title}
          />
          <h2 className="post__intro__title">{post.title}</h2>
          <small className="post__intro__date">{readableDate(post.date)}</small>

          <div className="post__intro__desc">{documentToReactComponents(post.description)}</div>


        </div>
      </React.Fragment>
    )
  }

  return (
    <Wrapper>
    <div className='post' style={{marginTop: '5rem'}}>
      <div className='blog-links'>
        <div className='back-btn'>
        <Link className='link' to='/'>
          <Button className='top-btn'>
            Home
          </Button>
        </Link>
        </div>

        <div class="divider"/>

        <div className='back-btn'>
        <Link className='link' to='/blog'>
          <Button className='top-btn'>
          <Icon name='chevron left' />
            Blog
          </Button>
        </Link>
        </div>
      </div>
      {renderPost()}
    </div>
    </Wrapper>
  )
}
