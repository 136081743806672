import styled from 'styled-components'

export const Wrapper = styled.div`

.wrapper {
    height: 100%;
}

.NewUserIntro {
}

`