import React, { useEffect, useState, Dropdown } from 'react';
import { DropdownButton } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux'
import { getAllInfluencers } from 'redux/actions/searchActions'
import { Link } from 'react-router-dom'
import { Card, Icon, Button } from 'semantic-ui-react'
import { Wrapper } from 'components/Dashboard/InfluencerSearch/InfluencerSearchStyles'


const AllInfluencers = () => {
  const dispatch = useDispatch()
  const state = useSelector((state) => state)
  const influencers = state.search.influencers.data
  const setInfluencers = useState();
  const [influencer, setInfluencer] = useState();

  console.log(state, 'this is state from inf search');

  useEffect(() => {
    dispatch(getAllInfluencers())
  }, [dispatch])

    useEffect(() => {
   if(influencers !== undefined)
   if(influencers.length > 0) setInfluencer(influencers[0].id);

  } , [])
  

        const [error, setError] = useState(null);
        const [isLoaded, setIsLoaded] = useState(false);

        //     set search query to empty string
        const [q, setQ] = useState("");
        //     set search parameters
        //     we only what to search countries by capital and name
        //     this list can be longer if you want
        //     you can search countries even by their population
        // just add it to this array
        const [searchParam] = useState(["username"]);


                function search(influencers) {
                return (influencers || []).filter((influencer) => {
                    return searchParam.some((newInfluencer) => {
                        return (
                            influencer[newInfluencer]
                                .toString()
                                .toLowerCase()
                                .indexOf(q.toLowerCase()) > -1
                        );
                    });
                });
            }



  return (
    <Wrapper>
      <div className='search-section'>
        <div className='toplink'>
          <a href='/dashboard'>
            <Button>
            <div className="link">
            <Icon name='chevron left'></Icon>
            Dashboard</div>
            </Button>
            </a>

         </div>
      <h1>Find Creators to work with!</h1>
      <div className="search-wrapper">
                        <label htmlFor="search-form">
                            <input
                                type="search"
                                name="search-form"
                                id="search-form"
                                className="search-input"
                                placeholder="Search by Username"
                                value={q}
                                /*
                                // set the value of our useState q
                                //  anytime the user types in the search box
                                */
                                onChange={(e) => setQ(e.target.value)}
                            />
                        </label>
                    </div>
      
        <div className='influencer-list'>
          {search(influencers).map((influencer, i) => (
              <div className='influencer' key={i} influencer={influencer}>
                <Card>
                  <Card.Content>
                  <img style={{ width: '120px', borderRadius: '50%' }} src={influencer.image} alt='avatar' />
                  <h2>{influencer.username}</h2>
                    <Link to={`/influencer/user/${influencer.id}`}>
                      <Button>View Profile</Button>
                    </Link>
                  </Card.Content>
                </Card>
              </div>
              ))
          }
        </div>
      </div>
    </Wrapper>
  )
}

export default AllInfluencers
