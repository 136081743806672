import React, { useState } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import {
  CardElement,
  Elements,
  useStripe,
  useElements
} from '@stripe/react-stripe-js'
import { Redirect } from 'react-router-dom'
import {SERVER_URI} from 'config'
import { getUserById } from 'redux/actions/userActions';
//import { Wrapper } from 'stripe/PaymentFormStyles'
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

const CheckoutForm = ({ productSelected, customer, userID }) => {
  const stripe = useStripe()
  const elements = useElements()
  const [ subscribing, setSubscribing ] = useState(false)
  const [ accountInformation, setAccountInformation ] = useState(null)
  const [ errorToDisplay, setErrorToDisplay ] = useState('')

  // const stripePriceID = productSelected.priceId

  console.log(customer, 'THIS IS THE CUSTOMER FROM PRICES')
  console.log(productSelected, 'THIS IS PRODUCT SELECTED FROM PRICES')

  const handlePaymentThatRequiresCustomerAction = ({
    subscription,
    invoice,
    priceId,
    paymentMethodId,
    isRetry
  }) => {
    if (subscription && subscription.status === 'active') {
      return { subscription, priceId, paymentMethodId }
    }
    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    let paymentIntent = invoice
      ? invoice.payment_intent
      : subscription.latest_invoice.payment_intent

    if (
      paymentIntent.status === 'requires_action' ||
      (isRetry === true && paymentIntent.status === 'requires_payment_method')
    ) {
      return stripe.confirmCardPayment(paymentIntent.client_secret, {
        payment_method: paymentMethodId,
      })
      .then((result) => {
        if (result.error) {
          // start code flow to handle updating the payment details
          // Display error message in your UI.
          // The card was declined (i.e. insufficient funds, card has expired, etc)
          throw result
        } else {
          if (result.paymentIntent.status === 'succeeded') {
            // There's a risk of the customer closing the window before callback
            // execution. To handle this case, set up a webhook endpoint and
            // listen to invoice.payment_succeeded. This webhook endpoint
            // returns an Invoice.
            return {
              priceId: priceId,
              subscription: subscription,
              invoice: invoice,
              paymentMethodId: paymentMethodId
            }
          }
        }
      })
    } else {
      return { subscription, priceId, paymentMethodId }
    }
  }

  const handleRequiresPaymentMethod = ({
    subscription,
    paymentMethodId,
    priceId,
  }) => {
    if (subscription.status === 'active') {
      return { subscription, priceId, paymentMethodId }
    } else if (
      subscription.latest_invoice.payment_intent.status === 'requires_payment_method'
    ) {
      // Using localStorage to store the state of the retry here
      // (feel free to replace with what you prefer)
      // Store the latest invoice ID and status
      localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id)
      localStorage.setItem(
        'latestInvoicePaymentIntentStatus',
        subscription.latest_invoice.payment_intent.status
      )
      throw new Error('Your card was declined.')
    } else {
      return { subscription, priceId, paymentMethodId }
    }
  }

  const retryInvoiceWithNewPaymentMethod = ({ paymentMethodId, invoiceId }) => {
    const priceId = productSelected.name.toUpperCase()
    return (
      fetch(`${SERVER_URI}/api/stripe/retry-invoice`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          customerId: customer.id,
          paymentMethodId: paymentMethodId,
          invoiceId: invoiceId,
        })
      })
        .then((response) => {
          return response.json()
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            throw result
          }
          return result
        })
        .then((result) => {
          return {
            invoice: result,
            paymentMethodId: paymentMethodId,
            priceId: priceId,
            isRetry: true
          }
        })
        .then(handlePaymentThatRequiresCustomerAction)
        .then(onSubscriptionComplete)
        .catch((error) => {
          console.log(error)
          setSubscribing(false)
          setErrorToDisplay(error && error.error && error.error.decline_code)
        })
    )
  }

  const onSubscriptionComplete = (result) => {
    console.log(result)
    // Payment was successful. Provision access to your service.
    // Remove invoice from localstorage because payment is now complete.
    // clearCache();
    if (result && !result.subscription) {
      const subscription = { id: result.invoice.subscription }
      result.subscription = subscription
      localStorage.clear()
    }

    setAccountInformation(result)
    console.log(result, "THIS IS THE RESULT FROM PAYMENT FORM")
    // Change your UI to show a success message to your customer.
    // onSubscriptionSampleDemoComplete(result);
    // Call your backend to grant access to your service based on
    // the product your customer subscribed to.
    // Get the product by using result.subscription.price.product
  }

  const createSubscription = ({ paymentMethodId, customerId }) => {
    console.log(customer, 'THIS IS CUSTOMER 155')
    const priceId = productSelected.priceId
    console.log(productSelected, 'THis is product selected')

    console.log(customer, paymentMethodId, priceId, 'THIS IS CUSTOMER');
    console.log(userID, productSelected.name, 'This is user id and subscription type')
    return (
      fetch(`${SERVER_URI}/api/stripe/create-subscription`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          customerId: customer,
          paymentMethodId: paymentMethodId,
          priceId: priceId,
          subscriptionType: productSelected.name,
          userId: userID

        })
      })
        .then((response) => {
          console.log(response, 'THIS IS PAYMENT FORM RESPONSE')
          return response.json()
        })
        // If the card is declined, display an error to the user.
        .then((result) => {
          if (result.error) {
            console.log(result, 'THIS IS ERROR RESULT')
            throw result
          }
          return result
        })
        .then((result) => {
          return {
            subscription: result,
            paymentMethodId: paymentMethodId,
            priceId: productSelected.name
          }
        })
        .then(handlePaymentThatRequiresCustomerAction)
        .then(handleRequiresPaymentMethod)
        .then(onSubscriptionComplete)
        .catch((error) => {
          setSubscribing(false)
          setErrorToDisplay(error.message || error.error.decline_code)
        })
    )
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setSubscribing(true)
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    const cardElement = elements.getElement(CardElement)
    const latestInvoicePaymentIntentStatus = localStorage.getItem('latestInvoicePaymentIntentStatus')
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    })

    if (error) {
      console.log('[createPaymentMethod error]', error);
      setSubscribing(false)
      setErrorToDisplay(error && error.message)
      return
    }
    console.log('[PaymentMethod]', paymentMethod)
    const paymentMethodId = paymentMethod.id
    if (latestInvoicePaymentIntentStatus === 'requires_payment_method') {
      const invoiceId = localStorage.getItem('latestInvoiceId')
      retryInvoiceWithNewPaymentMethod({
        paymentMethodId: paymentMethodId,
        invoiceId: invoiceId,
      })
      return
    }

    createSubscription({
      paymentMethodId: paymentMethodId
    })
  }

  if (accountInformation) {
    return (
      <Redirect
        to={{
          pathname: '/stripe-account',
          state: { accountInformation: accountInformation }
        }}
      />
    )
  } else {
    return (
        <div>
        <div>
          Enter Card Details <br />
          Your subscription will start now
          <p>
            → Total due now <span>{productSelected.price}</span>
          </p>
          <p className="text-gray-700 text-base mb-4">
            → Subscribing to{' '}
            <span className="font-bold">{productSelected.name}</span>
          </p>
        </div>

        <div>
          <label>Cardholder Name</label>
          <input
            id="name"
            type="text"
            placeholder=""
            required
          />
        </div>

        <div className="PaymentForm"style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <form onSubmit={handleSubmit}  >
            <label>Card</label>
            <div>
              <CardElement
                options={{
                  style: {
                    base: {
                      fontSize: '16px',
                      color: '#32325d',
                      fontFamily:
                        '-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, sans-serif',
                      '::placeholder': {
                        color: '#a0aec0',
                      },
                    },
                    invalid: {
                      color: '#9e2146',
                    },
                  },
                }}
              />
              <div>
                {errorToDisplay ? errorToDisplay : null}
              </div>
            </div>
            <button
              id='submit-premium'
              type='submit'
            >
              <div className="">
                <div>{subscribing ? 'Subscribing...' : 'Subscribe'}</div>
              </div>
            </button>
          </form>
          </div>
      </div>
    )
  }
}

const PaymentForm = (props) => (
  <Elements stripe={stripePromise}>
    <CheckoutForm { ...props } />
  </Elements>
)

export default PaymentForm
