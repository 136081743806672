import React, { useEffect, useState } from 'react'
import { Wrapper } from './PayoutPortalStyles'
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux'
import { Button, Icon } from 'semantic-ui-react'
import { SERVER_URI } from 'config'
import axios from 'axios';
import { getAllBrands, getCompletePayouts } from 'redux/actions/searchActions'
import { async } from '@firebase/util';
const stripe = require('stripe')(process.env.REACT_APP_STRIPE_SECRET_KEY);
const baseUrl = `${SERVER_URI}/api/stripe`;

const PayoutPortal = () => {
    const dispatch = useDispatch()
    const state = useSelector((state) => state)
    let tasks = state.search.tasks
    //let payouts = state.search.payouts
    const userID = state.auth.user.id
    const stripeCustomer = localStorage.getItem('stripeID')
    const [link, setLink] = useState();

    const [filter, setFilter] = useState('all');

    useEffect(() => {
      dispatch(getCompletePayouts(userID))
  
    } , [])

    const filteredTasks = tasks.filter(task => {
      if (filter === 'complete') return task.completed === true;
      if (filter === 'incomplete') return task.completed === false;
      return true; // for 'all', no filtering is applied
    });
    


    return (

        <Wrapper>
        <section className='section'>
        <div className='toplink'>
          <a href='/dashboard'>
            <Button>
            <div className="link">
            <Icon name='chevron left'></Icon>
            Dashboard</div>
            </Button>
            </a>
    
        </div>

        
    
        <h2> Promotions</h2>

        <div className='taskBtns'>
       <Button className= 'taskFilter'onClick={() => setFilter('all')}>All</Button>
       <Button className= 'taskFilter'  onClick={() => setFilter('complete')}>Complete</Button>
       <Button className= 'taskFilter' onClick={() => setFilter('incomplete')}>Incomplete</Button>
        </div>

        <div className='tasksTitle'>


    
          <div className='brandTask'>
          Creator
          </div>
    
          <div className='brandTask'>
          Date
          </div>
    
          <div className='brandTask'>
          Amount
          </div>
    
    
        </div>
    
        { filteredTasks.length > 0 ? (filteredTasks.map((task, index) => {
          return (
    
          <div className='tasks'  key={index} >
    
          <div className='taskInfo'>
           {task.influencerUsername}
          </div>
    
          <div className='taskInfo'>
          {task.date}
          </div>
    
          <div className='taskInfo'>
          $ {task.amount / 100}
          </div>

          </div>
          )
    }
    )) : (
      <div>
        <h1>No Tasks</h1>
      </div>
    )}


</section>
    </Wrapper>

  )
}


export default PayoutPortal;
