import React from 'react'
import { Wrapper } from './TermsStyles'

const Terms = () => {
  return (
    <Wrapper>
      <section className='section'>
        <h1>Endorse Terms of Use</h1>
        <h2>1. Introduction</h2>
        <h3>1.1 Contract</h3>
        <div className='main-container'>
        <div className='text'>
          <p>When you use our Services you agree to all of these terms. Your use of our Services is also subject to our privacy policy which covers how we collect, use, share, and store your personal information.
          You agree that by clicking “Join Now”, “Join Endorse”, “Sign Up” or similar, registering, accessing or using our services (described below), you are agreeing to enter into a legally binding contract with Endorse (even if you are using our Services on behalf of a company). If you do not agree to this contract (“Contract” or “User Agreement”), do not click “Join Now” (or similar) and do not access or otherwise use any of our Services. If you wish to terminate this contract, at any time you can do so by closing your account and no longer accessing or using our Services.</p>
        </div>

        <h3>Services</h3>
        <div className='text'>
        <p>This Contract applies to getendorse.com, promoquo.com, Endorse-branded apps, Endorse Learning and other Endorse-related sites, apps, communications and other services that state that they are offered under this Contract (“Services”), including the offsite collection of data for those Services, such as our ads and the “Apply with Endorse” and “Share with Endorse” plugins. Registered users of our Services are “Members” and unregistered users are “Visitors”.</p>
        </div>

        <h3>1.2 Change</h3>
        <div className='text'>
        <p>We may make changes to the Contract. We may modify this Contract, our Privacy Policy and our Cookies Policy from time to time. If we make material changes to it, we will provide you notice through our Services, or by other means, to provide you the opportunity to review the changes before they become effective. We agree that changes cannot be retroactive. </p>
        </div>
        <h2>2. Obligations</h2>

        <h3>2.1 Service Eligibility</h3>
        <div className='text'>
        <p>To use the Services, you agree that: (1) Your account(s) as an Influencer or Brand are not an impersonation or false representation of the legitimate influencer or brand (2) You have not already been suspended from using our services on Endorse. Creating an account with false information is a violation of our terms. </p>
        </div>

        <h3>2.2 Your Account</h3>
        <div className='text'>
        <p>Members are account holders. You agree to: (1) use a strong password and keep it confidential; (2) not transfer any part of your account (e.g., connections) and (3) follow the law. You are responsible for anything that happens through your account unless you close it or report misuse.
        As between you and others, your account belongs to you. However, if the Services were purchased by another party for you to use, the party paying for such Service has the right to control access to and get reports on your use of such paid Service; however, they do not have rights to your personal account.</p>
        </div>

        <h3>2.3 Payment</h3>
        <div className='text'>
        <p>If you buy any of our paid Services (“Premium Services”), you agree to pay us the applicable fees and taxes specific to the paid Services. Failure to pay these fees will result in the termination of your paid Services. Also, you agree that:
        Your purchase may be subject to foreign exchange fees or differences in prices based on location (e.g. exchange rates).
        We may store and continue billing your payment method (e.g. credit card) even after it has expired, to avoid interruptions in your Services and to use to pay other Services you may buy.
        If you purchase a subscription, your payment method automatically will be charged at the start of each subscription period for the fees and taxes applicable to that period. To avoid future charges, cancel before the renewal date.</p>
        </div>

        <h3>2.4 Notices and Messages</h3>
        <div className='text'>
        <p>You’re okay with us providing notices and messages to you through our websites, apps, and contact information. If your contact information is out of date, you may miss out on important notices.
        You agree that we will provide notices and messages to you in the following ways: (1) within the Service, or (2) sent to the contact information you provided us (e.g., email, mobile number, physical address). You agree to keep your contact information up to date.
        
        Our Services allow messaging and sharing of information in many ways, such as your profile, articles, group posts, links to news articles, job postings, and messages. Information and content that you share or post may be seen by other users. For searching activities, we default to not notifying your connections network or the public.
         
        We are not obligated to publish any information or content on our Service and can remove it with or without notice.</p>
        </div>

        <h3>2.5 Sharing</h3>
        <div className='text'>
        <p>Our Services allow messaging and sharing of information in many ways, such as your profile, pictures, social accounts, posts, articles, job postings, messages, and contracts. Information and content that you share or post may be seen by other users.
 
        We are not obligated to publish any information or content on our Service and can remove it with or without notice.
        </p>
        </div>

        <h2>3. Rights and Limits</h2>
        <h3>3.1. Your License to Endorse</h3>
        <div className='text'>
        <p>As between you and Endorse, you own the content and information that you submit or post to the Services, and you are only granting Endorse and our affiliates the following non-exclusive license:
        A worldwide, transferable and sublicensable right to use, copy, modify, distribute, publish and process, information and content that you provide through our Services and the services of others, without any further consent, notice and/or compensation to you or others. These rights are limited in the following ways:
        You can end this license for specific content by deleting such content from the Services, or generally by closing your account, except (a) to the extent you shared it with others as part of the Service and they copied, re-shared it or stored it and (b) for the reasonable time it takes to remove from backup and other systems.
        We will not include your content in advertisements for the products and services of third parties to others without your separate consent (including sponsored content). However, we have the right, without payment to you or others, to serve ads near your content and information, and your social actions may be visible and included with ads, as noted in the Privacy Policy. If you use a Service feature, we may mention that with your name or photo to promote that feature within our Services, subject to your settings.
        Your consent is necessary if we want to give others the right to publish your content beyond the Services, or if others wish to publish your content through third party services. 
        While we may edit and make format changes to your content (such as translating or transcribing it, modifying the size, layout or file type or removing metadata), we will not modify the meaning of your expression.
        Because you own your content and information and we only have non-exclusive rights to it, you may choose to make it available to others. 
         
        You and Endorse agree that we may access, store, process and use any information and personal data that you provide in accordance with, the terms of the privacy policy and your choices (including settings).
        By submitting suggestions or other feedback regarding our Services to Endorse, you agree that Endorse can use and share (but does not have to) such feedback for any purpose without compensation to you.
        You agree to only provide content or information that does not violate the law nor anyone’s rights (including intellectual property rights). You also agree that your profile information will be truthful. Endorse may be required by law to remove certain information or content in certain countries.
        </p>
        </div>

        <h3>3.2 Service Availability</h3>
        <div className='text'>
        <p>We may change, suspend or discontinue any of our Services. We may also modify our prices effective prospectively upon reasonable notice to the extent allowed under the law.
        We don’t promise to store or keep showing any information and content that you’ve posted. Endorse is not a storage service. You agree that we have no obligation to store, maintain or provide you a copy of any content or information that you or others provide, except to the extent required by applicable law and as noted in our Privacy Policy.</p>
        </div>

        <h3>3.3 Other Content, Sites and Apps</h3>
        <div className='text'>
        <p>By using the Services, you may encounter content or information that might be inaccurate, incomplete, delayed, misleading, illegal, offensive or otherwise harmful. Endorse generally does not review content provided by our Members or others. You agree that we are not responsible for others’ (including other Members’) content or information. We cannot always prevent this misuse of our Services, and you agree that we are not responsible for any such misuse. </p>
        </div>

        <h3>3.4 Intellectual Property Rights</h3>
        <div className='text'>
        <p>Endorse reserves all of its intellectual property rights in the Services. Trademarks and logos used in connection with the Services are the trademarks of their respective owners. Endorse, and “PQ” logos and other Endorse trademarks, service marks, graphics and logos used for our Services are trademarks or registered trademarks of Endorse.</p>
        </div>

        <h3>3.5 Automated Processing</h3>
        <div className='text'>
        <p>We use the information and data that you provide and that we have about Members to make recommendations for connections, content and features that may be useful to you. For example, we use data and information about you to recommend jobs to you and you to recruiters. Keeping your profile accurate and up to date helps us to make these recommendations more accurate and relevant.</p>
        </div>

        <h2>4. Disclaimer and Limit of Liability</h2>
        
        
        <h3>4.1 No Warranty</h3>
        <div className='text'>
        <p>Endorse AND ITS AFFILIATES MAKE NO REPRESENTATION OR WARRANTY ABOUT THE SERVICES, INCLUDING ANY REPRESENTATION THAT THE SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, AND PROVIDE THE SERVICES (INCLUDING CONTENT AND INFORMATION) ON AN “AS IS” AND “AS AVAILABLE” BASIS. TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, Endorse AND ITS AFFILIATES DISCLAIM ANY IMPLIED OR STATUTORY WARRANTY, INCLUDING ANY IMPLIED WARRANTY OF TITLE, ACCURACY OF DATA, NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.</p>
        </div>

        <h3>4.2 Exclusion of Liability</h3>
        <div className='text'>
        <p>TO THE FULLEST EXTENT PERMITTED BY LAW (AND UNLESS Endorse HAS ENTERED INTO A SEPARATE WRITTEN AGREEMENT THAT OVERRIDES THIS CONTRACT), Endorse, INCLUDING ITS AFFILIATES, WILL NOT BE LIABLE IN CONNECTION WITH THIS CONTRACT FOR LOST PROFITS OR LOST BUSINESS OPPORTUNITIES, REPUTATION (E.G., OFFENSIVE OR DEFAMATORY STATEMENTS), LOSS OF DATA (E.G., DOWN TIME OR LOSS, USE OF, OR CHANGES TO, YOUR INFORMATION OR CONTENT) OR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL OR PUNITIVE DAMAGES.
        Endorse AND ITS AFFILIATES WILL NOT BE LIABLE TO YOU IN CONNECTION WITH THIS CONTRACT FOR ANY AMOUNT THAT EXCEEDS (A) THE TOTAL FEES PAID OR PAYABLE BY YOU TO Endorse FOR THE SERVICES DURING THE TERM OF THIS CONTRACT, IF ANY, OR (B) US $1000.</p>
        </div>

        <h3>4.3 Basis of the Bargain; Exclusions</h3>
        <div className='text'>
        <p>The limitations of liability in this Section 4 are part of the basis of the bargain between you and Endorse and shall apply to all claims of liability (e.g., warranty, tort, negligence, contract and law) even if Endorse or its affiliates has been told of the possibility of any such damage, and even if these remedies fail their essential purpose.
        These limitations of liability do not apply to liability for death or personal injury or for fraud, gross negligence or intentional misconduct, or in cases of negligence where a material obligation has been breached, a material obligation being such which forms a prerequisite to our delivery of services and on which you may reasonably rely, but only to the extent that the damages were directly caused by the breach and were foreseeable upon conclusion of this Contract and to the extent that they are typical in the context of this Contract.
         </p>
        </div>

        <h2>5. Termination</h2>
        <div className='text'>
        <p>Both you and Endorse may terminate this Contract at any time with notice to the other. On termination, you lose the right to access or use the Services. The following shall survive termination: 
        Our rights to use and disclose your feedback
        Any amounts owed by either party prior to termination remain owed after termination.</p>
        </div>

        <h2>6. Governing Law and Dispute Resolution</h2>
        <div className='text'>
        <p>For others outside of Designated Countries, including those who live outside of the United States: You and Endorse agree that the laws of the State of California, U.S.A., excluding its conflict of laws rules, shall exclusively govern any dispute relating to this Contract and/or the Services.</p>
        </div>

        <h2>General Terms</h2>
        <div className='text'>
          <p>
          If a court with authority over this Contract finds any part of it unenforceable, you and we agree that the court should modify the terms to make that part enforceable while still achieving its intent. If the court cannot do that, you and we agree to ask the court to remove that unenforceable part and still enforce the rest of this Contract.
          This Contract (including additional terms that may be provided by us when you engage with a feature of the Services) is the only agreement between us regarding the Services and supersedes all prior agreements for the Services.
          If we don't act to enforce a breach of this Contract, that does not mean that Endorse has waived its right to enforce this Contract. You may not assign or transfer this Contract (or your membership or use of Services) to anyone without our consent. However, you agree that Endorse may assign this Contract to its affiliates or a party that buys it without your consent. There are no third-party beneficiaries to this Contract.
          </p>
        </div>

        <h2>8. Endorse “Dos and Dont's”</h2>

        <h3>8.1. Dos</h3>
        <div className='text'>
          <p>You agree that you will:
          Comply with all applicable laws, including, without limitation, privacy laws, intellectual property laws, anti-spam laws, export control laws, tax laws, and regulatory requirements;
          Provide accurate information to us and keep it updated;
          Use your real name on your profile; and
          Use the Services in a professional manner.</p>
          </div>

        <h3>8.2. Don’ts</h3>
        <div className='text'>
          <p>You agree that you will not:
          Create a false identity on Endorse, misrepresent your identity, create a Member profile for anyone other than yourself (a real person), or use or attempt to use another’s account;
          Develop, support or use software, devices, scripts, robots or any other means or processes (including crawlers, browser plugins and add-ons or any other technology) to scrape the Services or otherwise copy profiles and other data from the Services;
          Override any security feature or bypass or circumvent any access controls or use limits of the Service (such as caps on keyword searches or profile views);
          Copy, use, disclose or distribute any information obtained from the Services, whether directly or through third parties (such as search engines), without the consent of Endorse;
          Disclose information that you do not have the consent to disclose (such as confidential information of others (including your employer));
          Violate the intellectual property rights of others, including copyrights, patents, trademarks, trade secrets or other proprietary rights. For example, do not copy or distribute (except through the available sharing functionality) the posts or other content of others without their permission, which they may give by posting under a Creative Commons license;
          Violate the intellectual property or other rights of Endorse, including, without limitation, (i) copying or distributing our learning videos or other materials or (ii) copying or distributing our technology, unless it is released under open source licenses; (iii) using the word “Endorse” or our logos in any business name, email, or URL.
          Post anything that contains software viruses, worms, or any other harmful code;
          Reverse engineer, decompile, disassemble, decipher or otherwise attempt to derive the source code for the Services or any related technology that is not open source;
          Imply or state that you are affiliated with or endorsed by Endorse without our express consent (e.g., representing yourself as an accredited Endorse trainer);
          Rent, lease, loan, trade, sell/re-sell or otherwise monetize the Services or related data or access to the same, without Endorse’s consent;
          Deep-link to our Services for any purpose other than to promote your profile or a Group on our Services, without Endorse’s consent;
          Use bots or other automated methods to access the Services, add or download contacts, send or redirect messages;
          Monitor the Services’ availability, performance or functionality for any competitive purpose;
          Engage in “framing,” “mirroring,” or otherwise simulating the appearance or function of the Services;
          Overlay or otherwise modify the Services or their appearance (such as by inserting elements into the Services or removing, covering, or obscuring an advertisement included on the Services);
          Interfere with the operation of, or place an unreasonable load on, the Services (e.g., spam, denial of service attack, viruses, gaming algorithms).</p>
        </div>

      </div>
      </section>
    </Wrapper>
  )
}

export default Terms
