import {
  GET_USER_DATA_START,
  GET_USER_DATA_SUCCESS,
  GET_USER_DATA_FAIL,
  GET_SPECIFIC_USER_START,
  GET_SPECIFIC_USER_SUCCESS,
  GET_SPECIFIC_USER_FAIL,
  EDIT_USER_START,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAIL,
  EDIT_USER_SUBSCRIPTION_START,
  EDIT_USER_SUBSCRIPTION_SUCCESS,
  EDIT_USER_SUBSCRIPTION_FAIL
} from '../actions/types'

const initialState = {
  users: [],
  person: {},
  isLoading: false,
  error: ''
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_DATA_START:
      return { ...state, isLoading: true }
    case GET_USER_DATA_SUCCESS:
      return { ...state, isLoading: false, users: action.payload }
    case GET_USER_DATA_FAIL:
      return { ...state, isLoading: false, error: action.payload }
    case GET_SPECIFIC_USER_START:
      return { ...state, isLoading: true }
    case GET_SPECIFIC_USER_SUCCESS:
      return { ...state, isLoading: false, person: action.payload }
    case GET_SPECIFIC_USER_FAIL:
      return { ...state, isLoading: false, error: action.payload }
    case EDIT_USER_START:
      return { ...state, isLoading: true }
    case EDIT_USER_SUCCESS:
      return { ...state, isLoading: false, person: action.payload }
    case EDIT_USER_FAIL:
      return { ...state, isLoading: false, error: action.payload }
    case EDIT_USER_SUBSCRIPTION_START:
      return { ...state, isLoading: true }
    case EDIT_USER_SUBSCRIPTION_SUCCESS:
      return { ...state, isLoading: false }
    case EDIT_USER_SUBSCRIPTION_FAIL:
      return { ...state, isLoading: false, error: action.payload }
    default: 
      return state
  }
}