import React from 'react';
import { Link, useHistory } from 'react-router-dom'
import { Wrapper } from './HomeStyles.js'
import 'react-slideshow-image/dist/styles.css'
import { useLastLocation } from 'react-router-last-location'
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import pqCollage from 'assets/images/pq-homepage-collage.png'
import howItWorks from 'assets/images/how-it-works.png'
import howItWorksMobile from 'assets/images/how-it-works-mobile.png'
import video from 'assets/images/video.jpg'
import messaging from 'assets/images/messaging.png'
import campaign from 'assets/images/campaign.png'
import usersearch from 'assets/images/usersearch.png'
import payment from 'assets/images/payment.png'
import influencingEasy from 'assets/images/influencing-easy.png'
import influencingEasyMobile from 'assets/images/influencing-easy-mobile.png'
import captureAudience from 'assets/images/capture-audience.png'
import unmatched from 'assets/images/unmatched-engagement.png'
import unmatchedMobile from 'assets/images/unmatched-engagement-mobile.png'
import Helmet from 'react-helmet';

import './HomeStyles.js'
import './HomeStyles.css'

 const Home = () => {
  const state = useSelector(state => state)

  console.log(state, 'this is home state');

  return (

    
   <Wrapper>

    <Helmet>
    <meta charSet="utf-8" />
    <title>Endorse</title>
    <meta name='description' content='Creator Marketing Made easy, through Endorse Brands create promotions for Creators to complete and get paid. Join Today.'/>
    <link rel="canonical" to='https://getendorse.com' />
    </Helmet>
   
   <div className='homeBackground'></div>

    <main className='website-wrapper'>
      <section className='hero-section'>
        <div className='container'>
          <div className="row align-items-center text-center text-lg-left">
           <div className="col-lg-6 col-xl-5 py-5">
            <div className="status-quo">
            <h1><strong>Social Promotions</strong></h1>
            <h1>Simplified</h1>

            <div className="status-quo-btn">
              <Link to="/register" className="btn-prime">Sign Up</Link>
            </div>
            
           </div>
          </div>
          <div className="collage">
           <div className="col-lg-6 col-xl-7">   
            <div className='img-wrap'>
              <img src={pqCollage} alt='man-surfing' />
            </div>
          </div>
        </div>
       </div>
      </div>
     </section>

      <section className='howitworks'>
          <div className='container-fluid'>
            <div className='row align-items-center'>
              <div className="col-lg-5">
                <div className= 'img-texting'>
                  <img src={howItWorks} className='d-none d-lg-block' alt='how-it-works' />
                </div>
              </div>
              <div className='col-lg-7 text-center text-md-left text-col'>
               
               <h2 className='heading-style clr-prime'>
               <div className= 'HowItWorksTitle'>                  
                  How It Works
                  </div>
               </h2>
              
                <div className= "HowItWorksText">
                  <div className="py-1 d-md-block d-none"></div>
                    <p>Through Endorse Brands create promotions for Creators to complete and get paid. Endorse is designed to be the best Creator Marketing Platform available making Social Media Promotions easier than ever. Connect, compensate, and complete endorsements all from one place.</p>
                </div>
              <div className= "JoinButton">
                <Link to="/register" className="btn-prime">Join Today</Link>
              </div>
              </div>
            </div>
            <div className= "img-texting-mobile" >
              <img src={howItWorksMobile} className='d-none d-lg-block'/>
            </div>
          </div>
      </section>

      <section className="campaigns-section">
        <div className="container">
          <div className="row align-items-center">

            <div className="col-md-6 text-center text-md-left">
              <h2 className="heading-style text-light">
               <div className="CampaignTitle">
               Start Campaigns Instantly
               </div>
              </h2>
                  <div className="py-1 d-md-block d-none"></div>
                   <div className="CampaignsText">
                    <p>Whether Creator marketing is familiar to you or not, Endorse removes the hassle from the entire process. Connecting, communicating, and completing promotions can all be done from one location. Its as easy as these steps:</p>
                   </div>
                   <div className="py-1 d-md-block d-none"></div>
              <ul className="list-ticked">

                <li>
                <span id="checkmark"style={{marginRight: '5px'}}></span>
                Make a Free Profile
                </li>

                <li>
                <span id="checkmark"style={{marginRight: '5px'}}></span>
                Search For Users
                </li>

                <li>
                <span id="checkmark"style={{marginRight: '5px'}}></span>
                Create Promotion Terms
                </li>

                <li>
                <span id="checkmark"style={{marginRight: '5px'}}></span>
                Complete Agreements
                </li>
              </ul>
            </div>

          </div>
        </div>
      </section>

      <section className="features-section">
        <div className="container">
          <div className="row justify-content-center">
            
            <div className="col-md-12">
              <h2 className="heading-style text-center clr-prime pb-4">
               <div className= "features-title">
                Features
               </div>
              </h2>
            </div>

            <div className="flex-grid">
             <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3 text-center mb-4 text-xl-left">
               <div className="feature-box">
                 <div className='feature-box-icon'>
                   <img src={messaging} alt='feature-box-icon'/>
                 </div>
                 <div className="feature-box-content">
                   <h4 className="feature-box-title">Easy, In-App Messaging</h4>
                   <p className="feature-box-inner">Direct message Creators or Brands through our secure in-app messaging. Built to be as easy as using your phone.</p>
                 </div>
               </div>
             </div>

             <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3 text-center mb-4 text-xl-left">
               <div className="feature-box">
                 <div className="feature-box-icon">
                   <img src={campaign} alt='feature-box-icon' />
                 </div>
                 <div className="feature-box-content">
                   <h4 className="feature-box-title">Campaign Manager</h4>
                   <p className="feature-box-inner">No more disorganization, manage all campaigns from one place.</p>
                 </div>
               </div>
             </div>

             <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3 text-center mb-4 text-xl-left">
               <div className="feature-box">
                 <div className="feature-box-icon">
                   <img src={usersearch} alt='feature-box-icon' />
                 </div>
                 <div className="feature-box-content">
                   <h4 className="feature-box-title">User Search</h4>
                   <p className="feature-box-inner">Discover and Connect with Creators or Brands through User Search.</p>
                 </div>
               </div>
             </div>

             <div className="col-sm-6 col-md-4 col-lg-4 col-xl-3 text-center mb-4 text-xl-left">
               <div className="feature-box">
                 <div className="feature-box-icon">
                   <img src={payment} alt='feature-box-icon' />
                 </div>
                 <div className="feature-box-content">
                   <h4 className="feature-box-title">Payments Portal</h4>
                   <p className="feature-box-inner">Accountability for both Brand and Creator. Manage promotions and proper completion of agreements.</p>
                 </div>
               </div>
             </div>
           </div>
         </div>
        </div>
      </section>

      <section className= "InfluencingEasySection" >
       <div className='bg-prime text-light curved-img-section'>
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-lg-5">
                <div className= 'img-influencingEasy'>
                  <img src={influencingEasy} className='d-none d-lg-block' />
                </div>
              </div>
               <div className="col-sm-5">
                  <div className= 'img-influencingEasyMobile'>
                    <img src={influencingEasyMobile} className='d-none d-lg-block' />
                  </div>
                </div>
            <div className="col-lg-7 text-col text-center text-md-left">
              <h2 className='heading-style text-light'>
                  <div className= 'InfluencingEasyTitle'>
                  Promotion Has Never Been Easier
                  </div>
              </h2>
            <div className= "InfluencingEasyText">
              <div className="py-1 d-md-block d-none"></div>
              <p>Making promotions with Creators requires both starting connections and facillitating agreements. Our platform simplifies this process to prioritize user friendliness, making Creator campaigns easier than ever. Endorse provides both
              Creators and Brands the best Creator Marketing platform available.</p>
              <div className="py-1 d-md-block d-none"></div>
            </div>
              </div>
              </div>
            </div>
          </div>
      </section>

      <section className="audience-section">
        <div className="container">
          <div className="row">
            <div className="col-md-7 text-center text-md-left">
              <h2 className="heading-style clr-prime">
                <div className="capture-title">
                Capture an Audience
                </div>
              </h2>
              <div className="py-1 d-md-block d-none"></div>
              <p>The value of a Creator lies in their persuasion. Through content creation each Creator serves as a trusted source and point of interest to their audience. Combined with the right Brand collaboration creates enormous
              potential for conversions using Creator Marketing.</p>
            </div>
            <div className="col-md-12">
              <img src={captureAudience} alt='capture-audience-icon' />
            </div>
          </div>
        </div>
      </section>

     <section className="bg-prime text-light curved-img-section-right">
        <div className= "unmatched-engagement">
          <div className="container-fluid">
          <div className='row align-items-right'>
                        <div className="col-lg-5">
                           <div className= 'unmatched-icon'>
                            <img src={unmatched} alt="unmatched-icon" class="d-none d-lg-block"/>
                           </div>
                        </div>
            <div className="unmatched-section">
              <div className="col-lg-5 text-col text-center text-md-left">
                <h2 class="heading-style text-light">
                  <div className= "unmatched-title">
                  Unmatched Engagement
                  </div>
                </h2>
               <div className= "UnmatchedEngagementText">
               <div className="py-1 d-md-block d-none"></div>
               <p>No matter the target audience for Brands the ideal method for reaching individuals is Creator Marketing. Audience's today are increasingly more engaged with Content Creators on social media compared to any alternative marketing medium. Regardless of a Creator's follower count an effective promotion with the right Brand creates unmatched engagement rates. Endorse offers the best advertising returns for any Brand utilizing the power of the Creator Economy.</p>
               <div className="py-1 d-md-block d-none"></div>
              </div>
             </div>
             <div className="col-md-1 d-md-none d-lg-block"></div>

              <div className= 'unmatched-mobile'>
               <img src={unmatchedMobile} alt="unmatched-icon" class="d-none d-lg-block"/>
              </div>
            </div>
          </div>
         </div>
        </div>
      </section>





    </main>

    </Wrapper>

    
    
  )
}

export default Home;
