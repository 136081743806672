import styled from 'styled-components'

export const Wrapper = styled.div`

.accountStatus {
    display: block;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 5rem;
    }

    .titleOne {
    }
    .titleTwo {
        margin-top: 2rem;
    }

    button {
        margin-top: 2rem;
        margin-bottom: 2rem;
    }



`