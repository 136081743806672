import jwt from 'jwt-decode'
import store  from 'utils/store'
import { preserveState } from 'redux/actions/authActions'

export const validateToken = () => {
  const token = localStorage.getItem('token')
  if (token) {
    const decoded = jwt(token)
    const exp = decoded.exp
    const expConverted = new Date(exp * 1000)
    const current = new Date()
    if (current < expConverted) {
      store.dispatch(preserveState(token))
    } else {
      
    }
  }
}