import React from 'react';
import { NavLink } from 'react-router-dom';

const Settings = () => {
  return (
    <div className="section">
      <NavLink to="/dashboard" className="link">Dashboard</NavLink>
      <h1>Profile</h1>
      <p>Here is the settings</p>
    </div>
  )
};

export default Settings;