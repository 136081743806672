import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import store from 'utils/store'
import { Provider } from 'react-redux'
import { saveState } from 'utils/localStorage'
import './styles/index.css'
import 'semantic-ui-css/semantic.min.css'
import { validateToken } from 'utils/tokenChecker'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

const stripePromise = loadStripe('pk_live_ONeyIlHWwMZQTowOUsIufjFM00DViPqsjR')


store.subscribe(() => {
  saveState(store.getState())
})

window.addEventListener('beforeunload', validateToken())
window.addEventListener('load', validateToken())

ReactDOM.render(
  <Elements stripe={stripePromise}>
    <Provider store={store}>
        <App />
    </Provider>
  </Elements>,
  document.getElementById('root')
)

