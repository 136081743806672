import React, { useEffect, TextField } from 'react'
import { Wrapper } from './PaymentPortalStyles'
import axios from 'axios';
import { SERVER_URI } from 'config'
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Button, Icon } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllUsers, getPendingTasks } from 'redux/actions/searchActions'
import { getUserById } from 'redux/actions/userActions';
import PaymentForm from './PaymentMethod.js'
const stripe = require('stripe')('sk_test_51Glqp3HERG1YvueQVGLm9mW6l52hv6mf23T61GOKWGm11OyShQcByWDI92OWj3ujP4NgCbCE6PIg4BB8QZfjZSxY00tTyXBJXf');


const baseUrl = `${SERVER_URI}/api/stripe`;
const PaymentPortal = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const influencers = state.search.influencers.data;
  const tasks = state.search.tasks;
  const [influencer, setInfluencer] = useState();
  const [newStripeAccount, setNewStripeAccount] = useState('');
  const [status , setStatus] = useState('');
  const [date, setDate] = useState('');
  const [amount, setAmount] = useState(0);
  const [warning, setWarning] = useState('');
  const userID = state.auth.user.id;
  const subscriptionType = state.user.person.subscriptionType;
  const payMethod = state.user.person.payMethod;
  const currentUser = state.user.person;
  const stripeCustomer = localStorage.getItem('stripeID');
  // get stripe account
  const stripeAccount = currentUser.stripeAccountId;
  const stripeAccountLink = localStorage.getItem('stripeAccountLink');

// TODO  if connect is not setup but user has a stripe account allow user to resume account setup.

const { price, handleSubmit, formState } = useForm();
  const onClick = async (price) => {

    console.log(influencer);
    console.log(date);
    createTransfer();
}



useEffect(() => {

  // Is called when loggin
  stripeAccount && setNewStripeAccount(stripeAccount);
  // Is call if no account is found
  !stripeAccount && setNewStripeAccount(localStorage.getItem('stripeAccount'));
  dispatch(getAllUsers());
  dispatch(getUserById(userID));
}, [dispatch])


useEffect(() => {
 if(influencers !== undefined)
 if(influencers.length > 0) setInfluencer(influencers[0].id);

} , [])

  useEffect(() => {
  if (payMethod === true || subscriptionType === 'Premium') {
    setIsUnlocked(true);
    }
  }, [payMethod, subscriptionType]);

const onChange = event => {
  if(event.target.name === "creator") return setInfluencer(event.target.value);
  if(event.target.name === "date") return setDate(event.target.value);

};

const handleChange = (e) => {
  const newValue = parseInt(e.target.value, 10);
  if (newValue >= 20) {
      setAmount(newValue * 100);
      setWarning('');
  } else {
      setWarning('Amount must be at least $20');
  }
  // Removed setPrice line
};

const createTransfer = async () => {
const transfer = await axios.post(`${baseUrl}/create-transfer`, {
  amount: amount,
  payer: userID,
  payee: influencer, // should be the influencer id
  date: date

})
console.log(transfer.data.paymentIntent);
setStatus(transfer.data.paymentIntent.status);
}
// Stripe Account Setup
const createStripeAccount = async () => {

console.log('called: createStripeAccount');
  const account =  await axios.post(`${baseUrl}/create-account`, {
    email: state.auth.user.email,
    userId: state.auth.user.id,
  });

  const accountId = account.data.account.id;
  const accountLink = account.data.accountLink;
  console.log(account, 'this is the stripe account id', accountLink);

  setNewStripeAccount(accountId);

  localStorage.setItem('stripeAccount', accountId);
  localStorage.setItem('stripeAccountLink', accountLink);
   window.open(accountLink, "_blank");
}


const testFunction = async () => {
  console.log("test function", state.auth.user );
}

function insertDecimal(num) {
   return Number((num / 100).toFixed(2));
}

const [isUnlocked, setIsUnlocked] = useState(false);

const finishStepOne = async () => {
  setIsUnlocked(true); // Unlock the section by setting the state to true
};

  return (

    <Wrapper>
      {/* takes you to stripe connect account  */}
    { newStripeAccount === "null" && (
      <section className='section'>
        <div className='toplink'>
          <a href='/dashboard'>
            <Button>
            <div className="link">
            <Icon name='chevron left'></Icon>
            Dashboard</div>
            </Button>
            </a>

          </div>
          <div className='paymentMethodBtn'> 
          <a href="/stripe-login">
            <Button className="py-4 px-4 bg-blue-400 text-white rounded-xl">
              Add Payment Method
            </Button>
          </a>        
        </div>
      </section>

    )}



    {newStripeAccount !== "null" && (
          <section className='section'>
            <div className='toplink'>
                <a href='/dashboard'>
                  <Button>
                  <div className="link">
                  <Icon name='chevron left'></Icon>
                  Dashboard</div>
                  </Button>
                  </a>

                  <a href='/payout-portal'>
                    <Button>
                    <div className="link">
                    Payout History</div>
                    </Button>
                  </a>

             </div>
              <div>
                
                {subscriptionType === "Basic" && !payMethod && (
                  <div className = 'firstStep'>
                    
                    <PaymentForm customer={stripeCustomer} userID={userID} />

                    

                    {!isUnlocked && ( // Conditional rendering: button is displayed only when section is locked
                      <button onClick={finishStepOne}>Create Stripe Account</button>
                    )}
                  
                  </div>

                )}

              </div>

          <h1>Payment Portal</h1>


          <div className='Payment-Instructions'>
            <div className='text'>
              <p>Brands enter the compensation for Creators, Submit Payment, and Promoquo will release payment</p>
            </div>
          </div>

          <div className={isUnlocked ? 'unlocked' : 'locked'} id='lockedSection'>
            <div className='Payment-Box'>
            <form className='Payment-Form' action="/stripe/Card.js">

            <div className='Payment-Input'>
            <div className='Price-Input'>
              <label htmlFor="price">Payment Amount:</label><br></br>

            
            <input type="number" min="20" placeholder="$" value={price} onChange={handleChange}  id="price" name="price"  /><br></br>
            {warning && <div style={{color: 'red'}}>{warning}</div>}
              </div>

              <div className="Username-Input">
              <label htmlFor="creator">Creator Username:</label><br></br>
              <select name="creator" id="creator" onChange={(e)=> onChange(e)}> {influencers
                ? influencers.map((influencer, i) => (
                    
                <option key={i}  value={influencer.id} >{influencer.username}</option>
                    ))
                  : null
                }
              </select><br></br>
              </div>

              <div className='Date-Input'>
              <label htmlFor="fname">Date for Content:</label><br></br>
              <input type="date" id="date" name="date" value={date} onChange={onChange} /><br></br>

              </div>

              </div>

            </form>
            </div>

            <div className='paymentTotal'>
            <label htmlFor='subtotal'> Payment:{insertDecimal(amount)} </label><br></br>

            {currentUser.subscriptionType === 'Basic' ? <label htmlFor='fee'> Fee:{insertDecimal(amount * .1)} </label> :
            <label htmlFor='fee'> Fee:{insertDecimal(amount * .05)} </label>
            }
            <br></br>
            {currentUser.subscriptionType === 'Basic' ? <label htmlFor='total'> Total:{insertDecimal(amount * 1.1)} </label> :
            <label htmlFor='total'> Total:{insertDecimal(amount * 1.05)} </label>
            }
            <br></br>
            <div className= 'payment-bottom'>
            </div>
            </div>

            <div className="Submit-Button">
            <input type="submit" value="Submit" className="Submit-Button-Input" onClick={handleSubmit(onClick)}/>
            </div>
          </div>

          { status && (
            <div>
              <h1><a href={status }>{status}</a></h1>
            </div>
          )

          }
        </section>
      )
    }

  </Wrapper>
  )
 }

export default PaymentPortal;
