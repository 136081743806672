import {
  GET_ALL_USERS_START,
  GET_ALL_USERS_SUCCESS,
  GET_ALL_USERS_FAIL,
  GET_ALL_BRANDS_START,
  GET_ALL_BRANDS_SUCCESS,
  GET_ALL_BRANDS_FAIL,
  GET_BRAND_START,
  GET_BRAND_SUCCESS,
  GET_BRAND_FAIL,
  GET_ALL_INFLUENCERS_START,
  GET_ALL_INFLUENCERS_SUCCESS,
  GET_ALL_INFLUENCERS_FAIL,
  GET_INFLUENCER_START,
  GET_INFLUENCER_SUCCESS,
  GET_INFLUENCER_FAIL,
  GET_PENDING_TASKS_START,
  GET_PENDING_TASKS_SUCCESS,
  GET_PENDING_TASKS_FAIL,
  GET_PENDING_PAYOUTS_START,
  GET_PENDING_PAYOUTS_SUCCESS,
  GET_PENDING_PAYOUTS_FAIL,
  GET_COMPLETE_PAYOUTS_START,
  GET_COMPLETE_PAYOUTS_SUCCESS,
  GET_COMPLETE_PAYOUTS_FAIL,
} from '../actions/types'

const initialState = {
  tasks: [],
  brands: {},
  singleBrand: {},
  influencers: {},
  singleInfluencer: {},
  isLoading: false,
  error: '',

}

export const reducer = (state = initialState, action ) => {
  switch (action.type) {
     // ALL INFLUENCERS
     case GET_PENDING_TASKS_START:
      return { ...state, isLoading: true, error: '' }
    case GET_PENDING_TASKS_SUCCESS:
      return { ...state, isLoading: false, tasks: action.payload }
    case GET_PENDING_TASKS_FAIL:
      return { ...state, isLoading: false, error: action.payload }

      // ALL BRANDS
     case GET_PENDING_PAYOUTS_START:
      return { ...state, isLoading: true, error: '' }
    case GET_PENDING_PAYOUTS_SUCCESS:
      return { ...state, isLoading: false, tasks: action.payload }
    case GET_PENDING_PAYOUTS_FAIL:
      return { ...state, isLoading: false, error: action.payload }

    case GET_COMPLETE_PAYOUTS_START:
      return { ...state, isLoading: true, error: '' }
    case GET_COMPLETE_PAYOUTS_SUCCESS:
      return { ...state, isLoading: false, tasks: action.payload }
    case GET_COMPLETE_PAYOUTS_FAIL:
      return { ...state, isLoading: false, error: action.payload }

    // ALL BRANDS
    case GET_ALL_BRANDS_START:
      return { ...state, isLoading: true, error: '' }
    case GET_ALL_BRANDS_SUCCESS:
      return { ...state, isLoading: false, brands: action.payload }
    case GET_ALL_BRANDS_FAIL:
      return { ...state, isLoading: false, error: action.payload }

    // BRAND BY ID
    case GET_BRAND_START:
      return { ...state, isLoading: true, error: '' }
    case GET_BRAND_SUCCESS:
      return { ...state, isLoading: false, singleBrand: action.payload, error: '' }
    case GET_BRAND_FAIL:
      return { ...state, isLoading: false, error: action.payload }

    // ALL INFLUENCERS
    case GET_ALL_INFLUENCERS_START:
      return { ...state, isLoading: true, error: '' }
    case GET_ALL_INFLUENCERS_SUCCESS:
      return { ...state, isLoading: false, influencers: action.payload }
    case GET_ALL_INFLUENCERS_FAIL:
      return { ...state, isLoading: false, error: action.payload }

    // INFLUENCER BY ID
    case GET_INFLUENCER_START:
      return { ...state, isLoading: true, error: '' }
    case GET_INFLUENCER_SUCCESS:
      return { ...state, isLoading: false, singleInfluencer: action.payload, error: '' }
    case GET_INFLUENCER_FAIL:
      return { ...state, isLoading: false, error: action.payload }

    // Users By type
    case GET_ALL_USERS_START:
      return { ...state, isLoading: true, error: '' }
    case GET_ALL_USERS_SUCCESS:
      return { ...state, isLoading: false, influencers: action.payload, error: '' }
    case GET_ALL_USERS_FAIL:
      return { ...state, isLoading: false, error: action.payload }

    default:
      return state
  }
}
