import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getAllBrandsById } from 'redux/actions/searchActions'
import { Wrapper } from 'components/Dashboard/BrandSearch/BrandStyles'
import { Card, Icon, Button } from 'semantic-ui-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Brand = (props) => {
  const id = Number(props.match.params.id)
  const dispatch = useDispatch()
  const state = useSelector((state) => state)
  const brand = state.search.singleBrand.data
  console.log(brand,'BRAND ON BRAND.js')
  console.log(state, 'STATE IS HERE ON BRAND')
  console.log(props, 'these are some props');

  useEffect(() => {
    dispatch(getAllBrandsById(id))
  }, [dispatch, id])

  return (
    // link on line 30 goes to influencer inbox because you are the influencer and it is YOUR inbox
    <Wrapper>
    <div className='section'>
    <div className='toplink'>
      <a href='/dashboard'>
        <Button>
        <div className="link">
        <Icon name='chevron left'></Icon>
        Dashboard</div>
        </Button>
        </a>

     </div>
    <div className='single-brand'>
      <Card>
      {brand
        ? <div>
          <img
            style={{
              height: '120px',
              width: '120px',
              borderRadius: '50%',
              // margin: "2% 70% 0 0%"
            }}
            src={brand.image} alt='avatar'
          />
          <h1>{brand.username}</h1>
          <p>{brand.bio}</p>
          <div className='icon-links'>
          {brand.instagramLink ? (
            <p>
            <a
              className='link'
              href={brand.instagramLink}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none", color: "#6CB7D9" }}
            >
              <FontAwesomeIcon icon={['fab', 'instagram']} color='#6CB7D9' size='2x' />
            </a>
          </p>
          ) : (

            <></>

          )}

          {brand.twitterLink ? (
            <p>
            <a
              className='link'
              href={brand.twitterLink}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none", color: "#6CB7D9" }}
            >
              <FontAwesomeIcon icon={['fab', 'twitter']} color='#6CB7D9' size='2x' />
            </a>
          </p>
          ) : (
            <></>
          )}

          {brand.tiktokLink ? (
            <p>
            <a
              className='link'
              href={brand.tiktokLink}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none", color: "#6CB7D9" }}
            >
              <FontAwesomeIcon icon={['fab', 'tiktok']} color='#6CB7D9' size='2x' />
            </a>
          </p>
          ) : (
            <></>
          )}

        {brand.twitchLink ? (
          <p>
          <a
            className='link'
            href={brand.twitchLink}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", color: "#6CB7D9" }}
          >
            <FontAwesomeIcon icon={['fab', 'twitch']} color='#6CB7D9' size='2x' />
          </a>
        </p>
        ) : (
          <></>
        )}

        {brand.youtubeLink ? (
          <p>
          <a
            className='link'
            href={brand.youtubeLink}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", color: "#6CB7D9" }}
          >
            <FontAwesomeIcon icon={['fab', 'youtube']} color='#6CB7D9' size='2x' />
          </a>
        </p>
        ) : (
          <></>
        )}

        {brand.otherLink ? (
          <p>
          <a
            className='link'
            href={brand.otherLink}
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: "none", color: "#6CB7D9" }}
          >
            <FontAwesomeIcon icon='user' color='#6CB7D9' size='2x' />
          </a>
        </p>
        ) : (
          <></>
        )}

        </div>
          <Button>
            <Link to={`/dashboard/influencer/inbox?chatWith=${brand.id}&username=${brand.username}`}>
            Connect
            </Link>
          </Button>
        </div>

        : null
      }
      </Card>
    </div>
    </div>
    </Wrapper>
  )
}

export default Brand
