import React, { useEffect } from 'react'
import { Route, Router, Switch, useLocation } from 'react-router-dom'

// general components
import Nav from 'components/NavBar/NavBar';
import Home from 'pages/Home/Home';
import About from 'pages/About/About';
import NewUser from 'pages/NewUser/NewUser';

// user components
import Dashboard from './components/Dashboard/'
import Settings from './components/Dashboard/Settings'
import Deals from './components/Dashboard/Deals'
import InfluencerMessaging from './components/Dashboard/Messaging'
import MyProfile from 'components/MyProfile/myProfile'
import BrandSearch from 'components/Dashboard/BrandSearch/BrandSearch'
import Brand from 'components/Dashboard/BrandSearch/Brand'
import InfluencerSearch from 'components/Dashboard/InfluencerSearch/InfluencerSearch'
import Influencer from 'components/Dashboard/InfluencerSearch/Influencer'
import BrandMessaging from 'components/Dashboard/Messaging/BrandMessaging'
import PaymentPortal from 'components/PaymentPortal/PaymentPortal'
import PaymentPortalTwo from 'components/PaymentPortal/PaymentPortalTwo'
import PayoutPortal from 'components/PaymentPortal/PayoutPortal'
import PublicProfile from 'components/MyProfile/PublicProfile'

// register and login
import Register from 'auth/Register';
import Login from 'auth/Login';
import PasswordReset from 'auth/PasswordReset';
import { AuthProvider } from './auth/firebase/AuthContext.js';

// blog
import Posts from 'pages/NewBlog/posts/posts';
import SinglePost from 'pages/NewBlog/posts/singlePost';

//utils
import { PrivateRoute } from 'utils/privateRoute';
import history from 'utils/history'

// styles
import './styles/App.css'
import UpgradeOptions from 'components/Dashboard/UpgradeOptions/UpgradeOptions';
import Prices from './stripe/Prices.js'
import Account from './stripe/Account.js';
import StripeRegister from './stripe/StripeRegister.js';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from './stripe/CheckoutForm.js';
import StripeSubscription from './stripe/StripeSubscription.js';
import Product from './stripe/Product.js';

import { library } from '@fortawesome/fontawesome-svg-core'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fab, faFacebook, faInstagram, faPinterest, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faBars, faEnvelope, faMapMarker, faPhone, fas, faUnlock, faUser } from '@fortawesome/free-solid-svg-icons'
import Footer from 'components/Footer/Footer';
import Terms from 'components/Footer/Terms';
import Privacy from 'components/Footer/Privacy';
import 'styles/App.css'

library.add(fab, faUser, fas, faBars, faUser, faUnlock, faFacebook, faTwitter, faPinterest, faInstagram, faYoutube, faMapMarker, faPhone, faEnvelope,)



const App = (props) => {
  

  return (
    <div className="App">
      <div className="components">
        <Router history={history}>
          <Nav />
          <Switch>
            {/**** PRIVATE ROUTES ****/}
            <PrivateRoute exact path="/dashboard" component={Dashboard} />
            <PrivateRoute exact path='/dashboard/settings' component={Settings} />
            <PrivateRoute exact path='/upgrade-account' component={UpgradeOptions} />
            <PrivateRoute exact path='/dashboard/deals' component={Deals} />
            <PrivateRoute exact path='/dashboard/influencer/inbox' component={InfluencerMessaging} />
            <PrivateRoute exact path='/dashboard/brand/inbox' component={BrandMessaging} />
            <PrivateRoute exact path='/brandsearch' component={BrandSearch} />
            <PrivateRoute exact path='/brand/user/:id' component={Brand} />
            <PrivateRoute exact path='/influencersearch' component={InfluencerSearch} />
            <PrivateRoute exact path='/influencer/user/:id' component={Influencer} />
            <PrivateRoute exact path='/stripe-login' component={StripeRegister} />
            <PrivateRoute exact path='/stripe-prices' component={Prices} />
            <PrivateRoute exact path='/stripe-account' component={Account} />
            <PrivateRoute exact path='/stripe/CheckoutForm' component={CheckoutForm} />
            <PrivateRoute exact path='/stripe/StripeSubscription' component={StripeSubscription} />
            <PrivateRoute exact path='/stripe/Product' component={Product} />
            <PrivateRoute exact path='/payment-portal' component={PaymentPortal} />
            <PrivateRoute exact path='/payment-portal-two' component={PaymentPortalTwo} />
            <PrivateRoute exact path='/payout-portal' component={PayoutPortal} />

            
            {/**** PROFILE ROUTES ****/}
            <PrivateRoute exact path='/my-profile' component={MyProfile} />

            {/**** MAIN ROUTES ****/}
            <Route exact path="/" component={Home} />
            <Route path="/about" component={About} />
            <Route path='/terms' component={Terms} />
            <Route path='/privacy' component={Privacy} />
            <Route path='/new-user' component={NewUser} />
            <Route exact path="/public-profile/:userId" component={PublicProfile} />
            
            <Route exact path='/blog' component={Posts} />
            <Route exact path='/blog/:id' component={SinglePost} />
            
            {/**** AUTH ROUTES ****/}
            <Route exact path="/login" component={Login} />
            <Route exact path='/reset' component={PasswordReset} />
              
            <AuthProvider>
            <Route path='/register' component={Register} />
            </AuthProvider>

            <PrivateRoute exact path={`/brand/user/`} component={Brand} />
          </Switch>
          <Footer />
        </Router>
      </div>
    </div>
  );
}

export default App;




/* 
// **** NOTES **** //

To remove HRM message in console:
- go to node_modules
- webpack
- hot
- comment out console.log(msg) under if level ==="info"
- reload server

*/
