import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Icon, Button } from 'semantic-ui-react';
import { Wrapper } from 'components/MyProfile/PublicProfileStyles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getAllInfluencersById } from 'redux/actions/searchActions'
import { useParams } from 'react-router-dom';


const PublicProfile = props => {
    const dispatch = useDispatch();
    const state = useSelector(state => state);
    const { userId } = useParams();

    // consolelog for userId
    console.log(userId, 'this is the userId');
    const influencer = state.search.singleInfluencer.data

    useEffect(() => {
        dispatch(getAllInfluencersById(userId)); 
    }, [dispatch, userId]);

    console.log("PublicProfile component is rendering");

    return (
        <Wrapper>
            <div className='section'>
                
             <div className="profile-page">
                    <Card>
                {influencer
                ? <div className="profile">
                    <img style={{ width: '120px', height: '120px', borderRadius: '50%' }}
                    src={influencer.image}
                    alt='avatar'
                    />
                    <h1>{influencer.username}</h1>
                    <p>{influencer.bio}</p>

                    <div className='icon-links'>
                    {influencer.instagramLink ? (
                    <p>
                    <a
                        className='link'
                        href={influencer.instagramLink}
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: "none", color: "#6CB7D9" }}
                    >
                        <FontAwesomeIcon icon={['fab', 'instagram']} color='#6CB7D9' size='2x' />
                    </a>
                    </p>
                    ) : (

                    <></>

                    )}

                    {influencer.twitterLink ? (
                    <p>
                    <a
                        className='link'
                        href={influencer.twitterLink}
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: "none", color: "#6CB7D9" }}
                    >
                        <FontAwesomeIcon icon={['fab', 'twitter']} color='#6CB7D9' size='2x' />
                    </a>
                    </p>
                    ) : (
                    <></>
                    )}

                    {influencer.tiktokLink ? (
                    <p>
                    <a
                        className='link'
                        href={influencer.tiktokLink}
                        target="_blank"
                        rel="noreferrer"
                        style={{ textDecoration: "none", color: "#6CB7D9" }}
                    >
                        <FontAwesomeIcon icon={['fab', 'tiktok']} color='#6CB7D9' size='2x' />
                    </a>
                    </p>
                    ) : (
                    <></>
                    )}

                    {influencer.twitchLink ? (
                    <p>
                    <a
                    className='link'
                    href={influencer.twitchLink}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none", color: "#6CB7D9" }}
                    >
                    <FontAwesomeIcon icon={['fab', 'twitch']} color='#6CB7D9' size='2x' />
                    </a>
                    </p>
                    ) : (
                    <></>
                    )}

                    {influencer.youtubeLink ? (
                    <p>
                    <a
                    className='link'
                    href={influencer.youtubeLink}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none", color: "#6CB7D9" }}
                    >
                    <FontAwesomeIcon icon={['fab', 'youtube']} color='#6CB7D9' size='2x' />
                    </a>
                    </p>
                    ) : (
                    <></>
                    )}

                    {influencer.otherLink ? (
                    <p>
                    <a
                    className='link'
                    href={influencer.otherLink}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none", color: "#6CB7D9" }}
                    >
                    <FontAwesomeIcon icon='user' color='#6CB7D9' size='2x' />
                    </a>
                    </p>
                    ) : (
                    <></>
                    )}

                    </div>
                    </div>
                : null
                }
             </Card>
                
            </div>
                <div className='toplink'>
                    <div>
                    < p > Looking to join? <a href='/register'>Sign Up today </a> </p>
                     
                   
                    
                    </div>
                </div>
            </div>
        </Wrapper>
    )
}

export default PublicProfile;
