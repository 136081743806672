import React, {useRef, useState} from 'react'
import { Radio } from 'semantic-ui-react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Form, Card, Button, Alert } from 'react-bootstrap'
import { Wrapper } from 'auth/NewRegisterStyles'
import { useAuth } from 'auth/firebase/AuthContext.js'
import { AuthProvider } from 'auth/firebase/AuthContext.js'
import {Link, useHistory} from 'react-router-dom'
import { collection, addDoc } from "firebase/firestore";
import {db} from 'auth/firebase/firebase.js'
import { registerRequest } from 'redux/actions/newAuthActions'
import { connect } from 'react-redux'
import history from 'utils/history'


  const Register=(props) => {

  const [ type, setType ] = useState('');
  const [ input, setInput ] = useState({
    fullName: '',
    email: '',
    password: '',
  })

  const fullNameRef = useRef()
  const emailRef = useRef()
  const passwordRef = useRef()
  const passwordConfirmRef = useRef()
  const {signup}= useAuth()
  const docRef = useRef()
  const typeRef = useRef()
  const [errors, setErrors] = useState('')
  const [loading, setLoading] = useState(false)
  const history = useHistory()



/*
  const onClick= (event) => {
    event.preventDefault()

    // alert('Successfully Registered, please log in')
  };
*/

const onChange = (event) => {
    setInput({ ...input, [event.target.name]: event.target.value })
    setErrors("");
  }

  const handleTypeChange = ({ target: { value } }) => {
    setType(value);
    setErrors("");
  }



  async function handleSubmit(e) {
  e.preventDefault();
  if (type !== 'brand' && type !== 'influencer') {
    return setErrors("Please select either Creator or Brand");
  }
  if (passwordRef.current.value !== passwordConfirmRef.current.value) {
    return setErrors('Passwords do not match')
  }
  try {
    setErrors('')
    setLoading(true)
    await signup(emailRef.current.value, passwordRef.current.value)
    if (type=='brand') {
      const docRef = await addDoc(collection(db, "Brands"), {name: fullNameRef.current.value, email: emailRef.current.value, type: type});
      console.log("Document written with ID: ", docRef.id);
    } else {
      const docRef = await addDoc(collection(db, "Creators"), {name: fullNameRef.current.value, email: emailRef.current.value, type: type});
      console.log("Document written with ID: ", docRef.id);
    }
    return props.registerRequest(input, type, history)
  } catch (error) {
    setErrors('Username or Email already in use')
  } finally {
    setLoading(false)
  }
}




  return (

  <AuthProvider>
  <Wrapper>
  <div className= "registerWrap">
  <div className= "register">
    <Card className= "cardForm">
      <Card.Body className= "cardBody">
      <h2 className="title"> Sign Up </h2>
      {errors && <Alert variant="danger">{errors}</Alert>}
      <Form onSubmit={handleSubmit}>
        <Form.Group id="fullName">
         <Form.Label className= "cardLabel">Username</Form.Label>
         <Form.Control className= "cardInput" type="text" name="fullName" placeholder="" value={input.fullName} ref={fullNameRef}  onChange={onChange}  required/>
        </Form.Group>
        <Form.Group id="email">
         <Form.Label className= "cardLabel">Email</Form.Label>
         <Form.Control className= "cardInput" type="text" name="email" placeholder="" value={input.email} ref={emailRef} onChange={onChange} required/>
        </Form.Group>
        <Form.Group id="password">
         <Form.Label className= "cardLabel">Password</Form.Label>
         <Form.Control className= "cardInput" type="password" name="password" placeholder="" value={input.password} ref={passwordRef} onChange={onChange} required />
        </Form.Group>
        <Form.Group id="password-confirm">
         <Form.Label className= "cardLabel">Confirm Password</Form.Label>
         <Form.Control className= "cardInput" type="password" placeholder="" ref={passwordConfirmRef} required />
        </Form.Group>
        <Form.Group className='types' >


            <Radio
              label='Creator'
              type='radio'
              id='influencer'
              name='type'
              value='influencer'
              onChange={handleTypeChange}
              />

            <Radio
              label='Brand'
              type='radio'
              id='brand'
              name='type'
              value='brand'
              onChange={handleTypeChange}
            />
          </Form.Group>
        <Button disabled={loading} className="cardButton" type="submit" >Sign Up</Button>
      </Form>
      </Card.Body>
    </Card>
    <div className="titletwo">
     Already Have an Account? <Link to="/login"> Log In </Link>
    </div>
    </div>
    </div>
    </Wrapper>
  </AuthProvider>

  )


}



const mapStateToProps = state => {
  return {
    error: state.error,
  }
}


export default connect(mapStateToProps, {registerRequest})(Register)
