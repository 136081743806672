import React, { useEffect, useState } from 'react' 
import { withRouter, Link } from 'react-router-dom'
import Product from './Product'
import PriceChangeForm from './PriceChangeForm'
import { SERVER_URI } from 'config'
import { Button } from 'semantic-ui-react'
import { useSelector } from 'react-redux'
import { Wrapper } from './AccountStyles'
// import { useSelector } from 'react-redux'

const products = [
  {
    key: 0,
    price: '$10.00',
    name: 'Basic',
    interval: 'month',
    billed: 'monthly',
    priceId: 'price_1HpicbHERG1YvueQAW3uHbSx'
  },
  {
    key: 1,
    price: '$20.00',
    name: 'Premium',
    interval: 'month',
    billed: 'monthly',
    priceId: 'price_1HpicwHERG1YvueQXOAox3gH'
  },
]

const Account = ({ location }) => {
  console.log(location, 'LOCAAATION from account page')
  // const state = useSelector(state => state)
  // console.log(state, 'STATTE')
  const [ accountInformation ] = useState(location.state.accountInformation)

  console.log(accountInformation, "THIS IS ACCOUNT INFO")

  const [ customerPaymentMethod, setCustomerPaymentMethod ] = useState(null)
  const [ showChangePriceForm, setShowChangePriceForm ] = useState(false)
  const [ subscriptionCancelled, setSubscriptionCancelled ] = useState(false)
  const [ newProductSelected, setNewProductSelected ] = useState('')
  const [ selectedProduct, setSelectedProduct ] = useState(accountInformation.priceId)
  console.log(selectedProduct, 'THIS IS SELECRED PRODUFU')
  const amount = accountInformation.subscription.plan.amount
  const currentCustomer = accountInformation.subscription.customer
  console.log(currentCustomer, 'This is the current customer that is logged in')

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2
  })

  const price = formatter.format(amount / 100)

  // accountInformation.subscription.plan.amount THIS IS the amount of the current plan

  useEffect(() => {
    async function fetchData() {
      const response = await fetch(`${SERVER_URI}/api/stripe/retrieve-customer-payment-method`, {
        method: 'post',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          paymentMethodId: accountInformation.paymentMethodId,
        })
      })
        const responseBody = await response.json()
        const paymentMethod = 
          responseBody.card.brand + ' •••• ' + responseBody.card.last4
        setCustomerPaymentMethod(paymentMethod)
    }
    fetchData()
  }, [accountInformation.paymentMethodId])

  function handleChangePriceForm() {
    setShowChangePriceForm(true)
  }

  function handleClick(key) {
    setNewProductSelected(products[key].name)
  }

  function cancelSubscription() {
    console.log(accountInformation.subscription)
    fetch(`${SERVER_URI}/api/stripe/cancel-subscription`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        subscriptionId: accountInformation.subscription.id,
      })
    })
      .then((response) => {
        return response.json()
      })
      .then((cancelSubscriptionResponse) => {
        console.log(cancelSubscriptionResponse, 'cancelled response yo')
        setSubscriptionCancelled(true)
      })
  }

  function resetDemo() {
    localStorage.clear();
    window.location.href = '/';
  }

  return (
    <Wrapper>
    <div className="accountStatus">
      
        
            <div className= "titleOne">
              <h1>Account Status</h1>
            </div>
              <div className= "titleTwo">
                <h2>Current Subscription: {selectedProduct}</h2>
              </div>
        </div>
        <div>
          <button>
            <Link to='/my-profile'>Back to My Profile</Link>
          </button>
        </div>
    </Wrapper>
  )
}



export default withRouter(Account)