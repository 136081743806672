import styled from 'styled-components'

export const Wrapper = styled.div`
  .section {
    height: auto;
    width: auto;
    margin-top: 4.4rem;
    margin-bottom: 4.4rem;
    max-width: 100vw;
    overflow-x: hidden;
  }

  .toplink {
    display: flex;
    margin-top: 4rem;
    text-align: center;
    font-size: 1.5rem;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 100%;

    .ui.button {
      margin-top: 2rem;
      background: linear-gradient(90deg, #Ede0f7 0%, #B993ee 100%);
      color: white;
      text-shadow: 1px 1px 2px #959597;
      font-size: 17px;
      border: none;
      border-radius: .7rem;
      width: 175px;

      .link {
        color: white;
      }
    }
  }
  .ui.button:hover {
    transform: translateY(-3px);
    box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.15);
  }
  .share-link {
    display: flex;
    align-items: center;
    justify-content: start;

  .ui.button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
    .profile-page{
        display: flex;
        justify-content: center;
    }

    .ui.card {
      width: 450px;
      margin-top: 2rem;
      margin-bottom: 5vh;
      border: 10px solid;
      border-image-slice: 1;
      border-width: 4px;
      border-image-source: conic-gradient( #B993ee, #7c9eff, #befdff, #B993ee);
    
      .profile {
        justify-content: center;
      }

      img {
        margin: 0.5rem;
      }

      .header {
        margin-top: 10px;
      }

      .about-section {
        margin-top: 1rem;
        text-align: justify;
        text-align: center;
      }

      .icon-links {
        margin-top: 10px;
        display: flex;
        justify-content: center;

        .link {
          margin-right: 0.6rem;
          font-size: 0.8rem;
        }

        a {
          padding: 3px;
        }

        p {
          margin-bottom: 0;
        }
      }

    }

    .buttons {

      .ui.button {
        padding: .78571429em 1.3em .78571429em;
        background-color: #abe4fc;
      }

      .editProfile-btn {
        /* border: 2px dotted purple; */
      }

      .stripe-btn {
        /* border: 2px dotted blue; */
      }
      button {
        margin: 0.5rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
    }
  }

  .edit-section {
    /* border: 3px dashed green; */
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    .ui.card {
      width: 465px;
      margin-bottom: 5vh;
      border: 10px solid;
      border-image-slice: 1;
      border-width: 6px;
      border-image-source: conic-gradient( #e7d9f9, #cbd9ff, #e6feff, #e7d9f9);
    }

    .card-section {
      /* border: 2px dashed blue; */



      img {
        /* border: 1px solid blue; */
      }
    }
  }

  .label {
    /* border: 2px solid blue;
    padding: 0.3rem;
    background-color: #E5E5E5;
    border-radius: 25% */
    margin: 0.3rem;
  }

  .inputs {
    margin-top: 20px;
    }

  .image-input {
    margin-top: 2rem;
    margin-bottom: 2rem;
    justify-content: start;


    .label {
      align-items: left;
    }
  }

  .about-me {
    display: flex;
    flex-direction: column;

    .ui.card>.content p {
      margin: 0 0 .1em;
    }

    .textarea {
      margin: .25rem;
      border: .25px solid;
      text-align: justify;
    }

    .instagram-shtuff {
      margin-top: 5px;
      margin-bottom: 5px;
      display: flex;
      justify-content: center;


      .insta {
        font-size: 1.5rem;
        margin-right: 8px;
      }

    }

    .twitter-shtuff {
      margin-top: 5px;
      margin-bottom: 5px;
      display: flex;
      justify-content: center;
      .twita {
        font-size: 1.5rem;
        margin-right: 8px;
      }
    }

    .tiktok-shtuff {
      margin-top: 5px;
      margin-bottom: 5px;
      display: flex;
      justify-content: center;
      .tik {
        font-size: 1.5rem;
        margin-right: 8px;
      }
    }

    .twitch-shtuff {
      margin-top: 5px;
      margin-bottom: 5px;
      display: flex;
      justify-content: center;
      .twit {
        font-size: 1.5rem;
        margin-right: 8px;
      }
    }

    .youtube-shtuff {
      margin-top: 5px;
      margin-bottom: 5px;
      display: flex;
      justify-content: center;
      .you {
        font-size: 1.5rem;
        margin-right: 8px;
      }
    }

    .other-shtuff {
      margin-top: 5px;
      margin-bottom: 5px;
      display: flex;
      justify-content: center;
      .other {
        font-size: 1.5rem;
        margin-right: 8px;
      }
    }

    .save-changes {
      margin-top: 10px;
      background: linear-gradient(90deg, #Ede0f7 0%, #B993ee 100%);
      color: white;
      text-shadow: 1px 1px 2px #959597;
      border: none;
      border-radius: .7rem;
      height: 30px;
    }
    .save-changes:hover {
      transform: translateY(-3px);
      box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.15);
      cursor: pointer;
    }
  }


`;
