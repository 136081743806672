import { axiosWithAuth } from 'utils/axiosWithAuth'
import { SERVER_URI } from 'config'
import { 
  GET_PROFILE_START, 
  GET_PROFILE_SUCCESS, 
  GET_PROFILE_FAIL,
  EDIT_PROFILE_START,
  EDIT_PROFILE_SUCCESS,
  EDIT_PROFILE_FAIL,
  EDIT_PIC_START,
  EDIT_PIC_SUCCESS,
  EDIT_PIC_FAIL,
} from './types'

console.log(SERVER_URI, 'this is from profile actions SERVER URI BROOO')

export const getMyProfile = id => dispatch=> {
  dispatch({ type: GET_PROFILE_START })
  axiosWithAuth()
    .get(`${SERVER_URI}/api/users/profile/${id}`) 
    .then(res => {
      dispatch({ type: GET_PROFILE_SUCCESS, payload: res.data })
    })
    .catch(error => {
      dispatch({ type: GET_PROFILE_FAIL, payload: error.response })
    })

}

export const editMyProfile = (id, changes) => dispatch => {
  dispatch({ type: EDIT_PROFILE_START })
  return axiosWithAuth()
    .put(`${SERVER_URI}/api/users/profile/${id}`, changes) 
    .then(res => {
      console.log(res, 'this is the rezzzs')
      console.log(changes, 'THESE ARE THE CHANGES YOOOO')
      dispatch({ type: EDIT_PROFILE_SUCCESS, payload: changes })
    })
    .catch(error => {
      console.log(error, 'THIS IS THE ERROR ON EDIT PROFILE')
      dispatch({ type: EDIT_PROFILE_FAIL, payload: error.response })
    })
}

export const editAvatar = (id, changes) => dispatch => {
  dispatch({ type: EDIT_PIC_START })
  return axiosWithAuth()
    .put(`${SERVER_URI}/api/users/profile/${id}/upload`, changes) 
    .then(res => {
      console.log(res, "HERE IS THE EDIT PIC RES")
      dispatch({ type: EDIT_PIC_SUCCESS, payload: changes })
    })
    .catch(error => {
      console.log(error, 'HERE IS THE EDIT PIC ERROR')
      dispatch({ type: EDIT_PIC_FAIL, payload: error.response })
    })
}