import { 
  GET_PROFILE_START, 
  GET_PROFILE_SUCCESS, 
  GET_PROFILE_FAIL,
  EDIT_PROFILE_START,
  EDIT_PROFILE_SUCCESS,
  EDIT_PROFILE_FAIL,
  EDIT_PIC_START,
  EDIT_PIC_SUCCESS,
  EDIT_PIC_FAIL,
} from '../actions/types'

const initialState = {
  myProfile: {},
  isLoading: false,
  error: '' 
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROFILE_START:
      return { ...state, isLoading: true }
    case GET_PROFILE_SUCCESS:
      return { ...state, isLoading: false, myProfile: action.payload }
    case GET_PROFILE_FAIL:
      return { ...state, isLoading: false, error: action.payload }
    case EDIT_PROFILE_START:
      return { ...state, isLoading: true }
    case EDIT_PROFILE_SUCCESS:
      return { ...state, isLoading: false, error: action.payload }
    case EDIT_PROFILE_FAIL:
      return { ...state, isLoading: false, error: action.payload }
    case EDIT_PIC_START:
      return { ...state, isLoading: true }
    case EDIT_PIC_SUCCESS:
      return { ...state, 
        myProfile: state.myProfile.map(myProfile =>
          myProfile.id === action.payload.id
          ? action.payload.changesPic
          : myProfile.image 
        ), isLoading: false }
    case EDIT_PIC_FAIL:
      return { ...state, isLoading: false, error: action.payload }
    default:
      return state
  }
}