import { axiosWithAuth }from 'utils/axiosWithAuth'
import { SERVER_URI } from 'config'
import {
  GET_USER_DATA_START,
  GET_USER_DATA_SUCCESS,
  GET_USER_DATA_FAIL,
  GET_SPECIFIC_USER_START,
  GET_SPECIFIC_USER_SUCCESS,
  GET_SPECIFIC_USER_FAIL,
  EDIT_USER_START,
  EDIT_USER_SUCCESS,
  EDIT_USER_FAIL,
  EDIT_USER_SUBSCRIPTION_START,
  EDIT_USER_SUBSCRIPTION_SUCCESS,
  EDIT_USER_SUBSCRIPTION_FAIL
} from './types'
console.log(SERVER_URI, 'this is from user actions SERVER URI BROOO')

const baseURL = `${SERVER_URI}/api/users/`


export const getUserProfiles = () => dispatch => {
  dispatch({ type: GET_USER_DATA_START })

  axiosWithAuth()
    .get(`${baseURL}`)
    .then(res => {
      dispatch({ type: GET_USER_DATA_SUCCESS, payload: res.data })
    })
    .catch(err => {
      dispatch({ type: GET_USER_DATA_FAIL, payload: err.response })
    })
}

export const getUserById = id => dispatch => {
  dispatch({ type: GET_SPECIFIC_USER_START })

  axiosWithAuth()
    .get(`${baseURL}${id}`)
    .then(res => {
      dispatch({ type: GET_SPECIFIC_USER_SUCCESS, payload: res.data })
    })
    .catch(err => {
      dispatch({ type: GET_SPECIFIC_USER_FAIL, payload: err.response })
    })
}

export const editUser = (id, changes) => dispatch => {
  dispatch({ type: EDIT_USER_START })
  return axiosWithAuth()
    .put(`${baseURL}/:type/${id}`, changes)
    .then(res => {
      dispatch({ type: EDIT_USER_SUCCESS, payload: changes })
    })
    .catch(err => {
      dispatch({ type: EDIT_USER_FAIL, payload: err.response })
    })
}

// rewrite the editUserSubscription action to use the correct endpoint
// the correct endpoint is /api/users//:id/subscriptionType
// the endpoint is expecting an object with a subscriptionType property
// the subscriptionType property should be a string
// the subscriptionType property should be one of the following: 'basic', 'premium'
// the subscriptionType property should be one of the following: 'basic', 'premium'

export const editUserSubscription = (id, subscriptionType) => dispatch => {
  dispatch({
    type: EDIT_USER_SUBSCRIPTION_START,
  })
  return axiosWithAuth()
    .put(`${baseURL}${id} `, { subscriptionType })
    .then(res => {
      dispatch({
        type: EDIT_USER_SUBSCRIPTION_SUCCESS,
        payload: subscriptionType,
      })
    })
    .catch(err => {
      dispatch({
        type: EDIT_USER_SUBSCRIPTION_FAIL,
        payload: err.response,
      })
    })
}
/*

export const editUserSubscription = (id, changes) => dispatch => {
  dispatch({ type: EDIT_USER_SUBSCRIPTION_START })
  return axiosWithAuth()
    .put(`${baseURL}${id} `, changes)
    .then(res => {
      dispatch({ type: EDIT_USER_SUBSCRIPTION_SUCCESS, payload: changes })
    })
    .catch(err => {
      dispatch({ type: EDIT_USER_SUBSCRIPTION_FAIL, payload: err.response })
    })
}
*/