import styled from 'styled-components'

export const Wrapper = styled.div`
  .section {
    height: auto;
    margin-top: 20vh;
    margin-bottom: 5vh;

    .about-us {
      display: flex;
      justify-content: center;
      margin-top: 2rem;

      .text {
        justify-content: center;
        text-align: justify;
        max-width: 1500px;
      }
    }
  }
`;
