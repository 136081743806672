import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { SERVER_URI } from 'config'
const getFormattedAmount = (amount) => {
  let amountToFormat = amount
  let numberFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    currencyDisplay: 'symbol',
  })
  let parts = numberFormat.formatToParts(amountToFormat)
  let zeroDecimalCurrency = true
  for (let part of parts) {
    if (part.type === 'decimal') {
      zeroDecimalCurrency = false
    }
  }

  amountToFormat = zeroDecimalCurrency ? amount : amount / 100
  let formattedAmount = numberFormat.format(amountToFormat)
  console.log(formattedAmount)

  return formattedAmount
}

const getDateStringFromUnixTimestamp = (date) => {
  let nextPaymentAttemptDate = new Date(date * 1000)
  let day = nextPaymentAttemptDate.getDate()
  let month = nextPaymentAttemptDate.getMonth() + 1
  let year = nextPaymentAttemptDate.getFullYear()

  return month + '/' + day + '/' + year
}

const PriceChangeForm = ({
  customerId,
  subscriptionId,
  currentProductSelected,
  newProductSelected,
  setShowChangePriceForm,
  setSelectedProduct,
}) => {
  let [ invoicePreview, setInvoicePreview ] = useState({})

  useEffect(() => {
    async function fetchData() {
      const response = await fetch(`${SERVER_URI}/api/stripe/retrieve-upcoming-invoice`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          customerId: customerId,
          subscriptionId: subscriptionId,
          newPriceId: newProductSelected.toUpperCase(),
        })
      })
      const responseBody = await response.json()
      setInvoicePreview(responseBody)
    }
    fetchData()
  }, [customerId, subscriptionId, newProductSelected])

  function confirmPriceChange() {
    return fetch(`${SERVER_URI}/api/stripe/update-subscription`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        subscriptionId: subscriptionId,
        newPriceId: newProductSelected.toUpperCase()
      })
    })
      .then((response) => {
        return response.json()
      })
      .then((result) => {
        setSelectedProduct(newProductSelected)
        setShowChangePriceForm(false)
      })
  }

  function cancelPriceChange() {
    setShowChangePriceForm(false)
  }

  return (
    <div>
      {newProductSelected !== currentProductSelected ? (
        <div>
          <div>
            <p>Current Price</p>
            <p>{currentProductSelected}</p>
          </div>
          <div>
            <p>New Price</p>
            <p>{newProductSelected}</p>
          </div>

          <div>
          <p>
            You will be charged {console.log(invoicePreview)}
            {(invoicePreview &&
              getFormattedAmount(invoicePreview.amount_due)) ||
              ''}{' '}
            on{' '}
            <span>
              {(invoicePreview.next_payment_attempt &&
                getDateStringFromUnixTimestamp(
                  invoicePreview.next_payment_attempt
                )) ||
                ''}
            </span>
            </p>
            <button
              onClick={() => confirmPriceChange()}
              type="submit"
            >
              Confirm Change
            </button>
            <button
              onClick={() => cancelPriceChange()}
              type="submit"
            >
              Cancel
            </button>
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default withRouter(PriceChangeForm)