import styled from 'styled-components'

export const Wrapper = styled.div`


    .registerWrap{
      align-items: center;
      justify-content: center;
      display: flex;
      background: radial-gradient( #fdffff 60%, #dcdbff 99% ); 
      /*
      background: linear-gradient(#e2f0ff 0%, #fdffff 10%, #fdffff 90%, #e2f0ff 100% );
      */
    }

    .register{
      margin-top: 120px;
      margin-bottom: 60px;
      min-width: 325px;
 }

    .cardForm{
      position: relative;
      display: flex;
      flex-direction: column;
      word-wrap: break-word;
      background-color: #fbfbff;
      background-clip: border-box;
      padding: 1rem;
      border: 10px solid;
      border-image-slice: 1;
      border-width: 2.5px;
      border-image-source: conic-gradient( #B993ee, #7c9eff, #befdff, #B993ee);
 }
    .cardBody{
      flex: 1 1 auto;
      min-height: 1px;
      padding: 1.25rem;
 }
    .cardLabel{
      display: inline-block;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
 }
    .cardInput{
      display: block;
      width: 100%;
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }

    .cardButton{
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      cursor: pointer;
      padding: 0.375rem 0.75rem;
      height: 2.5rem;
      border: none;
      border-radius: .28571429rem;
      margin-top: 10px;
      color: white;
      font-size: 18px;
      background: linear-gradient(90deg, #Ede0f7 0%, #B993ee 100%);
      width: 75%;
    }
    .cardButton:hover {
      background: linear-gradient(90deg, #e2ccf2 0%, #9d68e7 100%);
    }
    .titletwo{
      margin-top: 1rem;
    }
    .forgot-btn{
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      cursor: pointer;
      padding: 0.375rem 0.75rem;
      height: 1.5rem;
      border: none;
      border-radius: .28571429rem;
      margin-top: 10px;
      color: white;
      font-size: 12px;
      background: linear-gradient(90deg, #Ede0f7 0%, #B993ee 100%);
      width: 50%;
    }
    .types {
      /* border: 2px dashed springgreen; */
      align-content: center;

      .ui.checkbox {
        margin: 15px;
    }
`;
