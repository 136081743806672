import styled from 'styled-components'


export const Wrapper = styled.div`



.section {
    padding-top: 100px;
    padding-bottom: 50px;
    height: 100%;
    width: 100%;
  }
  .reset{
    padding-bottom: 20px;
  }
  .submit{
      padding-top: 3px;
      padding-bottom: 3px;
      cursor: pointer;
      width: 30%;
      max-width: 300px;
      background: linear-gradient(90deg, #Ede0f7 0%, #B993ee 100%);
      color: white;
      text-shadow: 1px 1px 2px #959597;
      font-size: 15px;
      border: none;
      border-radius: .7rem;
  }
  .submit:hover {
    background: linear-gradient(90deg, #e2ccf2 0%, #9d68e7 100%);
  }
`