import styled from 'styled-components'

export const Wrapper = styled.div`
  .section {
    display: block;
    margin-top: 4.4rem;
    margin-bottom: 80px;

    .locked {
      opacity: 0.5;
      pointer-events: none; /* Disables all interactions */
    }
  
    .unlocked {
        opacity: 1;
        pointer-events: auto;
    }

    .toplink {
      display: flex;
      align-items: center;
      justify-content: start;
      column-gap: 1%;
      margin-top: 7rem;
      margin-left: 5%;
      cursor: pointer;
      width: 100%;


      .ui.button {
        margin-top: 2rem;
        background: linear-gradient(90deg, #Ede0f7 0%, #B993ee 100%);
        color: white;
        text-shadow: 1px 1px 2px #959597;
        font-size: 17px;
        border: none;
        border-radius: .7rem;

        .link {
          color: white;
        }
      }
    }
    .ui.button:hover {
      transform: translateY(-3px);
      box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.15);
    }
    

    .paymentMethodBtn {
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: 500px;
      min-width: 200px;
      width: 50%;
    }
      .ui.button {
        margin-top: 2rem;
        background-color: #abe4fc;
        color: white;
        text-shadow: 1px 1px 2px #959597;
        font-size: 17px;
        border: none;
        border-radius: .7rem;

        .link {
          color: white;
        }
      }
    }
    .ui.button:hover {
      transform: translateY(-3px);
      box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.15);
    }
      
      .Payment-Box{
      justify-content: center;
      padding: 2%;
      padding-right: 5%;
      padding-left: 5%;
      margin-top: 10px;
      margin-bottom: 10px;
    }
    @media (min-width: 600px) {
      .Payment-Box{
        display: flex;
        padding-right: 10%;
        padding-left: 10%;
      }
}
    .Payment-Form{
      width: 100%;
      min-width: auto;
    }

    .Payment-Input{
      justify-content: center;
      display: grid;
      width: auto;
      padding-left: 5%;
      padding-right: 5%;
      border: 5px solid;
      border-image-slice: 1;
      border-width: 2px;
      border-image-source: conic-gradient( #B993ee, #7c9eff, #befdff, #B993ee);
      background: radial-gradient( #fdffff 75%, #dcdbff 99% );
      align-items: center;
    }
    @media (min-width: 750px) {
      .Payment-Input { grid-template-columns: repeat(3, 1fr); }
    }

    input[type="number"]{
      padding: 10px;
      margin-top: 10px;
      border: 2px solid;
      border-image-slice: 1;
      border-width: 2px;
      border-image-source: conic-gradient( #B993ee, #7c9eff, #befdff, #B993ee);
    }

    label{
      font-size: 18px;
    }

    input[type="date"]{
      padding: 10px;
      margin-top: 10px;
      border: 2px solid;
      border-image-slice: 1;
      border-width: 2px;
      border-image-source: conic-gradient( #B993ee, #7c9eff, #befdff, #B993ee);
    }

    select[name="creator"]{
      padding: 10px;
      margin-top: 10px;
      border: 2px solid;
      border-image-slice: 1;
      border-width: 2px;
      border-image-source: conic-gradient( #B993ee, #7c9eff, #befdff, #B993ee);
    }

    .Price-Input{
      padding-top: 10%;
      padding-left: 5%;
      padding-right: 5%;
      padding-bottom: 10%;
    }
}
    .Username-Input{
      padding-top: 10%;
      padding-left: 5%;
      padding-right: 5%;
      padding-bottom: 10%;
    }
    .Date-Input{
      padding-top: 10%;
      padding-left: 5%;
      padding-right: 5%;
      padding-bottom: 10%;
      display: block;
    }
}
}
  .Submit-Button{
    display: block;
    margin-top: 20px;
    align-items: center;
  }

    .Submit-Button-Input{
      background: linear-gradient(90deg, #Ede0f7 0%, #B993ee 100%);
      color: white;
      text-shadow: 1px 1px 2px #959597;
      font-size: 17px;
      border: none;
      border-radius: .7rem;
      cursor: pointer;
      width: 100px;
      height: 3rem;
    }
  }
  .Submit-Button-Input:hover {
    transform: translateY(-3px);
    box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.15);
  }

    .Payment-Instructions {
      display: flex;
      word-spacing: normal;
      justify-content: center;
      padding-left: 10%;
      padding-right: 10%;

      margin-top: 2rem;
      /* border: 2px dashed purple; */

      .text {
        justify-content: center;
        /* border: 4px dotted blue; */
        font-size: 18px;
        text-align: justify;
        letter-spacing: 1px;
        width: 500px;
      }
    }
  }

  .payment-bottom{
    padding-top: 5px;
    border-bottom: 4px solid #B993ee;
    width: 100px;
    margin-right: auto;
    margin-left: auto;
  }
  .paymentTotal label{
    font-size: 18px;
  }

  body {
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
    display: flex;
    justify-content: center;
    align-content: center;
    height: 100vh;
    width: 100vw;
  }
  form {
    width: 30vw;
    min-width: 500px;
    align-self: center;
    box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
      0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
    border-radius: 7px;
    padding: 40px;
  }
  #payment-message {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    padding-top: 12px;
    text-align: center;
  }
  
  #payment-element {
    margin-bottom: 24px;
  }
  
  /* Buttons and links */
  button {
    background: #5469d4;
    font-family: Arial, sans-serif;
    color: #ffffff;
    border-radius: 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
  }
  
  button:hover {
    filter: contrast(115%);
  }
  
  button:disabled {
    opacity: 0.5;
    cursor: default;
  }
  
  /* spinner/processing state, errors */
  .spinner,
  .spinner:before,
  .spinner:after {
    border-radius: 50%;
  }
  
  .spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  
  .spinner:before,
  .spinner:after {
    position: absolute;
    content: '';
  }
  
  .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #5469d4;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
  }
  
  .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #5469d4;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
  }
  
  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @media only screen and (max-width: 600px) {
    form {
      width: 80vw;
      min-width: initial;
    }
  }

`;
