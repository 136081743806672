import React, { useEffect, useState } from 'react';
import Dropdown from 'react-dropdown';
import { DropdownButton } from 'react-bootstrap';
import { Turn as Hamburger } from 'hamburger-react';
import { slide as Menu } from 'react-burger-menu'
import { useSelector } from 'react-redux';
import { NavLink, useHistory, useLocation, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types'
import { Route } from 'react-router-dom'

import logoDark from 'assets/images/endorse-logo-blue.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Wrapper } from './NavBarStyles'

import './NavBarStyles.css'
import './NavBarStyles.js'

const Navigation = (props) => {
  const [ windowDimension, setWindowDimension ] = useState(null)
  const [ show, setShow ] = useState(false)
  const state = useSelector((state) => state)
  const user = state.auth.user.username
  // console.log(state, 'this is state home');

  // const url = window.location.href
  // console.log('### NAVBAR COMPONENT. THIS IS URL: ', url);

  console.log(props.history.location.pathname, 'propspsps');

  // if (props.history.location.pathname === '/stripe-prices') {
  //   console.log(true)
  // } else {
  //   console.log(false);
  // }

  useEffect(() => {
    setWindowDimension(window.innerWidth)
  }, [])

  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const isMobile = windowDimension <= 640

  const handleLogout = () => {
    localStorage.clear('token')
  }

  const onClick = () => {
    if (window.location.pathname === '/stripe-prices') {
      console.log(true);
      localStorage.removeItem('stripeID')
    } else {
      console.log(false);
    }
  }
  class MobileMenu extends React.Component {
    showSettings (event) {
      event.preventDefault();
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.bm-menu')) {
        handleOnClose();
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  
  const isMenuOpen = (state) => {
    setShow(state.isOpen);
  };
  
  const handleOnClose = () => {
    setShow(false);
  };
  
  return (


    <Wrapper>

    <div className='site-header'>
      <div className='container'>
        <div className='row'>

          <div className='col'>

            <div className='col navigation-col'>
              <div className='menu-wrap'>

              <ul className='header-btns d-flex justify-content-end align-items-center list-unstyled'
          style={{ listStyle: 'none'}}>


        <div className= "mobile-menu">
        

            {user ? null :
          <Menu right isOpen={show} onClose={handleOnClose} onStateChange={isMenuOpen}>
              <a><NavLink to="/new-user" onClick={onClick} className="mobile-link"style={{ color: '#B993ee', lineHeight:'30px' }}>Learn More</NavLink></a>
              <a><NavLink to="/blog" onClick={onClick} className="mobile-link"style={{ color: '#B993ee' }}>Blog</NavLink></a>

              <a><NavLink to="/register" onClick={onClick} className="mobile-link"style={{ color: '#B993ee', lineHeight:'30px' }}>Create Account</NavLink></a>
              <a><NavLink to="/login" onClick={onClick} className="mobile-link"style={{ color: '#B993ee' }}>Login</NavLink></a>
          </Menu>
        }

        {!user ? null :
          <Menu right isOpen={show} onClose={handleOnClose} onStateChange={isMenuOpen}>
            <a><NavLink to='/about' onClick={onClick} className='mobile-link'style={{ color: '#B993ee' }}>About</NavLink></a>
            <a><NavLink to="/blog" onClick={onClick} className="mobile-link"style={{ color: '#B993ee'}}>Blog</NavLink></a>
            <a><NavLink to="/dashboard" onClick={onClick} className="mobile-link"style={{ color: '#B993ee'}}>Dashboard</NavLink></a>
            <a><NavLink to="/login" onClick={handleLogout} className="mobile-link"style={{ color: '#B993ee'}}>Logout  <FontAwesomeIcon icon={["fas", "sign-out-alt"]} style={{ fontSize: '.7em', verticalAlign: 'middle' }}/></NavLink></a>
          </Menu>
        }
          </div>
            <div className= "account-btns">
            {user ? null :
              <li>
              <NavLink to='/register' className='signup' id='register'>
                <FontAwesomeIcon icon='user' className='reg-icon'></FontAwesomeIcon>
                Create Account
              </NavLink>
            </li>
            }

            {user ? null :
              <li>
                <NavLink to="/login" onClick={onClick} className="login">
                  <FontAwesomeIcon icon='unlock' className='login-icon'></FontAwesomeIcon>
                  Sign In
                </NavLink>
              </li>
            }
          </div>
          </ul>





              <Route exact path="/">
                <ul className='menu'>
                  <div className='main-menu'>
                  {user ? <li><NavLink to="/dashboard" onClick={onClick} className="link" activeClassName='active'>Dashboard</NavLink></li> :
                  <li><NavLink to="/new-user" onClick={onClick} className="link" activeClassName='active'>Learn More</NavLink></li>
                  }
                  <li><NavLink to='/about' onClick={onClick} className='link' activeClassName='active' >About</NavLink></li>
                  <li><NavLink to="/blog" onClick={onClick} className="link" activeClassName='active' >Blog</NavLink></li>
                  
                  
                  </div>
                </ul>
                </Route>

              </div>




              <div className='site-logo'>
                <NavLink to='/' activeClassName='active' >
                  <img src={logoDark} />
                </NavLink>
              </div>
            </div>
          </div>


          <div className='col'>

          </div>


        </div>
      </div>
    </div>


     </Wrapper>


  )
}

Navigation.propTypes = {
  pathname: PropTypes.string
}

export default withRouter(Navigation);
