import { combineReducers } from 'redux'
import { reducer as asset } from './asset'
import { reducer as auth } from './auth'
import { reducer as post } from './blog'
import { reducer as profile } from './profile'
import { reducer as user } from './user'
import { reducer as search } from './search'

export default combineReducers({
  asset,
  auth,
  post,
  profile,
  user,
  search
})