const client = require('contentful').createClient({
  space: 'wkwy7j9nlu5g',
  accessToken: 'uiEfb4wUQAuoZ01D8ArZ3HsV1m9iaBVdEFxMlluDQ7w'
})

const getBlogPosts = () => client.getEntries().then((response) => response.items)

const getSinglePost = (slug) => 
  client.getEntries({
    'fields.slug': slug,
    content_type: 'blogPost'
  })
  .then((response) => response.items)
  
export { getBlogPosts, getSinglePost }