import { useEffect, useState } from 'react'
import { getSinglePost } from '../contenful'

// const useIsMounted = () => {
//   const isMounted = useRef(false)
//   useEffect(() => {
//     isMounted.current = true
//     return () => isMounted.current = false
//   }, [])
//   return useCallback(() => isMounted.current, [])
// }

// const useAsyncState = (defaultValue) => {
//   const isMounted = useIsMounted();
//   const [value, setRawValue] = useState(defaultValue);
//   const setValue = useCallback((newValue) => {
//     if (isMounted()) {
//       setRawValue(newValue);
//     }
//   }, []);
//   return [value, setValue];
// };

export default function useSinglePost(slug) {
  const promise = getSinglePost(slug)
  // console.log(slug, 'this is the slug');

  const [ post, setPost ] = useState(null)
  const [ isLoading, setLoading ] = useState(true)

  // const [ post, setPost ] = useAsyncState([])
  // const [ isLoading, setLoading ] = useAsyncState([])

  useEffect(() => {
    
    promise.then(result => {
      setPost(result[0].fields)
      setLoading(false)
    })
    .catch(error => {
      console.log(error, 'there was some error')
    })
  }, [])

  return [ post, isLoading ]
}
