// import { FETCH_POSTS, FETCH_POST } from 'redux/actions/types'

// const initialState = { all: [], post: null }

// export const reducer = (state = initialState, action) => {
//   switch (action.type) {
//     case FETCH_POSTS:
//       return { ...state, all: action.payload.data }
//     case FETCH_POST:
//       return { ...state, post: action.payload.data }
//     default:
//       return state
//   }
// }

import { BLOG_LOADING, LOAD_BLOG_SUCCESS } from 'redux/actions/types'

const initialState = {
  blog: {
    loading: false,
    posts: []
  }
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case BLOG_LOADING:
      return { ...state, loading: action.isLoading }
    case LOAD_BLOG_SUCCESS:
      return { ...state, loading: false }
    default:
      return state
  }
}