import React, {useRef, useEffect, useState} from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Form, Card, Button, Alert } from 'react-bootstrap'
import './NewUserStyles.css'
import { Wrapper } from './NewUserStyles.js'



const NewUser = (props) => {

return (

<wrapper> 


        <section>

            < div className="NewUserIntro" >

                <h1> New Users</h1>

            </div>
            <div className="NewUserOptions">
                <Card>
                    <h1>Brand</h1>
                   
                   <p>Users looking for promotion will want a Brand account. Whether you have worked with creators or not Endorse makes it easy.</p> 
                
                    <a href='/register'>Sign Up</a>
                    
                </Card>

                <Card>
                <h1>Creator</h1>

                <p>Users looking to create paid promotions will want a Creator account. Collaboration and Payouts are effortless. </p>

                <a href='/register'>Sign Up</a>
                </Card>
            </div>
        </section>

        </wrapper>

)
}


export default NewUser;
