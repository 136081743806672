import React, { useEffect, useState, Dropdown } from 'react';
import { DropdownButton } from 'react-bootstrap';
import { getAllBrands } from 'redux/actions/searchActions'
import { getAllBrandsById } from 'redux/actions/searchActions'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Wrapper } from 'components/Dashboard/BrandSearch/BrandSearchStyles'
import { Card, Icon, Button } from 'semantic-ui-react'

const AllBrands = () => {
  const dispatch = useDispatch()
  const state = useSelector((state) => state)
  const brands = state.search.brands.data


  useEffect(() => {
    dispatch(getAllBrands());
  }, [dispatch])



        const [error, setError] = useState(null);
        const [isLoaded, setIsLoaded] = useState(false);

        //     set search query to empty string
        const [q, setQ] = useState("");
        //     set search parameters
        //     we only what to search countries by capital and name
        //     this list can be longer if you want
        //     you can search countries even by their population
        // just add it to this array
        const [searchParam] = useState(["username"]);


                function search(brands) {
                return (brands || []).filter((brand) => {
                    return searchParam.some((newBrand) => {
                        return (
                            brand[newBrand]
                                .toString()
                                .toLowerCase()
                                .indexOf(q.toLowerCase()) > -1
                        );
                    });
                });
            }

  return (
    <Wrapper>
    <div className='search-section'>
    <div className='toplink'>
      <a href='/dashboard'>
        <Button>
        <div className="link">
        <Icon name='chevron left'></Icon>
        Dashboard</div>
        </Button>
        </a>

     </div>
    <h1>Find Brands to work with!</h1>
    <div className="search-wrapper">
                      <label htmlFor="search-form">
                          <input
                              type="search"
                              name="search-form"
                              id="search-form"
                              className="search-input"
                              placeholder="Search by Username"
                              value={q}
                              onChange={(e) => setQ(e.target.value)}
                          />
                      </label>
                  </div>
      <div className='brands-list'>
        {search(brands).map((brand, i) => (
              <div className='brand' key={i} brand={brand}>
                <Card>
                  <Card.Content>
                  <img style={{ width: '120px', height: '120px'}} src={brand.image} alt='avatar' />
                  <h2>{brand.username}</h2>
                  <p>{brand.bio}</p>
                  <Link to={`/brand/user/${brand.id}`}>
                    <Button>View Profile</Button>
                  </Link>
                  </Card.Content>
                </Card>
              </div>
            ))
        }

      </div>

    </div>
    </Wrapper>

  )
}

export default AllBrands
