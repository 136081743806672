import styled from 'styled-components'

export const Wrapper = styled.div`

  .container {
    /* border: 2px dotted forestgreen; */
    margin-top: 8rem;
    margin-bottom: 1rem;
    height: 100%;

    h1 {
      margin-top: 10px;
      margin-bottom: 0;
    }

    .toplink {
      display: flex;
      align-items: center;
      justify-content: start;
      margin-top: 7rem;
      margin-left: 5%;
      width: 100%;

    .normal-btn {
      color: white;
      background: linear-gradient(90deg, #Ede0f7 0%, #B993ee 100%);
      font-size: 17px;
      border: none;
      border-radius: .7rem;
      width: 175px;
      cursor: pointer;
    }
}
    .top-btns {


      .blue-btn {
        background-color: #abe4fc;
        color: white;
        margin: 2px 1px;
        width: 70%;
        max-width: 250px;

        .link {
          text-decoration: none;

          a {
            color: white;
            text-decoration: none;
          }
        }
      }
    }

    .showing {
      /* border: 2px double blue; */
      margin-top: 20px;
      color: #B993ee;
      font-weight: bold;
    }

    .chatbox-container {
      /* border: 2px double purple; */
      /* margin-top: 20px; */
    }

    .bottom-btns {
      padding-bottom: 30px;

      .ui.button {
        background-color: #abe4fc;
        color: white;
        width: 70%;
        max-width: 250px;

        .link {
          color: white;
        }
      }
    }
  }
`;
