// authentication
export const SET_ERROR = 'SET_ERROR'
export const RESET_ERROR = 'RESET_ERROR'
export const SET_TOKEN = 'SET_TOKEN'
export const SET_USER = 'SET_USER'
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR'
export const RESET_PASSWORD_MESSAGE = 'RESET_PASSWORD_MESSAGE'

// blog
export const FETCH_POSTS = 'FETCH_POSTS'
export const FETCH_POST = 'FETCH_POST'
export const FETCH_ASSET = 'FETCH_ASSET'
export const BLOG_LOADING = 'BLOG LOADING'
export const LOAD_BLOG_SUCCESS = 'LOAD_BLOG_SUCCESS'

// profile
export const GET_PROFILE_START = 'GET_PROFILE_START'
export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS'
export const GET_PROFILE_FAIL = 'GET_PROFILE_FAIL'

export const EDIT_PROFILE_START = 'EDIT_PROFILE_START'
export const EDIT_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS'
export const EDIT_PROFILE_FAIL = 'EDIT_PROFILE_FAIL'

export const EDIT_PIC_START = 'EDIT_PIC_START'
export const EDIT_PIC_SUCCESS = 'EDIT_PIC_SUCCESS'
export const EDIT_PIC_FAIL = 'EDIT_PIC_FAIL'

// users
export const GET_USER_DATA_START = "GET_USER_DATA_START"
export const GET_USER_DATA_SUCCESS = "GET_USER_DATA_SUCCESS"
export const GET_USER_DATA_FAIL = "GET_USER_DATA_FAIL"

export const GET_SPECIFIC_USER_START = "GET_SPECIFIC_USER_START"
export const GET_SPECIFIC_USER_SUCCESS = "GET_SPECIFIC_USER_SUCCESS"
export const GET_SPECIFIC_USER_FAIL = "GET_SPECIFIC_USER_FAIL"

export const EDIT_USER_START = "EDIT_USER_START"
export const EDIT_USER_SUCCESS = "EDIT_USER_SUCCESS"
export const EDIT_USER_FAIL = "EDIT_USER_FAIL"

export const EDIT_USER_SUBSCRIPTION_START = "EDIT_USER_SUBSCRIPTION_START"
export const EDIT_USER_SUBSCRIPTION_SUCCESS = "EDIT_USER_SUBSCRIPTION_SUCCESS"
export const EDIT_USER_SUBSCRIPTION_FAIL = "EDIT_USER_SUBSCRIPTION_FAIL"

export const GET_ALL_USERS_START = "GET_USER_DATA_START"
export const GET_ALL_USERS_SUCCESS = "GET_USER_DATA_SUCCESS"
export const GET_ALL_USERS_FAIL = "GET_USER_DATA_FAIL"

// get all brands
export const GET_ALL_BRANDS_START = 'GET_ALL_BRANDS_START'
export const GET_ALL_BRANDS_SUCCESS = 'GET_ALL_BRANDS_SUCCESS'
export const GET_ALL_BRANDS_FAIL = 'GET_ALL_BRANDS_FAIL'

//get single brand
export const GET_BRAND_START = 'GET_BRAND_START'
export const GET_BRAND_SUCCESS = 'GET_BRAND_SUCCESS'
export const GET_BRAND_FAIL = 'GET_BRAND_FAIL'

// get all influencers
export const GET_ALL_INFLUENCERS_START = 'GET_ALL_INFLUENCERS_START'
export const GET_ALL_INFLUENCERS_SUCCESS = 'GET_ALL_INFLUENCERS_SUCCESS'
export const GET_ALL_INFLUENCERS_FAIL = 'GET_ALL_INFLUENCERS_FAIL'

//get single influencer
export const GET_INFLUENCER_START = 'GET_INFLUENCER_START'
export const GET_INFLUENCER_SUCCESS = 'GET_INFLUENCER_SUCCESS'
export const GET_INFLUENCER_FAIL = 'GET_INFLUENCER_FAIL'

// get stripe ID
export const GET_PENDING_TASKS_START = 'GET_PENDING-TASKS_START'
export const GET_PENDING_TASKS_SUCCESS = 'GET_PENDING_TASKS_SUCCESS'
export const GET_PENDING_TASKS_FAIL = 'GET_PENDING_TASKS_FAIL'

// get brand payouts
export const GET_PENDING_PAYOUTS_START = 'GET_PENDING-PAYOUTS_START'
export const GET_PENDING_PAYOUTS_SUCCESS = 'GET_PENDING_PAYOUTS_SUCCESS'
export const GET_PENDING_PAYOUTS_FAIL = 'GET_PENDING_PAYOUTS_FAIL'

export const GET_COMPLETE_PAYOUTS_START = 'GET_COMPLETE_PAYOUTS_START'
export const GET_COMPLETE_PAYOUTS_SUCCESS = 'GET_COMPLETE_PAYOUTS_SUCCESS'
export const GET_COMPLETE_PAYOUTS_FAIL = 'GET_COMPLETE_PAYOUTS_FAIL'
