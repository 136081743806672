import React, {useRef, useEffect, useState} from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Form, Card, Button, Alert } from 'react-bootstrap'
import { Wrapper } from 'auth/NewRegisterStyles'
import { Modal, Input, message } from 'antd'
import {Link, useHistory} from 'react-router-dom'
import { connect, useDispatch, useSelector } from 'react-redux'
import { UserOutlined } from '@ant-design/icons'
import history from 'utils/history'
import { SET_ERROR } from 'redux/actions/types';
import { loginRequest, resetError, forgotPassword } from 'redux/actions/authActions'
import ForgotPassword  from 'auth/ForgotPassword.js'
import "auth/LoginStyles.css"

const Login = (props) => {

  //console.log(state, 'this is some state');
  //console.log(state.auth.error, 'this is SET_ERROR')
  const [isSuccessfulLogin, setIsSuccessfulLogin] = useState(false);
  const state = useSelector(state => state);
  const [errors, setErrors] = useState('');
  const [error, setError] = useState('');

  const dispatch = useDispatch()

  const [ input, setInput ] = useState({
    username: '',
    password: ''
  })


  const history = useHistory()
  const [loading, setLoading] = useState(false);

  const onChange = e => {
    setInput({ ...input, [e.target.name]: e.target.value })
    setErrors('');
  }


const onSubmit = async (e) => {
  e.preventDefault();

  try {
    setLoading(true);
  const message = await props.loginRequest(input, history);

  
  
  if (message === 'Succesfully logged in 🎉') {
    setErrors('');
    setIsSuccessfulLogin(true);
    setTimeout(() => {
      history.push('/dashboard');
    }, 700); 
  } else if (message === 'YOU SHALL NOT PASS 🧙🏻‍♂️'){
    setLoading(false);
    setErrors("username or password is incorrect");
  } else {
    setErrors("Something went wrong, please try again");
  }
} catch (error) {
  setErrors("Something went wrong, please try again");
} finally {
  setLoading(false); // Stop loading animation
}
};

  const [forgotPassword, setForgotPassword] = useState(false);

  return (


  <Wrapper>
  <div className= "registerWrap">
  <div className= "register">
  {
  !forgotPassword ? (
    <> 
  <Card className={`cardForm ${isSuccessfulLogin ? 'slide-up-and-out' : ''}`}>
    <Card.Body className="cardBody">
      <h2 className="title"> Log In </h2>
      {errors && <Alert variant="danger">{errors}</Alert>}
      <Form onSubmit={onSubmit}>
        <Form.Group id="email">
         <Form.Label className= "cardLabel">Username</Form.Label>
         <Form.Control className= "cardInput" type="text" name="username" value={input.username} onChange={onChange} required />
        </Form.Group>
        <Form.Group id="password">
         <Form.Label className= "cardLabel">Password</Form.Label>
         <Form.Control className= "cardInput" type="text" name="password" value={input.password} onChange={onChange} required />
        </Form.Group>
        <Button disabled={loading} className="cardButton" type="submit">{loading ? 'Loading...' : 'Log In'}</Button>
      </Form>
      </Card.Body>
    </Card>
      <div className="titletwo">
      Need an Account? <Link to="/Register"> Sign Up </Link>
      </div>
      <div className="titletwo">
      <Button className='forgot-btn' onClick={() => setForgotPassword(true)}>Forgot Password?</Button>
      </div>
      </>
            ) : (
              <ForgotPassword goBack={() => setForgotPassword(false)} />
            )
          }
    </div>
    </div>
    </Wrapper>

  )
}




const mapStateToProps = state => ({
  error: state.auth.error
});

export default connect(mapStateToProps, { loginRequest })(Login);
