import styled from 'styled-components'

export const Wrapper = styled.div`
.search-section {
  height: 100%;
  margin-top: 6.4rem;
  margin-bottom: 4.4rem;
}
.toplink {
  /* border: 1px dotted black; */
  display: block;
  margin-top: 7rem;
  margin-left: 2rem;
  width: 160px;

  .ui.button {
    margin-top: 2rem;
    background: linear-gradient(90deg, #Ede0f7 0%, #B993ee 100%);
    color: white;
    text-shadow: 1px 1px 2px #959597;
    font-size: 17px;
    border: none;
    border-radius: .7rem;

    .link {
      color: white;
    }
  }
}

.ui.button:hover {
  transform: translateY(-3px);
  box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.15);
}

h1 {
  padding-top: 20px;
}

.influencer-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  .influencer {
    display: flex;
    justify-content: center;
  }

  .ui.card {
    align-items: center;
    width: 450px;
    margin-top: 2rem;
    padding-bottom: 1rem;
    border: 5px solid;
    border-image-slice: 1;
    border-width: 1.5px;
    border-image-source: conic-gradient( #B993ee, #7c9eff, #befdff, #B993ee);
  }
  .ui.button {
    background: linear-gradient(90deg, #Ede0f7 0%, #B993ee 100%);
    color: white;
    text-shadow: 1px 1px 2px #959597;
  }
}




`;
