import styled from 'styled-components'

export const Wrapper = styled.div`
  .top {
    /* border: 2px dotted purple; */
    /* margin: 15px; */

    .product {
      /* border: 2px dashed orange; */
      margin-top: 1rem;
      margin-left: 1rem;
      margin-right: 1rem;

      p {
        text-align: justify;
      }
    }
    .price-info {
      margin-bottom: 1rem;
    }
    .price {
      /* border: 2px dashed green; */
      /* margin-bottom: 5px; */
    }
  }

  .bottom {
    /* border: 2px dotted red; */
    margin-bottom: 1rem;

    .ui.button {
      background: #6CB7D9;
      color: white;
    }
  }
`;