// import Form from 'antd/lib/form/Form'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import {
  getMyProfile,
  editMyProfile,
  editAvatar,
} from 'redux/actions/profileActions'
import { Card, Icon } from 'semantic-ui-react'
import { Wrapper } from 'components/MyProfile/myProfileStyles'
// import { lang } from 'moment'
import { Button } from 'semantic-ui-react'
// axios.defaults.withCredentials = false
// import { editUser } from 'redux/actions/userActions'

// import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SERVER_URI } from 'config'
import axios from 'axios'
import { getUserById } from 'redux/actions/userActions';
import QRCode from "react-qr-code";
//import { json } from 'body-parser'
// import { fab, faTwitter } from '@fortawesome/free-brands-svg-icons'

// library.add(fab)


const MyProfile = props => {
  const dispatch = useDispatch()
  const state = useSelector(state => state)
  const id = state.auth.user.id
  const type = state.profile.myProfile.type
  const myProfile = state.profile.myProfile
  const name = state.auth.user.fullName
  const image = state.profile.myProfile.image
  const bio = state.profile.myProfile.bio
  const currentUserId = state.auth.user.id
  const instagramLink = state.profile.myProfile.instagramLink
  const twitterLink = state.profile.myProfile.twitterLink
  const tiktokLink = state.profile.myProfile.tiktokLink
  const twitchLink = state.profile.myProfile.twitchLink
  const youtubeLink = state.profile.myProfile.youtubeLink
  const otherLink = state.profile.myProfile.otherLink

  const stripeCustomer = state.user.person.stripeID
  const stripeSubscription = state.user.person.subscriptionType
  const stripeAccountId = localStorage.getItem('stripeAccount')
  const pendingEarnings = localStorage.getItem("pendingEarnings");
  console.log('### STATE HERE: ', state);


  const [ myProfileEdit, setMyProfileEdit ] = useState(true)
  const [newStripeAccount, setNewStripeAccount] = useState()
  const [stripeCustomerId, setStripeCustomerId] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const toggleEdit = () => {
    setMyProfileEdit(!myProfileEdit)
  }

  const [ changesPic, setChangesPic ] = useState({ image: image })
  const [ stripeLoginLink, setStripeLoginLink ] = useState('');
  const [ changes, setChanges ] = useState({
    image: changesPic,
    bio: '',
    instagramLink: '',
    twitterLink: '',
    tiktokLink: '',
    twitchLink: '',
    youtubeLink: '',
    otherLink: '',
  })

  useEffect(() => {
    dispatch(getUserById(id));
  }, [dispatch, id]);

  useEffect(() => {
    setNewStripeAccount(stripeAccountId)
    setStripeCustomerId(stripeCustomer)
    getConnectLoginLink();
    dispatch(getMyProfile(id))
  }, [dispatch, id])

  useEffect(() => {
    setChanges({
      image: myProfile.image,
      bio: bio,
      instagramLink: instagramLink,
      twitterLink: twitterLink,
      tiktokLink: tiktokLink,
      twitchLink: twitchLink,
      youtubeLink: youtubeLink,
      otherLink: otherLink,
    })
  }, [myProfile, bio, instagramLink, twitterLink])

  useEffect(() => {
    setChangesPic({
      image: MyProfile.image,
    })
  }, [myProfile.image])

  // const noToggleEdit = () => {
  //   setMyProfileEdit(myProfileEdit)
  // }

  const checkIfUserCanConnect = () => {

    }

    const createStripeAccount = async () => {
      setIsLoading(true)
      console.log('called: createStripeAccount');
        const account =  await axios.post(`${SERVER_URI}/api/stripe/create-account`, {
          email: state.auth.user.email,
          userId: state.auth.user.id,
        });

        const accountId = account.data.account.id;
        const accountLink = account.data.accountLink;
        console.log(account, 'this is the stripe account id', accountLink);

        setNewStripeAccount(accountId);

        localStorage.setItem('stripeAccount', accountId);
        localStorage.setItem('stripeAccountLink', accountLink);
         setIsLoading(false);
         window.open(accountLink, "_blank");
      }


    const getConnectLoginLink = async () => {
      const res = await axios.post(`${SERVER_URI}/api/stripe/connect-login`,{
        stripeAccountId : localStorage.getItem('stripeAccount')
      })

      console.log('### res.data', res.data.url);
      setStripeLoginLink(res.data.url);
    }





  const handleChange = e => {
    setChanges({ ...changes, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    dispatch(editMyProfile(id, changes)).then(() => {
      dispatch(getMyProfile(id))
      // alert('Successfully changed info')
    })
    toggleEdit()
  }

  // tried the handleSubmit below but that did not work either

  // const handleSubmit = e => {
  //   e.preventDefault()
  //   dispatch(editMyProfile(id, changes))
  //   toggleEdit()
  // }

  const handleImage = e => {
    setChangesPic({ ...changesPic, image: e.target.files[0] })
  }

  const handleImgSubmit = e => {
    e.preventDefault()
    const formData = new FormData()
    formData.append('image', changesPic.image)
    dispatch(editAvatar(id, formData)).then(() => {
      dispatch(getMyProfile(id))
      alert('Successfully updated new profile picture')
    })
  }


  // go to billing portal
  const handlePortal = (e) => {
    e.preventDefault()
    // console.log(id, 'JSJDHDHDHDHDHDHDH')
    fetch(`${SERVER_URI}/api/stripe/customer-portal`, {
      // mode: 'no-cors',
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: currentUserId
      })

    })
    .then((result) => {
      return result.json()
    })
    // Extract URL from response data
    .then((data) => {
      const url = data.url
      console.log('response is:', data)
      window.location = url
    })
    .catch((error) => {
      console.log(error.message, 'There was a dumb error')
    })
  }

  const [buttonText, setButtonText] = useState('Share Profile');
  
  const handleClick = () => {
    navigator.clipboard.writeText(`https://getendorse.com/public-profile/${state.auth.user.id}`)
      .then(() => {
        // Show "Link Copied" message and icon in button
        setButtonText('Link Copied');
        // Hide notification after 3 seconds
        setTimeout(() => setButtonText('Share Profile'), 3000);
      })
      .catch(err => {
        // Handle error scenario
        console.error('Failed to copy text: ', err);
        setButtonText('Failed to copy');
        setTimeout(() => setButtonText('Share Profile'), 3000);
      });
  };


  const [isCodeVisible, setIsCodeVisible] = useState(false);

  const showCode= e => {
  setIsCodeVisible(!isCodeVisible);
  }

  const renderSubscriptionButtons = () => {
    // Condition 1: Check if the user doesn't have a Stripe ID
  if (stripeCustomer === null) {
    return (
      <div className='buttons'>
        <Link to='/stripe-login'>
          <Button className='stripe-btn'>Create Subscription</Button>
        </Link>
      </div>
    );
  }
  
  // Condition 2: Check if the user's subscription is 'Basic' and needs an upgrade
  else if (stripeSubscription === 'Basic') {
    return (
      <div className='buttons'>
        <Link to='/stripe-prices'>
          <Button className='stripe-btn'>Upgrade Account</Button>
        </Link>
      </div>
    );
  }
  
  // Condition 3: For managing subscriptions (Users not covered by the first two conditions)
  else {
    return (
      <div className='buttons'>
        <Button className='stripe-btn' onClick={handlePortal}>Manage Subscription</Button>
      </div>
    );
  }
};
  
  return (
    <Wrapper>
    <div className='section'>
     <div className='toplink'>

          <a href='/dashboard'>
          <Button>
          <div className="link">
          <Icon name='chevron left'></Icon>
          Dashboard</div>
          </Button>
          </a>

          <div className='share-link'>
            <Button onClick={handleClick}>
              {buttonText}
              <FontAwesomeIcon icon={['fab', 'creative-commons-share']} style={{ paddingLeft: '1px' }} size='1x' />
            </Button>
          </div>

        <Button onClick={showCode}>
          <div className="link">QR Code</div>
        </Button>
            

      </div>



      {isCodeVisible && (
            <div style={{ height: "auto", margin: "0 auto", paddingTop: "20px", maxWidth: "50vw", maxHeight: "70vh", width: "100%" }}>
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", maxHeight: "70vh", width: "100%" }}
                value={'https://getendorse.com/public-profile/'+state.auth.user.id}
                viewBox={`0 0 256 256`}
              />
            </div>
          )}

      <div className='my-profile'>
        {myProfileEdit ? (
          <div className='profile-section'>
            <Card className='photo-buttons'>
              <Card.Content className='card-content'>
                <img
                  src={image}
                  alt={'profile pic'}
                  style={{
                    height: '150px',
                    width: '150px',
                    borderRadius: '50%',
                    // margin: "2% 70% 0 0%"
                  }}
                  className='image'
                />
                <Card.Header>{name}</Card.Header>
                <Card.Description className='about-section'>
                  <p>{bio}</p>
                </Card.Description>
                <div className='icon-links'>
                  {/* INSTAGRAM LINK */}
                  {instagramLink ? (
                    <p>
                    <a
                      className='link'
                      href={instagramLink}
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "none", color: "#6CB7D9" }}
                    >
                      <FontAwesomeIcon icon={['fab', 'instagram']} color='#6CB7D9' size='2x' />
                    </a>
                  </p>
                  ) : (

                    <></>

                  )}

                  {/* TWITTER LINK */}
                  {twitterLink ? (
                    <p>
                    <a
                      className='link'
                      href={twitterLink}
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "none", color: "#6CB7D9" }}
                    >
                      <FontAwesomeIcon icon={['fab', 'twitter']} color='#6CB7D9' size='2x' />
                    </a>
                  </p>
                  ) : (
                    <></>
                  )}

                  {/* TIKTOK LINK */}
                  {tiktokLink ? (
                    <p>
                    <a
                      className='link'
                      href={tiktokLink}
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "none", color: "#6CB7D9" }}
                    >
                      <FontAwesomeIcon icon={['fab', 'tiktok']} color='#6CB7D9' size='2x' />
                    </a>
                  </p>
                  ) : (
                    <></>
                  )}

                  {/* TWITCH LINK */}
                  {twitchLink ? (
                    <p>
                    <a
                      className='link'
                      href={twitchLink}
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "none", color: "#6CB7D9" }}
                    >
                      <FontAwesomeIcon icon={['fab', 'twitch']} color='#6CB7D9' size='2x' />
                    </a>
                  </p>
                  ) : (
                    <></>
                  )}

                  {/* YOUTUBE LINK */}
                  {youtubeLink ? (
                    <p>
                    <a
                      className='link'
                      href={youtubeLink}
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "none", color: "#6CB7D9" }}
                    >
                      <FontAwesomeIcon icon={['fab', 'youtube']} color='#6CB7D9' size='2x' />
                    </a>
                  </p>
                  ) : (
                    <></>
                  )}

                  {/* OTHER LINK */}
                  {otherLink ? (
                    <p>
                    <a
                      className='link'
                      href={otherLink}
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "none", color: "#6CB7D9" }}
                    >
                      <FontAwesomeIcon icon='user' color='#6CB7D9' size='2x' />
                    </a>
                  </p>
                  ) : (
                    <></>
                  )}
                </div>

                 <div className='buttons'>
                <Button type='submit' className='editProfile-btn' onClick={toggleEdit}>Edit My Profile</Button>
                </div>

                <div>
                        {type !== 'influencer' ? (
                          renderSubscriptionButtons()
                        ) : (<></>  )}
                      </div>
                            
            {type === 'influencer' ? (
                newStripeAccount !== "null" ? (
                    <div className='buttons'>
                    {Number(pendingEarnings) === 0 ? (
                      <a href={stripeLoginLink} className={'editProfile-btn'} target='_blank'>
                        <Button className='editProfile-btn'>
                          Payments Dashboard 
                        </Button>
                      </a>
                    ) : (
                      <h3>In order to access your earnings please complete your tasks, current tasks: {pendingEarnings}</h3>
                    )}
                  </div>
                ) : (
                  <div className="buttons">
                    {isLoading ? (
                      <h3>Please wait......</h3>
                    ) : (
                      <Button type='submit' className='editProfile-btn' onClick={createStripeAccount}>Setup Payments</Button>
                    )}
                  </div>
                )
              ) : null}
              </Card.Content>
            </Card> {/* end photo-buttons */}
          </div>
        ) : (
          <div className='edit-section'>
            <Card className='card-section'>
              <Card.Content>
                <img
                  alt='avatar'
                  src={image}
                  style={{
                    height: '120px',
                    width: '120px',
                    borderRadius: '50%',
                    // margin: '2% 70% 0 0%'
                  }}
                />
                <div className='inputs'>
                  <form onSubmit={handleImgSubmit} className='image-input'>
                    {/* <label htmlFor='myfile'>Choose New Photo:</label> */}
                    <div className='choose-photo'>
                    {/* <label htmlFor='myfile'>Photo</label> */}
                    <input
                      type='file'
                      onChange={handleImage}
                      name='image'
                      className='file'
                      // id='myfile'
                      // style={{ display: 'none'}}
                    />
                    </div>
                    <button className='label' type='submit'>Set as Image</button>
                  </form>

                  {/* THIS IS THE ABOUT ME FORM */}
                  <form onSubmit={handleSubmit} className='about-me'>
                    <label className='bio'>Bio</label>
                    <textarea
                      cols='120'
                      rows='8'
                      style={{ maxWidth: '100%'}}
                      className='textarea'
                      placeholder='Enter Bio'
                      value={changes.bio || ''}
                      onChange={handleChange}
                      name='bio'
                    />
                    <div className='instagram-shtuff'>
                    <label>
                      <FontAwesomeIcon className='insta' icon={['fab', 'instagram']} color='#6CB7D9' size='2x' />
                    </label>
                    <input
                      type='text'
                      size='40'
                      placeholder='Instagram Link'
                      value={changes.instagramLink || ''}
                      onChange={handleChange}
                      name='instagramLink'
                    />
                    </div>

                    <div className='twitter-shtuff'>
                    <label>
                      <FontAwesomeIcon className='twita' icon={['fab', 'twitter']} color='#6CB7D9' size='2x' />
                    </label>
                    <input
                      type='text'
                      size='40'
                      placeholder='Twitter Link'
                      value={changes.twitterLink|| ''}
                      onChange={handleChange}
                      name='twitterLink'
                      id='twitter'
                    />
                    </div>

                    <div className='tiktok-shtuff'>
                    <label>
                      <FontAwesomeIcon className='tik' icon={['fab', 'tiktok']} color='#6CB7D9' size='2x' />
                    </label>
                    <input
                      type='text'
                      size='40'
                      placeholder='Tiktok Link'
                      value={changes.tiktokLink|| ''}
                      onChange={handleChange}
                      name='tiktokLink'
                      id='tiktok'
                    />
                    </div>

                    <div className='twitch-shtuff'>
                    <label>
                      <FontAwesomeIcon className='twit' icon={['fab', 'twitch']} color='#6CB7D9' size='2x' />
                    </label>
                    <input
                      type='text'
                      size='40'
                      placeholder='Twitch Link'
                      value={changes.twitchLink|| ''}
                      onChange={handleChange}
                      name='twitchLink'
                      id='twitch'
                    />
                    </div>

                    <div className='youtube-shtuff'>
                    <label>
                      <FontAwesomeIcon className='you' icon={['fab', 'youtube']} color='#6CB7D9' size='2x' />
                    </label>
                    <input
                      type='text'
                      size='40'
                      placeholder='YouTube Link'
                      value={changes.youtubeLink|| ''}
                      onChange={handleChange}
                      name='youtubeLink'
                      id='youtube'
                    />
                    </div>

                    <div className='other-shtuff'>
                      <label>
                        <FontAwesomeIcon className='other' icon='user' color='#6CB7D9' size='2x' />
                      </label>
                      <input
                        type='text'
                        size='40'
                        placeholder='Other Link'
                        value={changes.otherLink|| ''}
                        onChange={handleChange}
                        name='otherLink'
                        id='other'
                      />
                    </div>

                    <button
                      className='save-changes'
                      type='submit'>Save Changes
                    </button>
                  </form>

                  {/* THIS ENDS THE ABOUT ME FORM */}

                </div>
              </Card.Content>
            </Card>
          </div>
        )}

      </div>

    </div>
    </Wrapper>
  )
}

export default MyProfile
