import React, { useEffect } from 'react';
import { Wrapper } from 'pages/About/AboutStyles'
import './AboutStyles.css'

const About = (props) => {

  console.log(props.location.state)

  return (
    <Wrapper>
    <section className='section'>
    <h1>About Us</h1>

      <div className='about-us'>
      <div className='text'>
        <p>Social Media marketing is both vital for brands to gain exposure, as well as for creators to monetize their following.</p>
         <p>The core issue with paid collaborations currently is the blind trust between both parties.</p>
         <p>Without payment guarantees creators risk not being paid for their promotion. Similarly, brands risk paying creators without promotions being completed. It is because of this lack of certainty that Endorse was created.</p>
         <p>Endorse provides a secure way for brands and creators to collaborate and compensate, serving as an intermediary to social media marketing.</p>
          <p>The lack of tools for social media marketing hinders the creator's ability to monetize their work, and discourages brands from utilizing social promotions.</p>
          <p>In order for the most effective collaborations on any platform be it Instagram, Twitter, Facebook, Twitch, Tik Tok, Youtube, etc. an efficient method for collaboration is essential. There is no better platform to automate the process of social media promotion than Endorse.</p>
          <p>No matter your follower count and no matter your marketing experience there are endless opportunities to work with amazing people, and our team invites you to make new promotions between brands and creators a reality.</p>
      </div>
    </div>
    </section>

    </Wrapper>
  )
}

export default About;
