import React from 'react'
// import { loadStripe } from '@stripe/stripe-js'
import { Link } from 'react-router-dom'


// const stripePromise = loadStripe('pk_live_ONeyIlHWwMZQTowOUsIufjFM00DViPqsjR')

const UpgradeOptions = () => {
  return (
    <div>This is the upgrade page
      {/* <CreateCustomerForm /> */}
      <Link to='/stripe-login'>
      <button role='link'>
        Checkout
      </button>
      </Link>
    </div>
  )
}

export default UpgradeOptions
