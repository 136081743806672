import React, { useEffect, useState } from 'react'
import { Wrapper } from './PaymentPortalStylesTwo'
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux'
import { Button, Icon } from 'semantic-ui-react'
import { SERVER_URI } from 'config'
import axios from 'axios';
import { getAllBrands, getPendingTasks } from 'redux/actions/searchActions'
import { async } from '@firebase/util';
const stripe = require('stripe')('sk_test_51Glqp3HERG1YvueQVGLm9mW6l52hv6mf23T61GOKWGm11OyShQcByWDI92OWj3ujP4NgCbCE6PIg4BB8QZfjZSxY00tTyXBJXf');
const baseUrl = `${SERVER_URI}/api/stripe`;

const PaymentPortalTwo = () => {
  const dispatch = useDispatch()
  const state = useSelector((state) => state)
  let tasks = state.search.tasks
  const userID = state.auth.user.id
  const stripeCustomer = localStorage.getItem('stripeID')
  const [link, setLink] = useState();

  useEffect(() => {
    dispatch(getPendingTasks(userID))
  
  } , [])


  const handleSubmit = async (e,taskId) => {
    if(link === ""|| link === undefined) return alert('Please enter a link');
    const transfer = await axios.post(`${baseUrl}/complete-task`, {
      taskId: taskId,
      link: link
    })
    console.log(transfer, 'this is the transfer');
    window.location.reload();
    console.log(tasks, 'wwwwwwwwwwww');

  }
  const onChange = (event) => {
     setLink(event.target.value)
  }

  return (

    <Wrapper>
    <section className='section'>
    <div className='top-btns' style={{marginTop: '10px'}}>
     <a href='/dashboard'>
      <Button className='normal-btn'>
          <div className="link">
          <Icon name='chevron left'></Icon>
          Dashboard</div>
        </Button>
        </a>
    </div>

    <h2>Collaborations</h2>
    <div className='tasksTitle'>

      <div className='brandTask'>
      Brand
      </div>

      <div className='brandTask'>
      Date
      </div>

      <div className='brandTask'>
      Amount
      </div>

      <div className='brandTask'>
      Link
      </div>


    </div>

    { tasks.length > 0 ? (tasks.map((task, index) => {
      return (

      <div className='tasks'>
        
      <div className='taskInfo'>
       {task.brandUsername}
      </div>

      <div className='taskInfo'>
      {task.date}
      </div>

      <div className='taskInfo'>
       $ {task.amount / 100}
      </div>

      <div className='taskInfo' >
        <label for="link">Link</label>
        <input type="text"  name="link"   onChange={onChange}  />
      </div>

      <div className='taskSubmit'>
      <button onClick={(e)=> handleSubmit(e,task.id)}>
        Submit
      </button>
      </div>

    </div>
    
      ) 
    }
    )) : (
      <div>
        <h1>No Tasks</h1>
      </div>
    )}
    
   
    {/* <button onClick={handleSubmit}>
        Submit
      </button> */}

    </section>
    </Wrapper>

  )
}


export default PaymentPortalTwo;
