import React from 'react'
import { Wrapper } from './PrivacyStyles'

const Privacy = () => {
  return (
    <Wrapper>
      <section className='section'>
        <h1>Endorse Privacy Policy</h1>
        <p>Your Privacy Matters</p>
        
        <div className='main-container'>
          <h3>Introduction</h3>
          <div className='text'>
            <p>Our registered users share their identities, engage with their network, exchange information and professional agreements, create campaigns, and find business and career opportunities. </p>
          </div>

          <h3>Services</h3>
          <div className='text'>
            <p>This privacy policy applies to Promoquo.com getendorse.com and Endorse related communications and services. </p>
          </div>

          <h3>Data Controllers and Contracting Parties</h3>
          <div className='text'>
            <p> Endorse will be the controller of your personal data provided to, or collected by or for, or processed in connection with, our Services.
            As a Visitor or Member of our Services, the collection, use and sharing of your personal data is subject to this Privacy Policy and other documents referenced in this Privacy Policy, as well as updates. 
            Endorse (“we” or “us”) can modify this Privacy Policy, and if we make material changes to it, we will provide notice through our Services, or by other means, to provide you the opportunity to review the changes before they become effective. 
            You acknowledge that your continued use of our Services after we publish or send a notice about our changes to this Privacy Policy means that the collection, use and sharing of your personal data is subject to the updated Privacy Policy, as of its effective date.</p>
          </div>

          <h2>1. Data Collected</h2>

          <h3>1.1 Data You Provide To Us</h3>
          <div className='text'>
            <h4>Registration</h4>
            <p>To create an account you need to provide data including your name, email address and/or mobile number, and a password. If you register for a premium Service, you will need to provide payment (e.g., credit card) and billing information.</p>
            <h4>Profile</h4>
            <p>You have choices about the information on your profile, such as your education, work experience, skills, photo, city or area and endorsements. You are not required to provide additional information on your profile, however profile information helps you to get more from our services, including helping brands create find you. It’s your choice whether to include sensitive information on your profile and to make that sensitive information public. Please do not post or add personal data to your profile that you would not want to be publicly available. You don’t have to post or upload personal data; though if you don’t, it may limit your ability to grow and engage with your network over our Services.</p>
            <h4>Payment Processing</h4>
            <p>Payments that are received through Endorse, initiated through Endorse (payment portal), and otherwise finalized through third party payment processing will be recorded to ensure agreements are handled properly. Data on payment amount, date, and payment accounts will be securely stored.</p>
          </div>

          <h2>2. How We Use Your Data</h2>
          <h3>2.1 Services</h3>
          <div className='text'>
            <p>We use your data to authorize access to our Services and honor your settings.
              Our Services allow you to stay in touch and up to date with colleagues, partners, clients, and other professional contacts. To do so, you can “connect” with the users who you choose, and who also wish to “connect” with you.
              The choice to connect, message, and work with others is your own.</p>

              <h4>Stay Connected</h4>
              <p>We use basic information from profiles to be able to enroll users in the search portion of our website. </p>
              <h4>Stay Organized</h4>
              <p>Through the payment portal information inputted by users is collected to confirm initiation of deals between both parties, and reviewed to confirm completion of both parties promises.  </p>
          </div>

          <h3>2.2 Premium Services</h3>
          <div className='text'>
          <p>Premium services through our subscription portal allows for the users to collect more of the payment amount through Endorse and third-party payment services. </p>
          </div>

          <h3>2.3 Marketing</h3>
          <div className='text'>
          <p>Premium services through our subscription portal allows for the users to collect more of the payment amount through Endorse and third-party payment services. </p>
          </div>


          <h3>2.4 Developing Services and Research</h3>
          <h4>Service Development</h4>
          <div className='text'>
            <p>We use data, including public feedback, to conduct research and development for our Services in order to provide you and others with a better, more intuitive and personalized experience, drive membership growth and engagement on our Services.</p>
          </div>

          <h4>Other Research</h4>
          <div className='text'>
            <p>We use the personal data available on Endorse to research social, economic and online trends. </p>
          </div>

          <h3>2.7 Customer Support</h3>
          <div className='text'>
            <p>We use data (which can include your communications) to investigate, respond to and resolve complaints and for Service issues (e.g., bugs).</p>
          </div>

          <h3>2.8 Insights That Do Not Identify You</h3>
          <div className='text'>
            <p>We use data (which can include your communications) to investigate, respond to and resolve complaints and for Service issues (e.g., bugs).</p>
          </div>

          <h2>3. How We Share Information</h2>
          <div className='text'>
            <p>We use data (which can include your communications) to investigate, respond to and resolve complaints and for Service issues (e.g., bugs).</p>
          </div>

          <h3>3.1 Our Services</h3>
          <h4>Profile</h4>
          <div className='text'>
            <p>Your profile is fully visible to all Members and customers of our Services.</p>
          </div>

          <h4>Posts and Messages</h4>
          <div className='text'>
            <p>Our services allow users to present all of their social media profiles as well as message other users within the site:
            When you share an article or a post (e.g., an update, image, video or article) publicly it can be viewed by every other user within the site. 
            When messaging your messages remain private to only those within the discussion. 
            Any information visible through your profile or related links will be visible to all other users. </p>
          </div>

          <h3>3.2 Others’ Services</h3>
          <div className='text'>
            <p>When you opt to link your account with other services, personal data will become available to them. The sharing and use of that personal data will be described in, or linked to, a consent screen when you opt to link the accounts. For example, you may link your Instagram or Twitter account to share content from our Services into these other services.</p>
          </div>

          <h3>3.3 Service Providers</h3>
          <div className='text'>
            <p>We use others to help us provide our Services (e.g., maintenance, analysis, audit, payments, fraud detection, marketing and development). They will have access to your information as reasonably necessary to perform these tasks on our behalf and are obligated not to disclose or use it for other purposes.</p>
          </div>

          <h3>3.4 Change in Control or Sale</h3>
          <div className='text'>
            <p>We can also share your personal data as part of a sale, merger or change in control, or in preparation for any of these events. Any other entity which buys us or part of our business will have the right to continue to use your data, but only in the manner set out in this Privacy Policy unless you agree otherwise.</p>
          </div>

          <h2>4. Your Choices & Obligations</h2>
          <h3>4.1 Data Retention</h3>
          <div className='text'>
            <p>We generally retain your personal data as long as you keep your account open or as needed to provide you Services. This includes data you or others provided to us and data generated or inferred from your use of our Services.</p>
          </div>
          <h3>4.2 Rights to Access and Control Your Personal Data</h3>
          <div className='text'>
            <p>Collection of data at this stage is very minimal in regards to use of our services, and users as well have the choice of deciding what to share and what not to share on their personal profiles. For personal data that we have about you, you can:
            Delete Data: You can ask us to erase or delete all or some of your personal data
            Change or Correct Data: You can edit some of your personal data through your account. You can also ask us to change, update or fix your data in certain cases. </p>
          </div>
          <h3>4.3 Account Closure</h3>
          <div className='text'>
            <p>If you choose to close your Endorse account, your personal data will generally stop being visible to others on our Services within 24 hours. We generally delete closed account information within 30 days of account closure, except as noted below.
 
            We retain your personal data even after you have closed your account if reasonably necessary to comply with our legal obligations (including law enforcement requests), meet regulatory requirements, resolve disputes, maintain security, prevent fraud and abuse (e.g., if we have restricted your account for breach of our Professional Community Policies), enforce our User Agreement, or fulfill your request to "unsubscribe" from further messages from us. We will retain de-personalized information after your account has been closed.
             
            Information you have shared with others (e.g., through InMail, updates or group posts) will remain visible after you close your account or delete the information from your own profile or mailbox, and we do not control data that other Members have copied out of our Services. Groups content and ratings or review content associated with closed accounts will show an unknown user as the source. Your profile may continue to be displayed in the services of others (e.g., search engine results) until they refresh their cache.</p>
          </div>

          <h2>5. Other Important Information</h2>
          <h3>5.1. Security</h3>
          <div className='text'>
            <p>We implement security safeguards designed to protect your data, such as encryption. We regularly monitor our systems for possible vulnerabilities and attacks. However, we cannot warrant the security of any information that you send us. There is no guarantee that data may not be accessed, disclosed, altered, or destroyed by breach of any of our physical, technical, or managerial safeguards.</p>
          </div>

          <h3>5.2 Lawful Bases for Processing</h3>
          <div className='text'>
            <p>We will only collect and process personal data about you where we have lawful bases. Lawful bases include consent (where you have given consent), contract (where processing is necessary for the performance of a contract with you (e.g., to deliver the Endorse Services you have requested) and “legitimate interests.”
 
            Where we rely on your consent to process personal data, you have the right to withdraw or decline your consent at any time and where we rely on legitimate interests, you have the right to object.</p>
          </div>

          <h3>5.4. Direct Marketing and Do Not Track Signals</h3>
          <div className='text'>
            <p>We currently do not share personal data with third parties for their direct marketing purposes without your permission.</p>
          </div>
          
      </div>
      </section>
    </Wrapper>
  )
}

export default Privacy
