import React from 'react'
import { connect } from 'react-redux'
import { forgotPassword, changePassword } from 'redux/actions/authActions'
import { message } from 'antd'
import { Wrapper } from 'auth/resetStyles'

const PasswordReset = (props) => {
  const token = window.location.search.split('?token=')[1]

  const [ input, setInput ] = React.useState({
    password1: '',
    password2: ''
  })

  React.useEffect(() => {
    localStorage.setItem('rsToken', token)
  }, [token])

  const onChange = (e) => {
    const { name, value } = e.target;
    setInput(prevState => ({ ...prevState, [name]: value }));
};

  const success = () => {
    message.success('Successfully Reset Password')
  }

  const error = () => {
    message.error('Error resetting password THIS MIGHT BE A LIE')
  }

  const checkPassword = passwords => {
    if (!passwords) return false
    if (passwords[0] !== passwords[1]) return false
    return true
  }

  const onSubmit = e => {
    e.preventDefault()
    let tkn = token ? token: localStorage.getItem('rsToken')
    console.log(tkn) // made need to remove this console
    if (checkPassword([input.password1, input.password2])) {
      console.log('Passwords Match')
      return new Promise((resolve, _reject) => {
        props.changePassword(token, input.password1)
        setTimeout(resolve, 700)
        // changed 'props.resetError' to 'props.resetPasswordError' and was able to get the correct error message to show up!
        if (props.resetPasswordError === false) {
          success()
          console.log('this is the success passwordreset.js')
          props.history.push('/login')
        } else {
          error()
          console.log('there was a stupid error')
        }
      })
    } else {
      console.log('passwords invalid');
    }
  }

  if (token) {
    return (
    <Wrapper>
     <div className='section'>

      <div className='form-container'>
        <form autoComplete='off' spellCheck='false' onSubmit={onSubmit}>
          <h2>Reset Your Password</h2>

        <div className='reset'>
          <label htmlFor='password1'>New Password </label>
          <input 
            id="password1"
            type='password'
            placeholder='New Password'
            name='password1'
            value={input.password1}
            onChange={onChange}
            required
            minLength='5'
          />
        </div>
        <div className='reset'>
          <label htmlFor='password2'>Confirm Password </label>
          <input 
            id='password2'
            type='password'
            placeholder='Re-type Password'
            name='password2'
            value={input.password2}
            onChange={onChange}
            required
            minLength='5'
          />
        </div>
        
          <button type='submit' className='submit'>Reset Password</button>
        </form>
       </div>
      </div>
    </Wrapper>
    )
  } else {
    return <h1>No Token dude</h1>
  }
}

// fixed failed message error by adding in auth on line 104 so that it is state.auth.resetError
// this is because when you combine reducers you need to specify your state in mapStateToProps
const mapStateToProps = state => {
  console.log(state)
  return {
    resetPasswordError: state.auth.resetError,
  }
}

export default connect(mapStateToProps, { forgotPassword, changePassword })(PasswordReset)