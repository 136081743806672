import styled from 'styled-components'


export const Wrapper = styled.div`



.section {
    height: 100%;
    width: 100%;
  }
  .confirm-btn {
    padding-top: 3px;
    padding-bottom: 3px;
    cursor: pointer;
    width: 30%;
    background: linear-gradient(90deg, #Ede0f7 0%, #B993ee 100%);
    color: white;
    text-shadow: 1px 1px 2px #959597;
    font-size: 15px;
    border: none;
    border-radius: .7rem;
  }
  .confirm-btn:hover {
    background: linear-gradient(90deg, #e2ccf2 0%, #9d68e7 100%);
  }
  .back{
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 100%;
  }
  .back-btn {
    padding-top: 3px;
    padding-bottom: 3px;
    cursor: pointer;
    width: 50%;
    background: linear-gradient(90deg, #Ede0f7 0%, #B993ee 100%);
    color: white;
    text-shadow: 1px 1px 2px #959597;
    font-size: 15px;
    border: none;
    border-radius: .7rem;
  }
  .back-btn:hover {
    background: linear-gradient(90deg, #e2ccf2 0%, #9d68e7 100%);
  }
`
