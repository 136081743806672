import styled from 'styled-components'

export const Wrapper = styled.div`


/***Hero-section styling***/

.status-quo {
  align-items: center;
  display: block;
}

.status-quo .btn-prime {
  align-items: center;
}

status-quo-btn {
  display: flex;
  align-items: center;
}

/*Hero section text*/
 .hero-section h1 {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  text-align: center;
  color: var(--clr-prime);
  text-orientation: upright;
 }

/*hero section image formatting*/
.hero-section .img-wrap{
  float: right;
  border-radius: 50%;
  border: 8px solid var(--clr-prime);
  overflow: hidden;
  margin-top: -240px;
}

/*hero-section button connection and formatting*/
.hero-section .btn-prime {
  }
  h1 {
      font-size: auto;
  }
  h2 {
      font-size: 30px;
  }
  .heading-style::after {
      height: 4px;
  }
  .curved-img-section p {
      line-height: 1.4285em;
  }
}

.hero-section .container {
  margin-top: 100px;
  padding-left: 80px;
}

.btn-prime {
  border-radius: 50px;
  padding: 17px 40px;
  color: #200d80;
  font-size: 15px;
}

.btn-prime:hover {
  transform: translateY(-5px);
  box-shadow: 5px 5px 12px rgba(0, 0, 0, 0.15);
}

/***How It Works Section ***/

/* How It Works Image */

.howitworks .img-texting {
  width: 35%;
  height: auto;
  float: left;
  padding-right: 20px;
  margin-top: -17.5%;
}

/*How It Works Title*/

.HowItWorksTitle {
  text-align: center;
  padding-top: 150px;
  padding-top: 4vh;
}

/*How It Works Text*/

.HowItWorksText {
  font-size: 1.5rem;
  text-align: center;
}

/*Purple Blob*/
.heading-style::after {
  content: '';
  background: #be68cd;
  width: 100px;
  align-content: center;
  border-radius: 50px;
  display: inline-block;
  margin: 10px 0;
  height: 7px;
}

/*How It Works Button/*
.JoinButton{
  padding-right: 800px;
}

/***Campaigns Section ***/

.CampaignsText{
  font-size: 1.5rem;
}

.list-ticked{
  font-size: 1.5rem;
}

/*Influecing Easy Section*/

.img-influencingEasy{
  height: auto;
  width: 35%;
  padding-right: 20px;
  float: left;
  margin-top: -18%;
}

.InfluencingEasyText{
  font-size: 1.5rem;
}

/*Audience Section*/

.audience-section P {
  font-size: 1.5rem;
  padding-bottom: 20px;
  padding-left: 3%;
  padding-right: 3%;
}

/*Unmatched Engagement Section*/

.unmatched-icon{
  height: auto;
  width: 35%;
  min-width: 300px;
  padding-left: 20px;
  float: right;
  margin-top: -15%;
}

.UnmatchedEngagementText{
  font-size: 1.5rem;
}

/*Campaigns Title/*
.CampaignTitle{
  font-size: 60px;
  padding-top: 100px;
  padding-right: 700px;
}

/***Features***/
/*features sections background*/
.features-section {
  padding: 7% 0 17%;
  background-image: url('../../assets/images/transparentbg.png');
  background-position: center bottom;
  background-size: cover;
  background-repeat: no-repeat;
}

/*features section title*/

.features-section .feature-block{
  display: inline-block;
}

.features-section .feature-box{
  display: inline-block;
  width: 300px;
}

/* features section icons*/
.features-section .feature-box .feature-box-icon {
  margin-bottom: 20px;
}

/* features section subtitles*/
.features-section .feature-box .feature-box-title {
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 15px;
  text-align: center;
}

/* features section subtext*/
.features-section .feature-box .feature-box-inner {
  font-size: 20px;
  display: inline-block;
}

/***Influencing Easy Section***/
/*section img*/

/*** How It Works Image ***/
/*section img*/

/***Main container***/
  .main-container {
    
  /* border: 2px solid blue; */

/* .main-top {
  background-image: linear-gradient(#B7DEEE, #D4E9F4);
  border: 3px dotted green;
  height: 500px;
} */

.main-top {
  /* background-image: linear-gradient(#B7DEEE, #D4E9F4); */
  color: white;
  /* clip-path: polygon(0 0%, 100% 0, 100% 80%, 0 100%); */
  /* padding: 20px; */
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  /* padding: 10rem 20%; */
  /* border: 5px dotted purple; */
  height: 300px;
  display: flex;
  flex-direction: column;
  /* justify-content: left; */
  margin-bottom: 2rem;



  .welcome {
    /* border: 2px dashed orange; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 20px;
    margin-top: 3rem;
    margin-bottom: 3rem;

    .tag-line {
      /* border: 2px double forestgreen; */
      display: flex;
      text-align: left;

      h2 {
        /* border: 1px dashed red; */
        font-size: 4rem;
        line-height: 50px;
        margin-bottom: 20px;

        @media only screen 
          and (device-width: 375px) 
          and (device-height: 812px) 
          and (-webkit-device-pixel-ratio: 3) { 
          /* border: 4px dotted blue; */
          font-size: 3rem;
          line-height: 40px;
        }
      }
    }

    .motto {
      /* border: 2px dotted blue; */
      font-size: 2rem;
    }



    p {
      font-size: 16px;
      text-align: left;
      color: black;
    }

  }

  .cta {
    display: flex;
    /* border: 2px dashed red; */

  .btn {
    background-color: #6CB7D9;
    @media only screen 
      and (device-width: 375px) 
      and (device-height: 812px) 
      and (-webkit-device-pixel-ratio: 3) { 
      padding: 10px;

    }


    .signup {
      color: white;
      font-size: 1.1rem;

      @media only screen 
      and (device-width: 375px) 
      and (device-height: 812px) 
      and (-webkit-device-pixel-ratio: 3) { 
        /* padding-top: 10px; */
        /* border: 2px dotted blue; */

    }
    }

  }

    p {
      font-size: 16px;
      padding-left: 50px;
      color: black;
    }
  }
}

.middle {
  /* border: 2px dotted purple; */
  background-color: #FAFAFA;



  .mid-section {
    padding: 2rem;


  }
}
.cards {
  /* border: 2px dotted darkblue; */
  display: flex;
  justify-content: center;

  @media only screen 
    and (device-width: 428px) 
    and (device-height: 926px) 
    and (-webkit-device-pixel-ratio: 3) { 
  border: 4px dotted green;
    flex-direction: column;

  }

  @media only screen 
    and (device-width: 375px) 
    and (device-height: 812px) 
    and (-webkit-device-pixel-ratio: 3) { 
      flex-direction: column;
    }

  .card {
    /* padding: 2rem; */
    /* border: 2px solid purple; */
    margin: 2rem;
    align-items: center;
    /* justify-content: center; */

    @media only screen 
      and (device-width: 428px) 
      and (device-height: 926px) 
      and (-webkit-device-pixel-ratio: 3) { 
      /* border: 4px dotted blue; */
      flex-direction: column;
      width: 300px;

    }

    @media only screen 
    and (device-width: 375px) 
    and (device-height: 812px) 
    and (-webkit-device-pixel-ratio: 3) { 
      flex-direction: column;
      width: 250px;
      justify-content: center;
    }

    img {
      /* border: 2px dashed seagreen; */
      margin-top: 20px;


    }

    h4 {
      color: #6CB7D9;
      font-weight: 900;
    }

    .card-content {
      /* border: 2px dotted red; */
      text-align: center;
      padding-inline-start: 0;
      padding: 2rem;
      margin-top: 10px;

      li {
        margin: 3px;
      }
    }

    .list {
      list-style: none;
    }
  }



}

.title {
font-size: 3rem;
}


.tagline {
font-size: 3rem;
}

.cta {
font-size: 1.5rem;
}

.bottom-section {
/* border: 2px dashed seagreen; */
/* display: flex; */

.bottom-wrapper {

  /* border: 5px dotted purple; */
  padding-bottom: 5rem;
  max-width: 100%;
  display: flex;
  justify-content: center;


  img {
      max-width: 550px;
      /* border: 2px dashed purple; */
      opacity: 70%;

    @media only screen 
    and (device-width: 428px) 
    and (device-height: 926px) 
    and (-webkit-device-pixel-ratio: 3) { 
      /* border: 4px dotted blue; */

      width: 250px;
      height: 150px;
    }

  }

  .how-section {
    /* border: 2px dashed red; */


  }

  .textbox {
    background-color: whitesmoke;
    padding: 1.4rem;

    width: 450px;

  }


}



    
  } /* ends bottom section */
    
}
`;
