import styled from 'styled-components'

export const Wrapper = styled.div`
  .section {
    /* border: 2px dotted blue; */
    padding: 1rem 3rem;
    /* height: 100vh; */
    margin-top: 5rem;

    .main-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      
      margin-top: 2rem;
      /* border: 2px dashed purple; */

      .text {
        justify-content: center;
        /* border: 4px dotted blue; */
        margin-left: 4rem;
        margin-right: 4rem;
        font-size: 16px;
        text-align: justify;
        letter-spacing: 1px;
        /* width: 500px; */
      }
    }
  }
`;