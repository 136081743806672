import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAllInfluencersById } from 'redux/actions/searchActions'
import { Link } from 'react-router-dom'
import { Card, Icon, Button } from 'semantic-ui-react'
import { Wrapper } from './InfluencerStyles'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Influencer = (props) => {
  const id = Number(props.match.params.id)
  const dispatch = useDispatch()
  const state = useSelector((state) => state)
  const influencer = state.search.singleInfluencer.data

  useEffect(() => {
    dispatch(getAllInfluencersById(id))
  }, [dispatch, id])

  return (
    <Wrapper>
      <div className='section'>
      <div className='toplink'>
        <a href='/influencersearch'>
          <Button>
          <div className="link">
          <Icon name='chevron left'></Icon>
          Creators</div>
          </Button>
          </a>

       </div>
        <div className='single-influencer'>
          <Card>
            {influencer
              ? <div>
                <img style={{ width: '120px', height: '120px', borderRadius: '50%' }}
                  src={influencer.image}
                  alt='avatar'
                />
                <h1>{influencer.username}</h1>
                <p>{influencer.bio}</p>

                <div className='icon-links'>
                {influencer.instagramLink ? (
                  <p>
                  <a
                    className='link'
                    href={influencer.instagramLink}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none", color: "#6CB7D9" }}
                  >
                    <FontAwesomeIcon icon={['fab', 'instagram']} color='#6CB7D9' size='2x' />
                  </a>
                </p>
                ) : (

                  <></>

                )}

                {influencer.twitterLink ? (
                  <p>
                  <a
                    className='link'
                    href={influencer.twitterLink}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none", color: "#6CB7D9" }}
                  >
                    <FontAwesomeIcon icon={['fab', 'twitter']} color='#6CB7D9' size='2x' />
                  </a>
                </p>
                ) : (
                  <></>
                )}

                {influencer.tiktokLink ? (
                  <p>
                  <a
                    className='link'
                    href={influencer.tiktokLink}
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none", color: "#6CB7D9" }}
                  >
                    <FontAwesomeIcon icon={['fab', 'tiktok']} color='#6CB7D9' size='2x' />
                  </a>
                </p>
                ) : (
                  <></>
                )}

                {influencer.twitchLink ? (
                <p>
                <a
                  className='link'
                  href={influencer.twitchLink}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none", color: "#6CB7D9" }}
                >
                  <FontAwesomeIcon icon={['fab', 'twitch']} color='#6CB7D9' size='2x' />
                </a>
                </p>
                ) : (
                <></>
                )}

                {influencer.youtubeLink ? (
                <p>
                <a
                  className='link'
                  href={influencer.youtubeLink}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none", color: "#6CB7D9" }}
                >
                  <FontAwesomeIcon icon={['fab', 'youtube']} color='#6CB7D9' size='2x' />
                </a>
                </p>
                ) : (
                <></>
                )}

                {influencer.otherLink ? (
                <p>
                <a
                  className='link'
                  href={influencer.otherLink}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "none", color: "#6CB7D9" }}
                >
                  <FontAwesomeIcon icon='user' color='#6CB7D9' size='2x' />
                </a>
                </p>
                ) : (
                <></>
                )}

                </div>



                <Link to={`/dashboard/influencer/inbox?chatWith=${influencer.id}&username=${influencer.username}`}>
                <Button>Connect</Button>
                </Link>

                </div>
              : null
            }
          </Card>
        </div>
      </div>
    </Wrapper>
  )
}

export default Influencer
