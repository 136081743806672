import styled from 'styled-components'

export const Wrapper = styled.div`
  .posts__container {
    /* height: 100vh; */
    padding: 5rem 3em;
    /* border: 2px dashed green; */
}

.posts {
  /* border: 2px dotted purple; */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-auto-rows: minmax(400px, auto);
  gap: 3em 1em;
  align-items: stretch;
  justify-content: center;
}

.posts__post {
  /* border: 3px dashed orange; */
  color: #333;
  background-color: #fff;
  text-decoration: none;
  border-radius: 12px;
  overflow: hidden;
  transition: box-shadow 200ms ease-in;
  max-height: 35rem;
}

.posts__post > *:not(div) {
  padding: 0 1rem;
}

.posts__post:hover {
  box-shadow: 0px 2px 16px #394e5d36;
}

.posts__post__img__container {
  margin-bottom: 1rem;
  overflow: hidden;
  height: 300px;
}

.posts__post__img__container__img {
  width: 100%;
  height: auto;
  object-fit: contain;
  transition: transform ease-in 200ms;
}

.posts__post:hover .posts__post__img__container__img {
  transform: scale(1.1);
  transition: transform ease-out 200ms;
}
`;
